<template>
  <TableComponent
      :table-data="this.getTableData"
      :table-data-total="this.getTableTotalData"
      :table-data-formatted="this.getTableData"
      :table-columns="this.getTableColumns"
      :is-xlsx-exporting="true"
      :is-selectable="this.getNextExist"
      :no-data-text="this.draw_info.empty_table_text == null ? 'нет данных' : this.draw_info.empty_table_text"
      :default-order-column="this.draw_info.default_sort == null ? 0 : this.draw_info.default_sort"
      @selectChangedByClick="this.rowSelected"
  >
    <template v-slot="{row_id, column_id}">
      <Icon v-if="this.getTableData[row_id].icon"
            type='svg'
            size=20
            :name="this.getTableData[row_id].icon"
            style="float: left; margin-right: 3px;"
          />

      <template v-if="this.getTableColumns[column_id].is_files">
        <div v-if="!this.checkEmpty(this.getTableData[row_id][column_id])"
             class="download-icon"
             @click="this.downloadFiles(this.getTableData[row_id][column_id],
             this.getTableData[row_id][0],
             this.getTableColumns[column_id].resource)">
          <Icon type='svg'
                size=20
                name="file-arrow-down"
          />
        </div>
      </template>

      <LabelComponent v-else
                      :is_monospace="true"
                      :digits_precision="this.getTableColumns[column_id].number_round == null ? 2 : this.getTableColumns[column_id].number_round"
                      label_type="body/large/regular 12"
                      :label_text="this.getTableData[row_id][column_id]"
      />

    </template>
  </TableComponent>
</template>

<script type="text/javascript">
// import builder from '@/assets/v1/js/builder';
import TableComponent from '@/units/Table';
import Icon from '@/units/Icon';
import LabelComponent from '@/units/Label';
import uniViewMixin from '@/assets/mixins/uniViewMixin';

export default ({
  components: {
    TableComponent,
    Icon,
    LabelComponent,
  },
  data: () => ({
  }),
  mixins: [uniViewMixin],
  computed: {
    getWorkData() {
      const workData = this.draw_data.map((item) => item);

      if (this.before_total != null) {
        const curColumns = Object.keys(this.draw_info.columns);

        curColumns.splice(0, 1);

        const curTotal = this.before_total.filter((item) => curColumns.includes(item.name));

        if (curTotal.length > 0) {
          const totalToPackedData = {
            columns: {},
            level: 1,
            is_total: true,
          };
          curTotal.forEach((item) => {
            totalToPackedData.columns[item.name] = {value: item.value};
          });

          workData.push(totalToPackedData);
        }
      }

      return workData;
    },
    getFormattedData() {
      const workData = this.getWorkData;

      if (workData.length===0) return [];

      const allColumns = this.getTableColumns;

      return workData.map((item, index) => {
        // item.columns['test_files'] = {'value': 'qweqwe123'};
        return Object.assign(
            {
              id: index,
              text_color: item.color,
              icon: item.icon,
              background_color: item.color_background,
              is_total: !!item.is_total,
            },
            allColumns.map((column) =>
                  item.columns[column.__key__] != null ? this.formatCell(item.columns[column.__key__].value) : null),
        );
      });
    },
    getTableData() {
      return this.getFormattedData.filter((item) => !item.is_total);
    },
    getTableTotalData() {
      return this.getFormattedData.filter((item) => item.is_total)[0];
    },
    getTableColumns() {
      const workData = this.getWorkData;

      if (workData.length===0) return [];

      const packedColumns = Object.keys(this.draw_info.columns).map((item) => {
        // eslint-disable-next-line vue/no-mutating-props
        this.draw_info.columns[item].__key__ = item;
        return this.draw_info.columns[item];
      });

      const ans = packedColumns.filter((item)=>item.visible!==false).map((item, index) =>{
        return {'id': index,
          'title': item.label,
          'content_align': this.checkColumnIsNumber(item.__key__) ? 'right' : 'left',
          'width': '300px',
          'is_delete_if_empty': item.is_delete_if_empty,
          'is_with_sum': item.is_with_sum === true,
          'is_files': item.is_files === true,
          'resource': item.resource,
          'self_clickable': item.is_files === true,
          'number_round': item.number_round,
          '__key__': item.__key__,
          // 'data': (item) => item['value'],
        };
      });

      return ans;
    },
    getNextExist() {
      const workData = this.getWorkData;

      if (workData.length===0) return false;

      return !!workData[0].next_type_data;
    },
  },
  watch: {

  },
  methods: {
    formatCell(data) {
      if (typeof data == 'boolean') {
        return data ? 'Да' : 'Нет';
      }

      return data;
    },
    checkEmpty(str) {
      return str == null || str === '';
    },
    getObjectFromQueryIdx(queryIdx) {
      let beforeTotal = this.getTableData[queryIdx];

      beforeTotal = this.getTableColumns.map((item, index) => {
        return {
          'value': beforeTotal[index],
          'name': item.__key__,
        };
      });

      const workData = this.getWorkData;

      return {
        modalCaption: this.getTableData[queryIdx][0],
        next_level: workData[queryIdx].next_level,
        next_type_data: workData[queryIdx].next_type_data,
        before_total: beforeTotal,
      };
    },
    rowSelected(data) {
      this.$emit('next_select', this.draw_index, this.getObjectFromQueryIdx(data), data);
    },
    downloadFiles(guid, title, resource) {
      this.$emit('download_files', {title, guid, resource});
    },
    checkColumnIsNumber(columnIndex) {
      const workData = this.getWorkData;
      // eslint-disable-next-line guard-for-in
      for (const key in workData) {
        if (workData[key].columns[columnIndex] == null) {
          return false;
        }

        if (typeof workData[key].columns[columnIndex].value === 'string' &&
            workData[key].columns[columnIndex].value.length > 3) {
          return false;
        } else {
          if (typeof workData[key].columns[columnIndex].value === 'number') {
            return true;
          }
        }
      }
      return false;
    },
  },
  emits: {
    next_select: null,
    download_files: null,
    update_multi_select: null,
  },
  props: {
    draw_index: {default: 0},
    draw_info: {default: {}},
    draw_data: {default: {}},
    before_total: {default: null},
  },
});
</script>

<style  lang="less">
.download-icon {
  .flex(row, flex-start, flex-start);
  cursor: pointer;
}
</style>
