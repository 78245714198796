<template>
  <ModalView ref = "settings_modal_view_ref"
             :open_level = "1"
             type_modal = "internal"
             position = "fixed"
             :caption = "'Смена пароля'"
             @click_close = "this.$emit('closeChangePasswordModal')">
    <div class = "changePasswordModal__container">

      <div class = "changePasswordModal__container__group">
        <LabelComponent class = "changePasswordModal__container__header"
                        label_type = "button/large/medium 14"
                        label_text = "После смены пароля на всех устройствах будет необходимо авторизоваться заново!"/>

        <LabelComponent v-if="this.success === true"
                        class = "changePasswordModal__container__header"
                        label_type = "button/large/medium 14"
                        style="color: green;"
                        label_text = "Пароль успешно изменен! Перезагрузите страницу"/>
        <LabelComponent v-if="this.success !== null && this.success !== true"
                        class = "changePasswordModal__container__header"
                        label_type = "button/large/medium 14"
                        style="color: red;"
                        :label_text="this.success"/>
        <LabelComponent class = "changePasswordModal__container__header"
                        label_type = "button/large/medium 14"
                        label_text = "Старый пароль"/>
        <LabelComponent v-if="this.oldPassIsNotCorrect"
                        class = "changePasswordModal__container__header"
                        label_type = "button/large/medium 14"
                        style="color: red;"
                        label_text = "Старый пароль неверный!"/>
        <InputField class = "changePasswordModal__container__input"
                    type = "password"
                    label_text = "Старый пароль"
                    :set_values="this.oldPass"
                    @input_change="(newVal) => {this.oldPass = newVal; this.oldPassIsNotCorrect = false;}"
                    />
      </div>
      <div class = "changePasswordModal__container__group">
        <LabelComponent class = "changePasswordModal__container__header"
                        label_type = "button/large/medium 14"
                        label_text = "Новый пароль"/>
        <InputField class = "changePasswordModal__container__input"
                    type = "password"
                    label_text = "Новый пароль"
                    :set_values="this.newPass"
                    @input_change="(newVal) => {this.newPass = newVal; this.notEqualPasswords = false;}"
                    />
      </div>
      <div class = "changePasswordModal__container__group">
        <LabelComponent class = "changePasswordModal__container__header"
                        label_type = "button/large/medium 14"
                        label_text = "Повторите пароль"/>
        <LabelComponent v-if="this.notEqualPasswords"
                        class = "changePasswordModal__container__header"
                        label_type = "button/large/medium 14"
                        style="color: red;"
                        label_text = "Новые пароли не совпадают!"/>
        <InputField class = "changePasswordModal__container__input"
                    type = "password"
                    label_text = "Повторите пароль"
                    :set_values="this.confirmPass"
                    @input_change="(newVal) => {this.confirmPass = newVal; this.notEqualPasswords = false;}"
                    />
      </div>
      <ButtonComponent class = "changePasswordModal__container__button"
                       label = "ИЗМЕНИТЬ"
                       elementType = "default-bg"
                       :elementInLoading="this.requesting"
                       :elementStatusModifier="this.requesting ? 'disabled' : null"
                       @click_to_button="this.changeRequest" />

      <LabelComponent class = "tips_for_password"
                        label_type = "button/large/medium 14"
                        label_text = "Tips for a good password"/>
      <LabelComponent class = "tips_for_password"
                        label_type = "caption/small/medium 14"
                        label_text = "Use both upper and lowercase characters."/>
      <LabelComponent class = "tips_for_password"
                        label_type = "caption/small/medium 14"
                        label_text = "Include at least one symbol (# $ ! % & etc.)."/>
      <LabelComponent class = "tips_for_password"
                        label_type = "caption/small/medium 14"
                        label_text = "Don't use general words and phrases."/>
    </div>
  </ModalView>
</template>

<script>
// import builder from '../@/assets/v1/js/builder';
import ModalView from '@/layoutTools/ModalView';
import LabelComponent from '@/units/Label';
import ButtonComponent from '@/units/forms/Button';
import InputField from '@/units/forms/InputField';

import {changePasswordRequest} from '@/assets/dataSources/auth/changePasswordRequest';

export default /* builder */({
  name: 'ChangePasswordModal',
  emits: ['closeChangePasswordModal'],
  components: {
    ModalView,
    LabelComponent,
    ButtonComponent,
    InputField,
  },
  data: () => ({
    oldPass: '',
    newPass: '',
    confirmPass: '',
    requesting: false,
    notEqualPasswords: false,
    oldPassIsNotCorrect: false,
    success: null,
  }),
  methods: {
    changeRequest() {
      if (this.requesting) {
        return;
      }

      this.success = null;

      if (this.newPass != this.confirmPass) {
        this.notEqualPasswords = true;
        return;
      }

      this.requesting = true;

      const curThis = this;

      changePasswordRequest(this.oldPass, this.newPass).then(async function(response) {
        curThis.requesting = false;

        if (response.status == 200) {
          curThis.success = true;
          curThis.oldPass = '';
          curThis.newPass = '';
          curThis.confirmPass = '';
          return;
        }

        if (response.status == 403) {
          curThis.success = 'Неверный старый пароль!';
          curThis.oldPass = '';
          curThis.newPass = '';
          curThis.confirmPass = '';
          return;
        }

        if (response.status == 400) {
          const respText = await response.text();

          curThis.success = 'Ваш пароль не соответствует правилам безопасности!\r\n\r\n' + respText;
          curThis.newPass = '';
          curThis.confirmPass = '';
          return;
        }

        if (response.status == 401) {
          curThis.success = 'Вы неавторизованы!';
          curThis.oldPass = '';
          curThis.newPass = '';
          curThis.confirmPass = '';
          return;
        }

        console.log('response', response);

        const respText = await response.text();

        curThis.success = String(response.status) + ' ' + respText;
      }).catch(function() {
        curThis.requesting = false;
        curThis.success = 'Ошибка связи с сервером! Попробуйте обновить страницу.';
      });
    },
  },
});
</script>

<style lang = "less">
.tips_for_password{
  display: flex;
}
.changePasswordModal {
  &__container {
    min-width: 250px;
    width: 15vw;
    display: grid;
    grid-row-gap: 20px;

    &__group {
      display: grid;
      grid-row-gap: 10px;
    }
    &__input {
      width: 100%;
    }
  }

}
</style>
