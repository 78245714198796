import {ROOT_URL, store} from '@/store';

const FULL_REQUEST_LINK = '/api/notifications/';
const COUNT_REQUEST_LINK = '/api/new_notif_count/';

function getNotificationInfo(handler, onlyCount=false) {
  store.commit('set_full_screen_loading', true);

  fetch(ROOT_URL + (onlyCount ? COUNT_REQUEST_LINK : FULL_REQUEST_LINK), {
    method: 'GET',
    credentials: 'include',
    // body: formData,
  }).then(async function(response) {
    if (onlyCount) {
      const data = Number.parseInt(await response.text());
      handler(data);
    } else {
      const data = await response.json();
      handler(data);
    }
  }).finally(() => store.commit('set_full_screen_loading', false));
}

export default getNotificationInfo;
