function runAppWork(filename, blobData, justSaveCallback) {
  if (justSaveCallback != null) {
    window.sendMessageInApplication({
      type: 'save_file',
      data: `${filename};${blobData}`,
      callback: justSaveCallback,
    });
  } else {
    window.sendMessageInApplication({
      type: 'open_file',
      data: `${filename};${blobData}`,
    });
  }
}


export default (url, filename, justSaveCallback) => {
  if (!filename) {
    filename = 'undefined';
  }

  let checkFunction = false;

  if (window.WORK_IN_APPLICATION) {
    checkFunction = window.hasFunctions(['open_file']);

    if (justSaveCallback != null) {
      checkFunction = window.hasFunctions(['save_file']);
    }
  }

  if (window.WORK_IN_APPLICATION && checkFunction) {
    async function download() {
      if (String(url).startsWith('data:')) {
        runAppWork(filename, url, justSaveCallback);
      } else {
        // we use a second try block here to have more detailed error information
        // because of the nature of JS the outer try-catch doesn't know anything where the error happended
        const res = await fetch(url, {
          credentials: 'include',
        });

        const data = await res.blob();

        const fr = new FileReader();
        fr.onload = () => {
          runAppWork(filename, String(fr.result), justSaveCallback);
        };
        fr.readAsDataURL(data);
      }
    }
    download();
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = filename;
    a.click();
    document.body.removeChild(a);
  }
};
