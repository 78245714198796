import {ROOT_URL, store} from '@/store';

const REQUEST_LINK = '/translate/language/?lang=';

function getTranslations(handler, lang) {
  store.commit('set_full_screen_loading', true);
  fetch(ROOT_URL + REQUEST_LINK + lang, {
    method: 'GET',
    credentials: 'include',
    // body: JSON.stringify({params: {}}),
  }).then(async function(response) {
    const data = await response.json();
    handler(data);
  }).finally(() => store.commit('set_full_screen_loading', false));
}

export default getTranslations;
