<template>
  <BasePage name="Mutual Sympathies">
    <div v-if="cards && cards.length > 0">
    <ViewedProfileList :cards="this.cards" @card_click="this.cardClick">
      <template v-slot="{card_data}">
        <!-- <template v-if="card_data.self_is_main">
          <InputField       label_text="Date time"
                            :set_values="card_data.date_date"
                            @click.stop="''"
                            @input_change="(data) => card_data.date_date = data"
                            :min="new Date()"
                            :max="(new Date()).shiftDateFromCurrent(0, 0, 7)"
                            type="datetime"/>
          <br/>
          <ButtonComponent label="Make date" elementType="default-bg" :elementStatusModifier="card_data.date_date == null ? 'disabled' : undefined" @click.stop="this.makeMeeting(card_data)"/>
        </template>
        <template v-else>
          <LabelComponent label_text="Ждите пока назначат свидание!" />
        </template> -->
        <template v-if="card_data.meeting != null">
          <div class="waiting_text" v-if="!card_data.meeting.self_payed && !card_data.meeting.other_payed">
            <LabelComponent label_text="mutual_symp_arose"
                                  label_type="body/large/regular 12"/>
          </div>
          <div class="waiting_text" v-if="card_data.meeting.other_payed">
            <LabelComponent label_text="Your chosen one has already paid for organization of online date with you, and is eagerly waiting for online meeting with you.Once you make a payment, you both will be relocated to the MY DATES Section for online meeting."
                                  label_type="body/large/regular 12"/>
          </div>
          <div class="cancel_button" v-if="!card_data.meeting.self_payed">
                  <ButtonComponent :newTab="true" label="PAY MEETING" @click.stop="this.payDate(card_data.meeting.meet_id)" elementType="default-bg" />
                  <ModalView v-if="this.user_message_text != null" :open_level="1" type_modal="internal" position="fixed" caption=" " @click_close="this.user_message_text = null">
                  <LabelComponent :label_text="this.user_message_text" label_type="button/large/medium 18" class="liked_text" />
                  </ModalView>
          </div>
          <div class="waiting_text" v-else>
                  <LabelComponent label_text="Once your chosen one makes payment, you both will be relocated to the MY DATES Section for your online meeting." />
            </div>
        </template>
        <!-- <template v-if="!card_data.self_is_main && !card_data.meeting.self_payed">
              <LabelComponent label_text="Once you make a payment, you both will be relocated to the MY DATES Section for online meeting."
                                  label_type="body/large/regular 12"/>
        </template> -->
        <div class="cancel_button">
           <ButtonComponent
                          label="CANCEL SYMPATHY"
                          elementType="hover-bg"
                          @click.stop="this.dislikeClick(card_data)"
                          />
        </div>
      </template>
    </ViewedProfileList>
  </div>
  <div v-else class="text_if_no_card">
      <LabelComponent label_text="Достойные Кандидаты и Претенденты появятся здесь в ближайшее время"/>
    </div>
    <div class="get-more-button-wraper">
      <ButtonComponent label="ORDER ANOTHER PACK" elementType="default-bg" @click="this.buyNewPackage"/>
    </div>
    <ModalView v-if="this.global_message_text != null" :open_level="1" type_modal="internal" position="fixed" caption=" " @click_close="this.global_message_text = null">
      <LabelComponent :label_text="this.global_message_text" label_type="button/large/medium 18" class="liked_text" />
        <br />
    </ModalView>
  </BasePage>
</template>

<script>
import BasePage from '@/baseLayout/BasePage';
import ButtonComponent from '@/units/forms/Button';
import LabelComponent from '@/units/Label';
// import InputField from '@/units/forms/InputField';
import ModalView from '@/layoutTools/ModalView';
import ViewedProfileList from './ViewedProfileList';

import getFormData from '@/assets/dataSources/getFormData';
import sendFormData from '@/assets/dataSources/sendFormData';
import getPayLink from '@/assets/dataSources/getPayLink';


export default ({
  components: {
    ModalView,
    BasePage,
    ButtonComponent,
    LabelComponent,
    // InputField,
    ViewedProfileList,
  },
  data: ()=> ({
    global_message_text: null,
    user_message_text: null,
    cards: [
      {
        id: '1',
        image_url: '/icons/law.svg',
        user_id: '0000000',
      },
      {
        id: '2',
        image_url: '/icons/law.svg',
        user_id: '0000001',
      },
    ],
  }),
  props: {

  },
  mounted() {
    this.updateCards();
  },
  computed: {

  },
  methods: {
    updateCards() {
      getFormData((servData) => {
        this.cards = servData;

        if (this.$route.query.autopay != null) {
          console.log('AUTOPAYED!');
          this.payDate(this.$route.query.autopay);
        }
      }, 'mutual_sympathies');
    },
    cardClick(card) {
      window.location.href = '/presentation/' + card.id + '/';
    },
    makeMeeting(card) {
      if (card.date_date == null) {
        return;
      }

      const curForm = new FormData();
      curForm.append('viewed_profile_id', card.id);
      curForm.append('date_date', card.date_date.toISOString());

      sendFormData((result) => {
        if (result.status == 200) {
          window.globalEvents.callEvent('new_message', [{
            messageType: 'success',
            messageData: 'Свидание создано. Перейдите на страницу свиданий для подробностей!',
          }]);
        }

        if (result.status == 400) {
          window.globalEvents.callEvent('new_message', [{
            messageType: 'warning',
            messageData: 'Убедитесь что свидания не существует и вернитесь на эту страницу!',
          }]);
        }

        this.updateCards();
      }, 'mutual_sympathies', curForm);
    },
    payDate(meetId) {
      this.$router.replace({'query': null});

      sendFormData(async (result) => {
        this.updateCards();

        if (result.status == 200) {
          this.user_message_text = 'Thank You for your payment!';
          // window.globalEvents.callEvent('new_message', [{
          //   messageType: 'success',
          //   messageData: 'Pay date success!',
          // }]);
          return;
        }

        const responseText = await result.text();

        if (result.status == 400 && String(responseText).startsWith('pay_req')) {
          getPayLink((link) => {
            window.open(link, '_blank');
          }, Number.parseFloat(String(responseText).split('|')[1]), '/dates/autopay=' + meetId);
          return;
        }

        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: {
            name: 'Error',
            message: 'Bad server response! Status: ' + result.status,
          },
        }]);
      }, 'pay_meeting/' + meetId);
    },
    // likeClick(card) {
    //   const curForm = new FormData();
    //   curForm.append('viewed_profile_id', card.id);

    //   sendFormData(() => {
    //     this.updateCards();
    //   }, 'mutual_sympathies', curForm);
    // },
    dislikeClick(card) {
      this.user_message_text= 'You canceled Sympathy.';
      const curForm = new FormData();
      curForm.append('viewed_profile_id', card.id);

      sendFormData(() => {
        this.updateCards();
      }, 'my_sympathies', curForm);
    },
    buyNewPackage() {
      sendFormData((result) => {
        if (result.status == 200) {
          this.global_message_text = 'The new package has been successfully purchased. Please expect Presentations of new Candidates to appear.';
        } else if (result.status == 400) {
          this.global_message_text = 'There are not enough funds to purchase the package, please top up your Wallet.';
        } else if (result.status == 409) {
          this.global_message_text = 'A new Pack can be ordered after all the Presentations of already ordered Candidates have arrived. Thank you for your desire to receive more Candidates.';
        };
      }, 'buy_candidates_package');
    },
  },
});
</script>

<style lang="less">
.get-more-button-wraper {
  .flex(row, center, center);
}
</style>
