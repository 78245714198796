<!-- eslint-disable no-irregular-whitespace -->
<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <AuthBase>
    <form class="register-frame" autocomplete="off">
      <LabelComponent class="register-frame__header" label_type="h1" :label_text="'Регистрация в Агентстве'"/>
      <div class="register-frame__footer">
        <LabelComponent label_type="button/large/regular 14" label_text="Уже есть аккаунт?"/>
        <ButtonComponent label="ВОЙТИ" elementSize="default" elementType="default" @click="this.$router.replace({ path: '/login' });"/>
      </div>
      <LabelComponent class="register-frame__global-error" v-show="this.global_error_text != null" :label_text="this.global_error_text"/>
      <LabelComponent class="register-frame__global-success" v-if="this.global_success_text != null" :label_text="this.global_success_text"/>
      <div class="register-frame__fields-wrapper" v-else>
        <div class="header_text_registration">
          <LabelComponent label_type="body/large/regular 12" label_text="Enter the requested data and follow the link that will be emailed to you."/>
        </div>
        <template v-for="(field, fieldName) in form_data" :key="fieldName">
          <DropDownInput v-if="fieldName == 'country_of_residence'" class="register-frame__login-field"
                         :is_filterable="true"
                         :is_multi_select="false"
                         :dropdown_items_init="ddDsCountries"
                         :label_text="field.label"
                         :input_field_status="getFieldStatus(fieldName)"
                         :error_text="field.error"
                         :always_mobile_view="false"
                         @input_change="(data) => setFormValue(fieldName, data[0] ? data[0].return_value : null)"/>
          <InputField v-else-if="fieldName != 'privacy_policy_agreed' && fieldName != 'children'" class="register-frame__login-field"
                      :label_text="field.label"
                      :input_field_status="getFieldStatus(fieldName)"
                      :error_text="field.error || (field.pattern && !new RegExp(field.pattern).test(field.val) ? field.invalid_message : '')"
                      autocomplete="off"
                      @input_change="(data) => setFormValue(fieldName, data)"
                      :type="field.itype ? field.itype : 'text'"
                      :check_pattern="field.pattern ? field.pattern : '.+'"
                      :pattern_invalid_message="field.invalid_message"/>
        </template>
        <div class="policy-field register-frame__login-field">
          <LabelComponent label_text="I agree with the"/>
          <ButtonComponent label="Privacy Statement" elementType="default" elementSize="default" href="https://marriageagency.family/privacy-statement"/>
          <CheckBox :check_box_field_status="getFieldStatus('privacy_policy_agreed')" :error_text="form_data['privacy_policy_agreed'].error" @input_check="(data) => setFormValue('privacy_policy_agreed', data ? 'on' : undefined)"/>
        </div>
        <div class="header_text_registration">
          <LabelComponent label_type="body/large/regular 12" label_text="This is how easy and quick you can register on the «International Marriage Agency» website. Five minutes spent on this, will be more than repaid by pleasant communication with decent candidates."/>
        </div>
      </div>
      <ButtonComponent class="register-frame__button1" v-if="global_success_text == null" label_type="button/large/medium 18" elementSize="default" @click="registerUserInSystem()" label="ЗАРЕГИСТРИРОВАТЬСЯ"/>
    </form>
  </AuthBase>
</template>


<script>
import LabelComponent from '@/units/Label';
import ButtonComponent from '@/units/forms/Button';
import InputField from '@/units/forms/InputField';
import DropDownInput from '@/units/forms/DropDownInput';
import CheckBox from '@/units/forms/CheckBox';
import AuthBase from './AuthBase';

import getRefList from '@/assets/dataSources/getRefList';
import sendFormData from '@/assets/dataSources/sendFormData';


export default {
  components: {
    LabelComponent,
    ButtonComponent,
    InputField,
    DropDownInput,
    AuthBase,
    CheckBox,
  },
  data() {
    return {
      pattern_invalid_message: 'Можно вводить только буквы алфавита',
      length_invalid_message: 'Поле должно содержать не менее 2 букв',
      same_name_surname_message: 'Фамилия и имя не должны совпадать',
      global_error_text: undefined,
      global_success_text: undefined,
      form_data: {
        surname: {label: 'Last Name', error: null, val: null, pattern: '^[a-zA-Zа-яА-Я]+$', invalid_message: 'Фамилия должна содержать только буквы (не менее 2 букв)'},
        name: {label: 'First Name', error: null, val: null, pattern: '^[a-zA-Zа-яА-Я]+$', invalid_message: 'Имя должно содержать только буквы (не менее 2 букв)'},
        country_of_residence: {label: 'Country of Residence', error: null, val: null},
        email: {label: 'Email', error: null, val: null, itype: 'email'},
        password1: {label: 'Password', error: null, val: null, itype: 'password'},
        password2: {label: 'Password confirmation', error: null, val: null, itype: 'password'},
        privacy_policy_agreed: {label: 'I agree with the privacy policy', error: null, val: false},
      },
      countries: [],
    };
  },
  mounted() {
    window.acceptableElements.push(this.registerUserInSystem);
    this.updateCountries();
  },
  unmounted() {
    const index = window.acceptableElements.indexOf(this.registerUserInSystem);
    if (index > -1) {
      window.acceptableElements.splice(index, 1);
    }
  },
  computed: {
    ddCountries() {
      return this.countries.map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    ddDsCountries() {
      return this.countries.filter((outVal) => outVal.id !== 1).map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
  },
  methods: {
    updateCountries() {
      getRefList((newList) => {
        newList.shift();
        this.countries = newList;
      }, 'Country');
    },
    getFieldStatus(field) {
      return this.form_data[field].error ? 'error' : undefined;
    },
    setFormValue(field, value) {
      this.global_error_text = null;
      this.form_data[field].val = value;
      this.form_data[field].error = null;
    },
    registerUserInSystem() {
      let isError = false;
      const fillCorrectText = 'You were unable to get registered: invalid data has been entered. Please try again.';

      Object.keys(this.form_data).forEach((key) => {
        const fieldValue = this.form_data[key].val;
        const fieldPattern = this.form_data[key].pattern ? new RegExp(this.form_data[key].pattern) : null;

        if (!fieldValue || fieldValue.length < 1) {
          this.form_data[key].error = 'Поле должно быть заполнено';
          isError = true;
        } else if (['name', 'surname'].includes(key)) {
          if (fieldValue.length < 2) {
            this.form_data[key].error = this.length_invalid_message;
            isError = true;
          } else if (fieldPattern && !fieldPattern.test(fieldValue)) {
            this.form_data[key].error = this.pattern_invalid_message;
            isError = true;
          } else {
            this.form_data[key].error = null;
          }
        }
      });

      if (this.form_data.name.val && this.form_data.surname.val && this.form_data.name.val === this.form_data.surname.val) {
        this.form_data.name.error = this.same_name_surname_message;
        this.form_data.surname.error = this.same_name_surname_message;
        isError = true;
      }
      if (this.form_data.name.val && this.form_data.surname.val && this.form_data.name.val.toLowerCase() === this.form_data.surname.val.toLowerCase()) {
        this.form_data.name.error = this.same_name_surname_message;
        this.form_data.surname.error = this.same_name_surname_message;
        isError = true;
      }

      if (!this.form_data.privacy_policy_agreed.val) {
        this.form_data.privacy_policy_agreed.error = 'Примите политику конфиденциальности!';
        isError = true;
      }

      if (this.form_data.password1.val !== this.form_data.password2.val) {
        this.form_data.password1.error = 'Введенные пароли не совпадают!';
        this.form_data.password2.error = this.form_data.password1.error;
        isError = true;
      }

      if (isError) {
        this.global_error_text = fillCorrectText;
        return;
      }

      const postForm = new FormData();
      postForm.append('_lang', this.$store.state.base_lang);
      Object.keys(this.form_data).forEach((key) => {
        const fieldValue = this.form_data[key].val;
        if (this.form_data[key].itype === 'inline') {
          if (!fieldValue) return;

          postForm.append(`${key}-TOTAL_FORMS`, fieldValue.length);
          fieldValue.forEach((elem, idx) => {
            Object.keys(elem).forEach((inlineKey) => {
              postForm.append(`${key}-${idx}-${inlineKey}`, elem[inlineKey]);
            });
          });
        } else if (Array.isArray(fieldValue)) {
          fieldValue.forEach((elem) => postForm.append(key, elem));
        } else {
          postForm.append(key, fieldValue);
        }
      });

      sendFormData(async (response) => {
        if (response.status === 200) {
          this.global_success_text = 'You have successfully registered in our Agency! Link to activate your account has been emailed to you. If you haven’t received a registration confirmation email, you should check your spam.';
          return;
        }
        if (response.status === 400) {
          this.global_error_text = fillCorrectText;
          const errorPack = await response.json();
          Object.keys(errorPack).forEach((erField) => {
            this.form_data[erField].error = errorPack[erField].join(' ');
          });
        }
      }, 'register', postForm);
    },
  },
};
</script>


<style lang="less">
.register-frame {
  // overflow: hidden;
  .flex(column, flex-start, center);
  position: absolute;
  width: 390px;
  max-height: 90%;
  //border: 1px solid #000000;
  border-radius: 15px;
  box-shadow: 0 10px 15px #505050;
  padding: 20px;

  &__header {
    .flex(row, center, flex-end);
    text-align: center;
    flex-basis: 0.4;
    vertical-align: middle;
    margin-bottom: 10px;
    font-size: 20px;
  }
  &__global-error {
    color: red;
    text-align: center;
  }
  &__global-success {
    color: green;
    text-align: center;
  }

  .set_custom_scrollbar(6px, 10px, 0px, 20px, 20px);

  &__fields-wraper {
    height: 100%;
    width: 330px;
    padding-right: 5px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__login-field {
    margin: 11px 0;
  }
  &__button1 {
    width: 75%;
    max-width: none;
    margin-top: 10px;
  }
  &__button2 {
    margin-top: 20px;
    color: hsl(0, 100%, 55%);
  }

  &__footer{
    .flex(row, center, center);
    flex-basis: 35px;
    flex-wrap: wrap;
    gap: 3px;
  }

  .policy-field {
    .flex(row, center, center);
    gap: 3px
  }
  .header_text_registration{
    .flex(row, center, flex-end);
    text-align: center;
    flex-basis: 0.4;
    vertical-align: middle;
    margin-bottom: 10px;
  }
}
</style>
