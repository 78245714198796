import {createRouter, createWebHistory} from 'vue-router';

import store from '@/store';
import getUserInfo from '@/assets/dataSources/auth/getUserInfo';
// import getWebSiteStructure from '@/assets/dataSources/getWebSiteStructure';

import LoadingPage from '@/baseLayout/LoadingPage';

// import BasePage from '@/baseLayout/BasePage';
import Page404 from '@/baseLayout/Page404';
import LoginPage from '@/baseLayout/auth/LoginPage';
import RegistrationPage from '@/baseLayout/auth/RegistrationPage';
import ResetPassword from '@/baseLayout/auth/ResetPassword';
import ObjectCreateForm from '@/adminLayout/ObjectCreateForm';
import SelectObjectList from '@/adminLayout/SelectObjectList';
import ObjectEditForm from '@/adminLayout/ObjectEditForm';
import ReportPage from '@/reportsLayout/ReportPage';
import getTranslations from '@/assets/dataSources/getTranslations';
import {pluginsRoutes} from '@/plugins/pluginsRouter.js';

import questionnaire from '@/pagesLayout/questionnaire';
import presentation from '@/pagesLayout/presentation';
import packages from '@/pagesLayout/packages';
import IndexPage from '@/pagesLayout/IndexPage';
import sympathy from '@/pagesLayout/sympathy';
import challengers from '@/pagesLayout/challengers';
import mutual from '@/pagesLayout/mutual';
import dates from '@/pagesLayout/dates';
import wallet from '@/pagesLayout/wallet';
import notifications from '@/pagesLayout/notifications';
import userprofile from '@/pagesLayout/userprofile';

const authUserPages = [
  {
    in_fast_panel: true,
    link: '/questionnaire/',
    name: 'QUESTIONNAIRE',
    icon: '/icons/survey.svg',
  },
  {
    in_fast_panel: true,
    link: '/presentation/self/',
    name: 'MY PRESENTATION',
    icon: '/icons/presentation.svg',
  },
  {
    in_fast_panel: true,
    link: '/packages/',
    name: 'CANDIDATES',
    icon: '/icons/packages.svg',
  },
  {
    in_fast_panel: true,
    link: '/sympathy/',
    name: 'MY SYMPATHIES',
    icon: '/icons/symp.svg',
  },
  {
    in_fast_panel: true,
    link: '/challengers/',
    name: 'CHALLENGERS',
    icon: '/icons/applicants.svg',
  },
  {
    in_fast_panel: true,
    link: '/mutual/',
    name: 'MUTUAL SYMPATHIES',
    icon: '/icons/mutual.svg',
  },
  {
    in_fast_panel: true,
    link: '/dates/',
    name: 'MY DATES',
    icon: '/icons/meeting.svg',
  },
  {
    in_fast_panel: true,
    link: '/wallet/',
    name: 'MY WALLET',
    icon: '/icons/wallet.svg',
  },
  {
    in_fast_panel: true,
    link: '/notifications/',
    name: 'NOTIFICATIONS',
    icon: '/icons/not.svg',
  },
];

const routes = [
  {
    path: '/questionnaire',
    name: 'MY QUESTIONNAIRE',
    component: questionnaire,
  },
  {
    path: '/presentation/:presentation_owner/',
    name: 'presentation',
    component: presentation,
  },
  {
    path: '/packages',
    name: 'packages',
    component: packages,
  },
  {
    path: '/sympathy',
    name: 'sympathy',
    component: sympathy,
  },
  {
    path: '/challengers',
    name: 'challengers',
    component: challengers,
  },
  {
    path: '/mutual',
    name: 'mutual',
    component: mutual,
  },
  {
    path: '/dates',
    name: 'dates',
    component: dates,
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: wallet,
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: notifications,
  },
  {
    path: '/userprofile',
    name: 'userprofile',
    component: userprofile,
  },
  {
    path: '/select_objects/:objectName',
    name: 'SelectObjectList',
    component: SelectObjectList,
  },
  {
    path: '/object_create_form/:objectName',
    name: 'ObjectCreateForm',
    component: ObjectCreateForm,
  },
  {
    path: '/object_edit_form/:objectName/:objectUid',
    name: 'ObjectEditForm',
    component: ObjectEditForm,
  },
  {
    path: '/report/:reportName',
    name: 'ReportPage',
    component: ReportPage,
  },
  {
    path: '/plugins',
    children: pluginsRoutes,
  },
  {
    path: '/',
    name: 'IndexPage',
    component: IndexPage,
  },
  {
    path: '/page_404',
    name: 'Page404',
    component: Page404,
    props: (route) => ({
      path: route.redirectedFrom ? route.redirectedFrom.path : '',
    }),
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    props: (route) => {
      return {next_page: route.query.next_page};
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: RegistrationPage,
  },
  {
    path: '/reset_password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/loading_initial',
    name: 'LoadingInitial',
    component: LoadingPage,
    props: (route) => ({
      func: () => {
        const afterRequestFunc = (isAnon) => {
          if (route.query.lang != null) {
            const lang = route.query.lang;
            store.state.base_lang = lang;
            const language = store.state.translations[lang];
            if (language == null) {
              // this.$store.commit('set_full_screen_loading', true);
              getTranslations((trans) => store.state.translations[lang] = trans, lang);
            }
          }

          if (isAnon) store.commit('web_site_struct_mut', []);
          else store.commit('web_site_struct_mut', authUserPages);

          store.commit('mutate_notification_count');

          const toQuery = JSON.parse(JSON.stringify(route.query));
          delete toQuery.next_page;

          const to = {
            path: route.query.next_page,
            query: toQuery,
          };
          if (store.getters.isEndLoadAuthInfo) router.replace(to);
        };

        getUserInfo((data) => {
          const result = (data != null) ? data : null;
          store.commit('user_obj_mut', result);
          afterRequestFunc(result.is_anonymous);
        });
      },
    }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!store.getters.isEndLoadAuthInfo && to.name !== 'LoadingInitial') {
    // Если данные еще не скачивались, то скачать начальные данные
    next({name: 'LoadingInitial', query: {next_page: to.path, ...to.query}});
  } else if (/* window.WORK_IN_APPLICATION && */ store.getters.isEndLoadAuthInfo && !store.getters.isLoggedIn && (to.name !== 'Login' && to.name !== 'Register' && to.name !== 'ResetPassword')) {
    // Если начальные данные скачаны, но их нет, то отправить на страницу логина
    next({name: 'Login', query: {next_page: to.path}});
  } else if (store.getters.isLoggedIn && (to.name === 'Login' || to.name === 'Register' || to.name === 'ResetPassword')) {
    // Если человек уже аутентифицирован, то ему не доступна страница логина
    next({path: '/'});
  } else if (to.path === '/' && store.getters.getSortedLayer(null)[0]) {
    // Закидываем на первую страницу навигации
    next({path: store.getters.getSortedLayer(null)[0].url});
  } else if (router.resolve(to).matched.length === 0 && !['Page404'].includes(to.name)) {
    // 404
    next({name: 'Page404'});
  } else {
    // console.log(store.getters.isLoggedIn, to.name);
    next();
  }
});

export default router;
