<template>
  <BasePage :name="'Notifications'">
    <div class="not">
      <div class="one_not" v-for="notif in this.notifications" :key="notif" @click="this.notifClick(notif)" :class="notif.read ? null : 'one_not__unread'">
          <LabelComponent :label_text="notif.header"/>
          <LabelComponent :label_text="notif.text"/>
          <LabelComponent :label_text="this.formatedDate(notif.date)"/>
          <!-- <LabelComponent :label_text="'is readed: ' + notif.read"/> -->
      </div>
    </div>
  </BasePage>
</template>

<script>
import BasePage from '@/baseLayout/BasePage';
import LabelComponent from '@/units/Label';
// import ButtonComponent from '@/units/forms/Button';

import getNotificationInfo from '@/assets/dataSources/pages/getNotificationInfo';
import sendFormData from '@/assets/dataSources/sendFormData';

export default ({
  components: {
    BasePage,
    // ButtonComponent,
    LabelComponent,
  },
  data: ()=> ({
    notifications: [],
  }),
  props: {

  },
  mounted() {
    this.updateNotificationData();
  },
  computed: {

  },
  methods: {
    formatedDate(date) {
      if (!date) {
        return '-';
      }
      date = new Date(date);
      const dateText = date.toLocaleDateString('ru-RU', {timeZone: 'Europe/Zurich'});
      const timeText = date.toLocaleTimeString('en-EN', {timeZone: 'Europe/Zurich', hour: '2-digit', minute: '2-digit'});

      // datetime
      return dateText + ' ' + timeText;
    },
    notifClick(notification) {
      notification.read = true; // Локально помечаем как прочитанное

      const sendForm = new FormData();
      sendForm.append('read-notif', notification.id);

      sendFormData((result) => {
        // Обработка успешного ответа
        // Не вызываем updateNotificationData, чтобы избежать полной перезагрузки списка
        this.$store.commit('mutate_notification_count');
      }, 'notifications', sendForm);
    },

    updateNotificationData() {
      getNotificationInfo((newData) => {
        this.notifications = newData.notifications;
      });
    },
  },
});
</script>

<style lang="less">
  .not {
    padding: 13px;
  }
  .one_not{
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    flex-direction: column;

    &__unread {
      background-color: @pink;
    }
  }
</style>
