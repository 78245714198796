<template>
  <AuthBase>
    <div class="reset-pass-frame">
      <div class="reset_pass_frame_header_text">
        <LabelComponent class="reset-pass-frame__header"
                        label_type="h1"
                        :label_text="'Смена пароля'"/>
      </div>
      <form>
        <div class="reset-pass-frame__footer">
          <LabelComponent class="reset-pass-frame__global-error"
                          v-show="this.global_error_text != null"
                          :label_text="this.global_error_text"/>
          <LabelComponent class="reset-pass-frame__global-success"
                          v-show="this.global_success_text != null"
                          :label_text="this.global_success_text"/>

          <template v-if="!passwordChanged">
            <template v-if="this.resetSessionKey == null">
              <InputField class="reset-pass-frame__login-field"
                          @input_change="this.setLogin"
                          name="username"
                          autocomplete="on"
                          :label_text="'Имя Пользователя (e-mail адрес)'"
                          :input_field_status="this.login_input_data.status"
                          :error_text="this.login_input_data.error_text"/>

              <ButtonComponent class="reset-pass-frame__button1"
                               label_type="button/large/medium 18"
                               elementSize="default"
                               @click="this.resetRequest"
                               :label="'СМЕНА ПАРОЛЯ'"
                               v-show="!global_success_text"/>
            </template>
            <template v-else>
              <InputField class="login-frame__login-field"
                          @input_change="this.setPassword"
                          name="password"
                          autocomplete="on"
                          label_text="Новый пароль"
                          :input_field_status="this.password_input_data.status"
                          :error_text="this.password_input_data.error_text"
                          type="password"/>
              <InputField class="login-frame__login-field"
                          @input_change="this.setConfirmPassword"
                          name="password"
                          autocomplete="on"
                          label_text="Подтверждение нового пароля"
                          :input_field_status="this.password_input_data.status"
                          :error_text="this.password_input_data.error_text"
                          type="password"/>

              <ButtonComponent class="reset-pass-frame__button1"
                               label_type="button/large/medium 18"
                               elementSize="default"
                               @click="this.sendNewPassword"
                               :label="'УСТАНОВИТЬ'"/>
            </template>
          </template>

          <ButtonComponent v-show="passwordChanged"
                           elementSize="default"
                           elementType="default"
                           @click="this.$router.replace({ path: '/login' })"
                           :label="'Авторизация'"/>
        </div>
      </form>
    </div>
  </AuthBase>
</template>

<script>
import LabelComponent from '@/units/Label';
import ButtonComponent from '@/units/forms/Button';
import InputField from '@/units/forms/InputField';
import AuthBase from './AuthBase';

import {resetPassRequest} from '@/assets/dataSources/auth/resetPassRequest';
import {setNewPassRequest} from '@/assets/dataSources/auth/setNewPassRequest';

export default ({
  components: {
    LabelComponent,
    ButtonComponent,
    InputField,
    AuthBase,
  },
  data: () => ({
    global_error_text: undefined,
    global_success_text: undefined,
    login_input_data: {
      status: undefined,
      error_text: undefined,
    },
    password_input_data: {
      status: undefined,
      error_text: undefined,
    },
    login: undefined,
    password: undefined,
    confirm_password: undefined,
    passwordChanged: false,
  }),
  computed: {
    resetSessionKey() {
      return this.$route.query.key;
    },
  },
  methods: {
    setLogin(data) {
      this.login = data;
    },
    setPassword(data) {
      this.password = data;
    },
    setConfirmPassword(data) {
      this.confirm_password = data;
    },
    sendNewPassword() {
      if (this.password !== this.confirm_password) {
        this.global_error_text = 'Пароли не совпадают!';
        return;
      }

      if (!this.password) {
        this.global_error_text = 'Пароль не может быть пустым!';
        return;
      }

      this.$store.commit('set_full_screen_loading', true);

      const request = setNewPassRequest(this.password, this.resetSessionKey);

      request
          .then(async (result) => {
            if (result.status !== 200) {
              throw result;
            }

            this.global_success_text = 'Пароль успешно изменен! Перейдите на авторизацию.';
            this.passwordChanged = true;
          })
          .catch(async (result) => {
            const errorText = await result.text();
            const errorCode = result.status;

            this.global_error_text = 'Error: ' + errorCode + ' ' + errorText;
          })
          .finally(() => {
            this.$store.commit('set_full_screen_loading', false);
          });
    },
    resetRequest() {
      this.$store.commit('set_full_screen_loading', true);

      const request = resetPassRequest(this.login);

      request
          .then(async (result) => {
            if (result.status !== 200) {
              throw result;
            }

            this.global_success_text = 'Ссылка для сброса пароля выслана вам на почту';
          })
          .catch(async (result) => {
            const errorText = await result.text();
            const errorCode = result.status;

            this.global_error_text = 'Error: ' + errorCode + ' ' + errorText;
          })
          .finally(() => {
            this.$store.commit('set_full_screen_loading', false);
          });
    },
  },
});
</script>

<style lang="less">
.reset_pass_frame_header_text{
    max-width: 360px;
    width: 360px;
    min-width: 360px;
}
.reset-pass-frame {
  .flex(column, flex-start, center);
  position: absolute;
  width: 390px;
  border-radius: 15px;
  box-shadow: 0 10px 15px #505050;
  padding: 20px;

  &__header {
    .flex(row, center, flex-end);
    text-align: center;
    flex-basis: 0.4;
    vertical-align: middle;
    margin-bottom: 10px;
    font-size: 20px;
  }
  &__global-error {
    color: red;
    text-align: center;
  }
  &__global-success {
    color: green;
    text-align: center;
  }
  &__login-field {
    margin: 11px 0;
  }
  &__button1 {
    width: 75%;
    max-width: none;
    margin-top: 10px;
  }
  &__footer{
    .flex(row, center, center);
    flex-basis: 35px;
    flex-wrap: wrap;
    gap: 8px;
  }
}
</style>
