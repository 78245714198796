<template>
    <NonResizableOnOverflow>
    <div class="left-content">
      <linkTo v-if="this.getCanAddNavElem"
              :href="'/object_create_form/self__NavigationElementModelObject'"
              :newTab="false">
        <IconComponent size=20 :url="'/icons/plus.svg'" color="green"/>
      </linkTo>
      <StructNode :levelData="this.getTreeStruct" @select_changed="this.$emit('select_changed')"/>
      <div class="balance_top_panel_left_menu mobile-only">
         <LabelComponent label_text="ТЕКУЩИЙ БАЛАНС" label_type="caption/big/bold 16" class="top_panel_text"/>
         <LabelComponent :is_monospace="true" :label_text="getFormattedBalanceWithCurrency"/>
      </div>
      <div class="prising_for_lest_content"></div>
      <ButtonComponent  style="margin-left: 25px; width: 200px; color: red;"
                        label="OUR PRICES"
                        elementType="default"
                        @click="this.left_menu_price=true"
                        />
      <ButtonComponent  class="sign-out"
                        label="Sign out"
                        elementType="hover-bg"
                        iconUrl="/icons/sign-out.svg"
                        iconType="png"
                        iconPosition="right"
                        iconMargin="10px"
                        @click="this.logoutClick"
                        />
    <div v-if="left_menu_price" class="pricing-modal">
      <ModalView :open_level="1" min-width="350px" type_modal="internal" position="fixed" caption=" " @click_close="left_menu_price = false">
        <div class="hearder_pricing">
          <LabelComponent label_text="OUR PRICES" style="color: red;" label_type="headline/h3/medium 18"/>
        </div>
        <br/>
        <div v-for="price in filteredPrices" :key="price.id">
          <div class="pricing_line">
            <div class="cur_and_price">
              <LabelComponent :label_text="price.currency"/>
              <LabelComponent :label_text="formatPrice(price.value)"/>
            </div>
            <div>
              <LabelComponent :label_text="price.comments"/>
            </div>
          </div>
          <br/><br/>
        </div>
      </ModalView>
    </div>
      <!-- <DropDownInput      style="margin-left: 25px; width: 200px;"  :is_filterable="false"
                           :is_multi_select="false"
                           elementSize="caption/small/medium 10"
                           :dropdown_items_init="this.ddPricingForLeftMenu"
                           label_text="Prices"
                           :always_mobile_view="true"
                           /> -->
      <!-- <div class="add-left-panel-elements">
            <SwitcherComponent
                         class="left-panel-lang-switcher"
                         size="default"
                         :values="this.get_lang_switcher_values"
                         :init_selected_index="this.getUserLangId()"
                         @select_changed="this.switchLanguage"/>
        </div> -->
      <!-- <div class="left-content__footer"> -->
<!--
       <ButtonComponent class="sign-out"
                        label="Sign out"
                        elementType="hover-bg"
                        iconUrl="/icons/sign-out.svg"
                        iconType="png"
                        iconPosition="right"
                        iconMargin="10px"
                        @click="this.logoutClick"
                        /> -->
      <!-- </div> -->
    </div>
  </NonResizableOnOverflow>
</template>

<script type="text/javascript">
// import NonResizableOnOverflow from '@/layoutTools/NonResizableOnOverflow';
import IconComponent from '@/units/Icon';
import StructNode from './StructNode';
import linkTo from '@/units/linkTo.vue';
import ButtonComponent from '@/units/forms/Button';
import LabelComponent from '@/units/Label';
import getWalletInfo from '@/assets/dataSources/pages/getWalletInfo';
import NonResizableOnOverflow from '@/layoutTools/NonResizableOnOverflow';
import logoutUser from '@/assets/dataSources/auth/logoutUser';
import ModalView from '@/layoutTools/ModalView';
import getFormData from '@/assets/dataSources/getFormData';

import getRefList from '@/assets/dataSources/getRefList';
import langChangeMixin from '@/assets/mixins/langChangeMixin';


export default ({
  name: 'LeftMenu',
  components: {
    NonResizableOnOverflow,
    StructNode,
    IconComponent,
    ModalView,
    linkTo,
    LabelComponent,
    ButtonComponent,
    // SwitcherComponent,
  },
  data: () => ({
    left_menu_price: false,
    balance: 0,
    pricing: [
    // value: {label: '*Last Name (In English)', error: null, val: null, required: true},
    // commets: {label: '*Last Name (In English)', error: null, val: null, required: true},
    ],
    currencies: [],
  }),
  emits: ['select_changed'],
  mixins: [langChangeMixin],
  mounted() {
    this.getServerData();
  },
  computed: {
    get_user_info() {
      return this.$store.state.auth_settings.user_obj;
    },
    getFormattedBalanceWithCurrency() {
      // Определяем символ валюты на основе pay_region
      const currencySymbol = this.get_user_info.pay_region === 'ru' ? '₽' : 'CHF';
      return `${currencySymbol} ${this.formattedBalance}`;
    },
    formattedBalance() {
    // Форматируем баланс как число с двумя десятичными знаками и разделителем в виде запятой
      return this.balance.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    filteredPrices() {
    // Получаем регион оплаты пользователя
      const payRegion = this.get_user_info.pay_region;
      // Определяем, какую валюту использовать на основе региона оплаты
      const currency = payRegion === 'ru' ? 'RUB' : 'CHF';
      // Фильтруем цены по выбранной валюте
      return this.pricing.filter((price) => price.currency === currency);
    },
    ddCurrencies() {
      return this.currencies.map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    ddPricing() {
      return this.pricing.map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    getTreeStruct() {
      return this.$store.state.navigate_settings.web_site_tree_struct;
    },
    getCanAddNavElem() {
      return this.$store.getters.hasPermission('self__NavigationElementModelObject__add_object');
    },
  },
  methods: {
    formatPrice(value) {
      return Number(value).toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    getServerData() {
      getWalletInfo((newData) => {
        this.balance = newData.balance;
        this.transactions = newData.transactions.reverse();
      });

      getFormData((newList) => {
        this.pricing = newList;
      }, 'pricing_list');
    },
    logoutClick() {
      logoutUser(() => {
        location.href = '/';
      });
    },
  },
});
</script>

<style lang="less">
.hearder_pricing{
  display: flex;
    align-items: center;
    justify-content: center;
}
.cur_and_price{
  display: flex;
  gap: 5px;
}
.pricing_line{
  display: flex;
  gap: 5px;
    width: 330px;
    flex-direction: column;
    flex-wrap: wrap;
}
.prising_for_lest_content{
  margin-top: 50px;
}
.balance_left_menu{
    margin-left: 30px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.add-left-panel-elements {
  padding: 32px;
}
.left-panel-lang-switcher {
  margin-top: 32px;
}
.left-content {
  overflow: auto;
  height: 100%;
  width: 100%;
  /* ::-webkit-scrollbar {
    display: none;
  } */

  .change_nav{
    position: fixed;
    margin: 15px;
  }
  &__footer{
    display: flex;
    gap: 13px;
    position: absolute;
    bottom: 0;
    flex-direction: column;
  }
  .sign-out{
    margin-top: 100px;
    margin-bottom: 55px;
    margin-left: 25px;
  }
}
.balance_top_panel_left_menu{
    margin-top: 30px;
    margin-left: 30px;
}
.mobile-only {
  display: none;
}
@media screen and (max-width: @transition-threshold-1) {
  .prising_for_lest_content{
  margin-top: 13px;
  }
  .left-column[opened=false] {
    .change_nav {
      display: none;
    }
  }
  .mobile-only {
    display: block;
  }
}
</style>
