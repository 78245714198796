<template>
  <div>
    <div class="report-filters-form" :class="[`report-filters-form__${'column'}`]">
      <div v-for="(item, index) in this.getRawFields" :key="index" class="input-fields-line">
        <component :is="this.getFieldComponent(item)"
                   :rawField="item"
                   @input_change="(newData)=> this.inputChanged(item, newData)"
                   />
        <!-- <FormSeparator v-if="this.isHaveAfterSeparator(item, index) && 'column' !== 'row'"
                       class="form-painter_separator"/> -->
      </div>
    </div>
  </div>
</template>

<script>
import ReportDateRangeField from './fields/ReportDateRangeField';


const fieldComponents = {
  'date_range': ReportDateRangeField,
};

// const separatorBetweenTypes = [
//   ['label', 'label'],
//   ['label', 'input'],
// ];

export default ({
  name: 'ChangeObjectForm',

  emits: [
    'form_changed',
  ],

  components: {

  },

  props: {
    raw_fields: {
      required: true,
      type: Array,
    },
  },

  data: () => ({
    changedDataValue: {},
  }),

  computed: {
    getRawFields() {
      return this.raw_fields.filter((field) => field.system_name != '__uid__');
    },
  },

  methods: {
    getFieldComponent(item) {
      const ans = fieldComponents[item.type];

      if (ans == null) {
        console.warn('ReportForm Не удалось отрисовать поле ввода для типа:', item.type, ' Поле: ', item);
      }

      return ans;
    },
    inputChanged(rawField, newData) {
      const customMethods = this.getFieldComponent(rawField)['staticMethods'];

      let packedData = newData;

      if (customMethods != null && customMethods['getPackedInputData']) {
        packedData = customMethods['getPackedInputData'](newData, rawField);
      }

      this.changedDataValue[rawField.system_name] = packedData;

      this.$emit('form_changed', this.changedDataValue);
    },
  },
  beforeMount() {

  },
});
</script>

<style lang="less">
.report-filters-form {
  display: grid;
  grid-gap: 10px;
  width: 100%;

  &__row {
    grid-template-columns: repeat(auto-fit, minmax(calc(((@transition-threshold-0 * 0.98) - 70px - 10px) / 2), 1fr));
  }

  &__column {
    grid-template-columns: 100%;
  }

  &_separator {
    margin-top: 10px;
  }

  .actions-container {
    display: flex;
    gap: 10px;
  }

  .meta-actions-container {
    display: flex;
    gap: 10px;
  }
}

.form-painter-wrapper__group {
  margin: 10px 0;
}

.admin-page {
  &__modal-form {
    display: grid;
    row-gap: 10px;

    &__input {
      max-width: 500px;
    }
  }
}

.paginatedTable__container {
  &__boolean {
    &__true {
      .set_icon_color(@pink);
    }

    &__false {
      .set_icon_color(@error);
    }

    &__null {
      .set_icon_color(@light-gray);
    }
  }
}
</style>
