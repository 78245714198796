import {ROOT_URL, store} from '@/store';

const REQUEST_LINK = '/api/wallet/';

function getWalletInfo(handler) {
  store.commit('set_full_screen_loading', true);

  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'GET',
    credentials: 'include',
    // body: formData,
  }).then(async function(response) {
    const data = await response.json();
    handler(data);
  }).finally(() => store.commit('set_full_screen_loading', false));
}

export default getWalletInfo;
