<template>
  <InputField v-model="this.data_value"
              :label_text="this.getTitle"
              :type="'date'"
              :set_values="this.data_value"
              :input_field_status="this.rawField.readonly ? 'disabled' : undefined"
              @input_change="this.updateValue"
              />
</template>

<script type="text/javascript">
import InputField from '@/units/forms/InputField';

export default ({
  components: {
    InputField,
  },
  emits: [
    'input_change',
  ],
  data: () => ({
    data_value: '',
  }),
  mounted() {
    this.data_value = String(this.rawField['value'] == null ? '' : this.rawField['value']);
  },
  methods: {
    updateValue(newData) {
      this.data_value = newData;
      this.$emit('input_change', newData);
    },
  },
  computed: {
    getTitle() {
      const title = this.rawField.view || this.rawField.system_name;
      return title;
    },
  },
  watch: {
    rawField(newData) {
      if (newData) this.data_value = String(newData.value);
    },
    // set_values(newData) {
    //   // при получении новых данных через props меняем data_values
    //   this.data_value = newData;
    // },
    data_value(newData) {
      // сообщаем родителю, что данные изменены изнутри
      // this.$emit('input_change', newData);
      // this.$emit('update:modelValue', newData);
    },
  },
  props: {
    rawField: {
      required: true,
      type: Object,
    },
  },
});
</script>

<style lang="less">

</style>
