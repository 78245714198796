<template>
  <div>
    <div class="form-painter" :class="[`form-painter__${'column'}`]">
      <div class="meta-actions-container">
        <div v-for="(action, index) in this.getRawMetaActions" :key="index">
            <AdminButton    :isPositive="action.system_name != 'delete'"
                            :label="action.view"
                            @buttonClick="this.completeForm(action)"
            />
        </div>
      </div>
      <div v-for="(item, index) in this.getRawFields" :key="index">
        <component :is="this.getFieldComponent(item)"
                   :rawField="item"
                   @input_change="(newData)=> this.inputChanged(item, newData)"
                   />
        <!-- <FormSeparator v-if="this.isHaveAfterSeparator(item, index) && 'column' !== 'row'"
                       class="form-painter_separator"/> -->
      </div>
      <div class="actions-container">
        <div v-for="(action, index) in this.getRawActions" :key="index">
            <AdminButton    :isPositive="action.system_name != 'delete'"
                            :label="action.view"
                            @buttonClick="this.completeForm(action)"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import downloadBLOB from '@/assets/downloadBLOB';

import AdminFormBooleanField from './fields/AdminFormBooleanField';

import AdminFormTextField from './fields/AdminFormTextField';
import AdminFormNumberField from './fields/AdminFormNumberField';
import AdminFormDateField from './fields/AdminFormDateField';

import AdminFormLinkField from './fields/AdminFormLinkField';
import AdminFormMtoMField from './fields/AdminFormMtoMField';

import AdminQRCode from './fields/AdminQRCode';

import AdminButton from './fields/AdminButton';

const fieldComponents = {
  'boolean': AdminFormBooleanField,
  'text': AdminFormTextField,
  'number': AdminFormNumberField,
  'date': AdminFormDateField,
  'link': AdminFormLinkField,
  'table': AdminFormMtoMField,
  'qr': AdminQRCode,
};

// const separatorBetweenTypes = [
//   ['label', 'label'],
//   ['label', 'input'],
// ];

export default ({
  name: 'ChangeObjectForm',

  emits: [
    'completeFormAction',
  ],

  components: {
    AdminButton,
  },

  props: {
    raw_fields: {
      required: true,
      type: Array,
    },
    raw_actions: {
      required: true,
      type: Array,
    },
  },

  data: () => ({
    changedDataValue: {},
  }),

  computed: {
    getRawFields() {
      return this.raw_fields.filter((field) => field.system_name != '__uid__');
    },
    getRawActions() {
      return this.raw_actions.filter((item) => !item.is_meta_action);
    },
    getRawMetaActions() {
      return this.raw_actions.filter((item) => item.is_meta_action);
    },
  },

  methods: {
    getFieldComponent(item) {
      const ans = fieldComponents[item.type];

      if (ans == null) {
        console.warn('AdminForm Не удалось отрисовать поле ввода для типа:', item.type, ' Поле: ', item);
      }

      return ans;
    },
    inputChanged(rawField, newData) {
      const customMethods = this.getFieldComponent(rawField)['staticMethods'];

      let packedData = newData;

      if (customMethods != null && customMethods['getPackedInputData']) {
        packedData = customMethods['getPackedInputData'](newData, rawField);
      }

      this.changedDataValue[rawField.system_name] = packedData;
    },
    completeForm(rawAction) {
      // alert('form was complete! ' + JSON.stringify(this.changedDataValue) + ' ' + rawAction.system_name);

      if (rawAction.system_name == 'pay_invoice') {
        const allImages = Array.from(document.querySelectorAll('.qr-code img')).filter((img) => String(img.getAttribute('src')).startsWith('data:'));
        const allCanvases = Array.from(document.querySelectorAll('.qr-code canvas')).filter((img) => String(img.toDataURL()).startsWith('data:'));

        let downloadImage = '';

        if (allImages.length < 1) {
          downloadImage = allCanvases[0].toDataURL();
        } else {
          downloadImage = allImages[0].getAttribute('src');
        }

        if (window.WORK_IN_APPLICATION) {
          window.applicationCallback.savedFileCallback = (savedUri) => {
            if (savedUri != null && window.IntIsFileSelectMode === true) {
              window.sendMessageInApplication({
                type: 'select_media',
                selectedURIs: JSON.stringify([savedUri]),
              });
            }
          };
        }

        downloadBLOB(
            downloadImage,
            'pay_qr',
            window.IntIsFileSelectMode === true ? 'window.applicationCallback.savedFileCallback' : undefined,
        );
        return;
      }

      // if (window.IntIsFileSelectMode === true) {
      //   window.applicationCallback.savedFileCallback = (savedUri) => {
      //     if (savedUri != null) {
      //       window.sendMessageInApplication({
      //         type: 'select_media',
      //         selectedURIs: JSON.stringify([savedUri]),
      //       });
      //     } else {
      //     // downloadBLOB('/icons/toroid-green-glossy.png', 'testFile', 'window.applicationCallback.savedFileCallback');
      //     }
      //   };
      //   downloadBLOB('/icons/toroid-green-glossy.png', 'testFile', 'window.applicationCallback.savedFileCallback');
      // }

      const result = {
        ...this.changedDataValue,
      };

      result[rawAction.system_name] = rawAction.system_name;

      this.$emit('completeFormAction', result);
    },
  },
  beforeMount() {

  },
});
</script>

<style lang="less">
.form-painter {
  display: grid;
  grid-gap: 10px;
  width: 100%;

  &__row {
    grid-template-columns: repeat(auto-fit, minmax(calc(((@transition-threshold-0 * 0.98) - 70px - 10px) / 2), 1fr));
  }

  &__column {
    grid-template-columns: 100%;
  }

  &_separator {
    margin-top: 10px;
  }

  .actions-container {
    display: flex;
    gap: 10px;
  }

  .meta-actions-container {
    display: flex;
    gap: 10px;
  }
}

.form-painter-wrapper__group {
  margin: 10px 0;
}

.admin-page {
  &__modal-form {
    display: grid;
    row-gap: 10px;

    &__input {
      max-width: 500px;
    }
  }
}

.paginatedTable__container {
  &__boolean {
    &__true {
      .set_icon_color(@dark-blue);
    }

    &__false {
      .set_icon_color(@error);
    }

    &__null {
      .set_icon_color(@light-gray);
    }
  }
}
</style>
