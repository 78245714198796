<template>
  <div v-if="type === 'svg'" class="icon" @click="iconClick" :style="get_styles" :class="url || name ? 'icon-style-color' : 'default-icon'"></div>
  <img v-else :style="get_styles" :src="url" @click="iconClick">
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      required: false,
    },
    sizeUnit: {
      type: String,
      default: 'px',
      required: false,
    },
    sizeHeight: {
      type: String,
      default: '24',
    },
    sizeWidth: {
      type: String,
      default: '24',
    },
    color: {
      type: String,
      default: undefined,
    },
    url: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: 'svg',
      required: false,
    },
  },
  emits: ['icon_click'],
  computed: {
    get_styles() {
      const localUrl = this.url ? this.url : `/icons/${this.name}.${this.type}`;
      const url = localUrl;
      const styles = {
        'src': this.type === 'png' ? url : null,
        'mask': (this.url || (this.type && this.name)) ? `url(${url}) no-repeat center / contain` : null,
        '-webkit-mask': (this.url || (this.type && this.name)) ? `url(${url}) no-repeat center / contain` : null,
        'min-width': `${this.size || this.sizeWidth}${this.sizeUnit}`,
        'max-width': `${this.size || this.sizeWidth}${this.sizeUnit}`,
        'min-height': `${this.size || this.sizeHeight}${this.sizeUnit}`,
        'max-height': `${this.size || this.sizeHeight}${this.sizeUnit}`,
      };

      if (this.color) {
        if (!(this.url || this.name)) {
          styles['border-color'] = this.color;
        } else {
          styles['fill'] = this.color;
          styles['stroke'] = this.color;
          styles['background'] = this.color;
        }
      }

      return styles;
    },
  },
  methods: {
    iconClick() {
      this.$emit('icon_click');
    },
  },
};
</script>

<style lang="less">
@icon__border-color: @light-green;

.icon {
  transition: color 0.2s;
}

// .default-icon {
//   border: 1px solid @icon__border-color;
//   border-radius: 30%;
// }
</style>
