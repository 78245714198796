<template>
  <ModalView ref = "settings_modal_view_ref"
             :open_level = "1"
             type_modal = "internal"
             position = "fixed"
             :caption = "'Смена пароля'"
             @click_close = "this.$emit('closeChangePasswordModal')">
    <div class = "changePasswordModal__container">

      <div class = "changePasswordModal__container__group">
        <LabelComponent class = "changePasswordModal__container__header"
                        label_type = "button/large/medium 14"
                        label_text = "Старый пароль"/>
        <InputField class = "changePasswordModal__container__input"
                    type = "password"
                    label_text = "Старый пароль"/>
      </div>
      <div class = "changePasswordModal__container__group">
        <LabelComponent class = "changePasswordModal__container__header"
                        label_type = "button/large/medium 14"
                        label_text = "Новый пароль"/>
        <InputField class = "changePasswordModal__container__input"
                    type = "password"
                    label_text = "Новый пароль"/>
      </div>
      <ButtonComponent class = "changePasswordModal__container__button"
                       label = "Изменить"
                       elementType = "default-bg"/>
    </div>
  </ModalView>
</template>

<script>
// import builder from '../@/assets/v1/js/builder';
import ModalView from '@/layoutTools/ModalView';
import LabelComponent from '@/units/Label';
import ButtonComponent from '@/units/forms/Button';
import InputField from '@/units/forms/InputField';

export default /* builder */({
  name: 'ChangePasswordModal',
  emits: ['closeChangePasswordModal'],
  components: {
    ModalView,
    LabelComponent,
    ButtonComponent,
    InputField,
  },
});
</script>

<style lang = "less">
.changePasswordModal {
  &__container {
    min-width: 250px;
    width: 15vw;
    display: grid;
    grid-row-gap: 20px;

    &__group {
      display: grid;
      grid-row-gap: 10px;
    }
    &__input {
      width: 100%;
    }
  }
}
</style>
