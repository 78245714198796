<template>
  <BasePage :name="`${'Error'} 404. ${'This page was not found'}`">
    <div  class="page-not-found">
      <div class="page-not-found__caption">
        <div v-if="logoff" class="page-not-found__message">
          You are not authorized
        </div>
        <div class="page-not-found__message">
          Page {{ this.get_domain }}{{ this.path }} not found
        </div>
      </div>
      <div class="page-not-found__buttons">
        <ButtonComponent v-if="get_first_menus_item"
        :label="'Перейти на главную'"
        :href="get_first_menus_item.url"
        />
        <ButtonComponent v-if="logoff" :label="'Авторизация'" href="/login"/>
      </div>
    </div>
  </BasePage>
</template>

<script>
import BasePage from '@/baseLayout/BasePage';
import ButtonComponent from '@/units/forms/Button';

export default ({
  components: {BasePage, ButtonComponent},
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (this.get_first_menus_item) {
      this.$router.push(this.get_first_menus_item.url);
    }
  },
  computed: {
    logoff() {
      return !this.$store.getters.isLoggedIn;
    },
    get_first_menus_item() {
      return this.$store.getters.getSortedLayer(null)[0];
    },
    get_domain() {
      return window.location.host;
    },
  },
});
</script>

<style lang="less">
.page-not-found{
  .flex(column, unset, unset);
  padding: 10px;
  & > *{
    margin-bottom: 15px;
  }

  & > *:last-child{
    margin-bottom: 0;
  }
  &__caption{

  }
  &__buttons{
    .flex(row, unset, unset)
  }
}

</style>
