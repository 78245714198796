import {ROOT_URL, store} from '@/store';

const REQUEST_LINK = '/api/';

function getFormData(handler, method, silent=false) {
  if (!silent) store.commit('set_full_screen_loading', true);

  let addonUrl = REQUEST_LINK + method;

  if (method[0] == '/') {
    addonUrl = method;
  }

  fetch(ROOT_URL + addonUrl + '/', {
    method: 'GET',
    credentials: 'include',
    // body: formData,
  }).then(async function(response) {
    const data = await response.json();
    handler(data);
  }).finally(() => {
    if (!silent) store.commit('set_full_screen_loading', false);
  });
}

export default getFormData;
