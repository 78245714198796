<template>
  <BasePage name="Challengers">
    <div v-if="cards && cards.length > 0">
    <ViewedProfileList :cards="this.cards" @card_click="this.cardClick">
      <template v-slot="{card_data}">
        <div>{{ (card_data.sympathy1_date) }}</div>
          <!-- Условие для показа сообщения, если прошло более 7 дней -->
          <div class="waiting_text" v-if="daysSince(card_data.other_sympathy_date) > 7">
            <LabelComponent
              label_text="The Candidate is waiting for your feedback. Please find a moment to look through the Presentation and make a decision."
              label_type="body/large/regular 12" />
          </div>
          <div class="cancel_button">
            <ButtonComponent
                            label="ACCEPT SYMPATHY"
                            elementType="default-bg"
                            @click.stop="this.likeClick(card_data)"
                            />
          </div>
        <!-- <br/>
        <ButtonComponent
                          label="Reject sympathy"
                          elementType="default-bg"
                          @click.stop="this.hardDislikeClick(card_data)"
                          /> -->
      </template>
    </ViewedProfileList>
    </div>
    <div v-else class="text_if_no_card">
      <LabelComponent label_text="Достойные Претенденты, которым Вы понравились, появятся здесь в ближайшее время"/>
    </div>
    <div class="get-more-button-wraper">
      <ButtonComponent label="ORDER ANOTHER PACK" elementType="default-bg" @click="this.buyNewPackage"/>
    </div>
    <ModalView v-if="this.global_message_text != null" :open_level="1" type_modal="internal" position="fixed" caption=" " @click_close="this.global_message_text = null">
      <LabelComponent :label_text="this.global_message_text" label_type="button/large/medium 18" class="liked_text" />
        <br />
    </ModalView>
  </BasePage>
</template>

<script>
import BasePage from '@/baseLayout/BasePage';
import ButtonComponent from '@/units/forms/Button';
import LabelComponent from '@/units/Label';
import ViewedProfileList from './ViewedProfileList';
import ModalView from '@/layoutTools/ModalView';
import getFormData from '@/assets/dataSources/getFormData';
import sendFormData from '@/assets/dataSources/sendFormData';


export default ({
  components: {
    BasePage,
    ButtonComponent,
    ViewedProfileList,
    ModalView,
    LabelComponent,
  },
  data: ()=> ({
    global_message_text: null,
    user_message_text: null,
    cards: [
      {
        id: '1',
        image_url: '/icons/law.svg',
        user_id: '0000000',
      },
      {
        id: '2',
        image_url: '/icons/law.svg',
        user_id: '0000001',
      },
    ],
  }),
  props: {

  },
  mounted() {
    this.updateCards();
  },
  computed: {

  },
  methods: {
    formatDateTime(dateTimeStr) {
      // Функция для форматирования даты и времени
      return new Date(dateTimeStr).toLocaleString(); // Пример форматирования
    },
    daysSince(dateTimeStr) {
      const oneDay = 24 * 60 * 60 * 1000; // миллисекунд в одном дне
      const date = new Date(dateTimeStr);
      const now = new Date();
      return Math.round(Math.abs((date - now) / oneDay));
    },
    updateCards() {
      getFormData((servData) => {
        this.cards = servData;
      }, 'challengers');
    },
    cardClick(card) {
      window.location.href = '/presentation/' + card.id + '/';
    },
    likeClick(card) {
      const curForm = new FormData();
      curForm.append('viewed_profile_id', card.id);

      sendFormData(() => {
        this.user_message_text= 'Your Sympathy has been sent. Respectively, the Presentation of this Challenger has been relocated to your MUTUAL SYMPATHIES Section.';

        this.updateCards();
      }, 'challengers', curForm);
    },
    hardDislikeClick(card) {
      const curForm = new FormData();
      curForm.append('viewed_profile_id', card.id);
      curForm.append('hard_dislike', 'on');

      sendFormData(() => {
        this.updateCards();
      }, 'challengers', curForm);
    },
    buyNewPackage() {
      sendFormData((result) => {
        if (result.status == 200) {
          this.global_message_text = 'The new package has been successfully purchased. Please expect Presentations of new Candidates to appear.';
        } else if (result.status == 400) {
          this.global_message_text = 'There are not enough funds to purchase the package, please top up your Wallet.';
        } else if (result.status == 409) {
          this.global_message_text = 'A new Pack can be ordered after all the Presentations of already ordered Candidates have arrived. Thank you for your desire to receive more Candidates.';
        };
      }, 'buy_candidates_package');
    },
  },
});
</script>

<style lang="less">
.notif_text{
    display: flex;
    flex-wrap: wrap;
}
.get-more-button-wraper {
  .flex(row, center, center);
}
</style>
