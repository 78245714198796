<template>
  <router-view/>
  <LoadingPage v-if="this.$store.state.full_screen_loading > 0"/>
  <MessageWindow/>
  <ModalView v-if="this.breakLogIn"
             :open_level = "1"
             type_modal = "internal"
             position = "fixed"
             caption = "Сессия авторизации неактивна"
             @click_close="this.fullReload">
      <LabelComponent label_text = "For your security, we log you out automatically, because your account was inactive for more than 30 minutes. Please log in again."/>
  </ModalView>
</template>

<script>
import ModalView from '@/layoutTools/ModalView';
import LabelComponent from '@/units/Label';
import getUserInfo from '@/assets/dataSources/auth/getUserInfo';
import MessageWindow from './baseLayout/userMessages/MessageWindow';
import LoadingPage from './baseLayout/LoadingPage';
import checkAutoUpdate from '@/assets/cacheWork/autoUpdateCache';

export default {
  components: {
    LoadingPage,
    MessageWindow,
    ModalView,
    LabelComponent,
  },
  data: () => ({
    isMobileWall: 0,
    currentWidth: 0,
    breakLogIn: false,
  }),
  methods: {
    fullReload() {
      this.$store.commit('set_full_screen_loading', true);
      window.location.reload();
    },
  },
  beforeMount() {
    const appElem = document.getElementById('app');
    const appStyles = window.getComputedStyle(appElem, null);
    const mobileTransitionThreshold = appStyles.getPropertyValue('--transition-threshold-1').slice(0, -2);

    this.isMobileWall = parseInt(mobileTransitionThreshold);
    this.currentWidth = window.innerWidth;

    window.IS_MOBILE_VIEW = this.currentWidth <= this.isMobileWall;

    window.addEventListener('resize', () => {
      this.currentWidth = window.innerWidth;

      window.IS_MOBILE_VIEW = this.currentWidth <= this.isMobileWall;
    });
  },
  beforeCreate() {
    Date.dateUTC = (...args) => new Date(Date.UTC(...args));

    // eslint-disable-next-line no-extend-native
    Date.prototype.daysInMonth = function() {
      return 33 - Date.dateUTC(this.getUTCFullYear(), this.getUTCMonth(), 33).getUTCDate();
    };
    // eslint-disable-next-line no-extend-native
    Date.prototype.shiftDateFromCurrent = function(deltaYears, deltaMonths, deltaDays) {
      this.setUTCDate(this.getUTCDate() + deltaDays);
      this.setUTCMonth(this.getUTCMonth() + deltaMonths);
      this.setUTCFullYear(this.getUTCFullYear() + deltaYears);
      return this;
    };
    // eslint-disable-next-line no-extend-native
    Date.prototype.today=function() {
      // возвращает начало текущего года
      return Date.dateUTC(this.getUTCFullYear(), this.getUTCMonth(), this.getUTCDate());
    };
    // eslint-disable-next-line no-extend-native
    Date.prototype.beginCurrentWeek=function() {
      // возвращает начало текущего года
      return Date.dateUTC(this.getUTCFullYear(), this.getUTCMonth(), this.getUTCDate() - (this.getUTCDay() - 1));
    };
    // eslint-disable-next-line no-extend-native
    Date.prototype.beginCurrentMonth=function() {
      // возвращает начало текущего месяца
      return Date.dateUTC(this.getUTCFullYear(), this.getUTCMonth(), 1);
    };
    // eslint-disable-next-line no-extend-native
    Date.prototype.beginCurrentYear=function() {
      // возвращает начало текущего года
      return Date.dateUTC(this.getUTCFullYear(), 0, 1);
    };
    // // eslint-disable-next-line no-extend-native
    // Date.prototype.beginCurrentQuarter=function() {
    //   // возвращает начало текущего квартала
    //   return Date.dateUTC(this.getUTCFullYear(), this.getUTCMonth()-(this.getUTCMonth() % 3), 1);
    // };
    // // eslint-disable-next-line no-extend-native
    // Date.prototype.pastYear=function() {
    //   // возвращает начало текущего года
    //   return Date.dateUTC(this.getUTCFullYear()-1, 0, 1);
    // };
    // // eslint-disable-next-line no-extend-native
    // Date.prototype.prePastYear=function() {
    //   // возвращает начало текущего года
    //   return Date.dateUTC(this.getUTCFullYear()-2, 0, 1);
    // };

    // eslint-disable-next-line no-extend-native
    Number.prototype.truncated_n = function(precision) {
    // отсекает до точности в precision знаков
      if (precision == null) console.error(`${this} truncated_n to ${precision}`);
      return (precision == null) ? Math.trunc(this * (10 ** precision)) / (10 ** precision) : this;
    };

    // eslint-disable-next-line no-extend-native
    String.comparePaths=function(pathOne, pathTwo) {
      pathOne = String(pathOne);
      pathTwo = String(pathTwo);

      pathOne = pathOne[pathOne.length-1] == '/' ? pathOne : pathOne + '/';
      pathTwo = pathTwo[pathTwo.length-1] == '/' ? pathTwo : pathTwo + '/';

      return pathOne == pathTwo;
    };
  },
  created() {
    // window.__is_online__ = true;
    // window.addEventListener('offline', () => {
    //   console.log('I am offline.');
    //   window.__is_online__ = false;
    // });

    // window.addEventListener('online', () => {
    //   console.log('I am back online.');
    //   window.__is_online__ = true;
    // });

    window.escapeableElements = [];
    window.acceptableElements = [];
    document.addEventListener('keydown', (e) => {
      // Keycode for "Escape"
      if (e.keyCode === 27) {
        if (window.escapeableElements.length > 0) {
          window.escapeableElements[window.escapeableElements.length-1]();
        }
      }
      // Keycode for "Return"
      if (e.keyCode == 13) {
        if (window.acceptableElements.length > 0) {
          window.acceptableElements[0]();
        }
      }
    });

    window.onerror = function() {
      const args = Array.prototype.slice.call(arguments);
      window.globalEvents.callEvent('new_message', [{
        messageType: 'error',
        messageData: {
          name: 'Error',
          message: args.toString(),
        },
      }]);
    };

    checkAutoUpdate();

    // Ping methods
    setInterval(() => {
      if (this.$store.getters.isLoggedIn && !this.breakLogIn) {
        getUserInfo((data) => {
          const isLoggedIn = data != null && !data.is_anonymous;

          if (!isLoggedIn) {
            this.breakLogIn = true;
          }
        }, !this.$store.state.is_active);
        this.$store.state.is_active = false;
      }
    }, 10000);

    [
      'mousedown', 'mousemove', 'keydown',
      'scroll', 'touchstart',
    ].forEach((eventName) => {
      document.addEventListener(eventName, () => {
        this.$store.state.is_active = true;
      }, true);
    });
  },
};
</script>

<style lang="less">
@import url('./assets/less/components.less');

#app {
  --transition-threshold-0: @transition-threshold-0;
  --transition-threshold-1: @transition-threshold-1;
  --transition-threshold-2: @transition-threshold-2;
  --transition-threshold-3: @transition-threshold-3;

  height: 100%;
}

</style>
