<template>
  <component :is="this.component_type"
             class="label"
             :class="this.classes"
             :style="{width: this.is_html ? '100%' : null, height: this.is_html ? '100%' : null}">
    <div class="label-inner">
      <iframe style="width: 100%; height: 100%; border: 0;" v-if="this.is_html" :srcdoc="this.label_text_current">
      </iframe>
      <!-- <div style="isolation: isolate;" v-if="this.is_html" v-html="this.label_text_current"></div> -->
      <div v-else-if="this.is_inline_markup" v-html="this.label_text_current"></div>
      <div v-else>{{ this.label_text_current }}</div>
    </div>

  </component>
</template>

<script type="text/javascript">
/*
 * Поле вывода строки текста.
 * По запросу меняет свою высоту и ширину.
 * При сборке данных формы отдаёт свое значение, если разрешено сервером.
 * Настраивается на сервере
 */

export default ({
  name: 'LabelTemplate',
  data: () => ({
    component_type: 'p',
    label_text_current: '',
    classes: {},
  }),
  props: {
    label_type: {
      default: 'body/large/regular 14',
      type: String,
    },
    label_component_type: {
      default: 'p',
      type: String,
    },
    label_text: {
      default: 'Текст по умолчанию',
    },
    label_color: {
      type: String,
      required: false,
      validator: (prop) => prop.startsWith('status-color-'),
    },
    white_space: {
      type: String,
      default: 'normal',
      validator: (prop) => ['normal', 'nowrap', 'pre', 'pre-line', 'pre-wrap'].includes(prop),
    },
    digits_precision: {
      type: Number,
      required: false,
    },
    is_monospace: {
      type: Boolean,
      default: false,
    },
    is_html: {
      type: Boolean,
      default: false,
    },
    is_inline_markup: {
      type: Boolean,
      default: false,
    },
    auto_translate: {
      type: Boolean,
      default: true,
    },
  },
  beforeMount() {
    this.label_text_current = this.get_label(this.label_text);
    this.classes = this.get_classes();
  },
  watch: {
    label_text: function() {
      this.label_text_current = this.get_label(this.label_text);
      this.classes = this.get_classes();
    },
    label_color: function() {
      this.classes = this.get_classes();
    },
    label_type: function() {
      this.classes = this.get_classes();
    },
    langs: function() {
      this.label_text_current = this.get_label(this.label_text);
      this.classes = this.get_classes();
    },
  },
  computed: {
    langs() {
      return this.$store.getters.getTranslations;
    },
  },
  methods: {
    get_classes() {
      this.component_type = this.label_component_type;
      const classes = {};
      const labelType = this.label_type;
      if (this.label_color) {
        classes[`${this.label_color}__background`] = true;
        classes['label_colored'] = true;
      }

      if (this.is_monospace) {
        classes['label_monospace'] = true;
      }

      if (['0', '0.00 %'].includes(this.get_label(this.label_text))) {
        classes['label-zero'] = true;
      }
      if (this.white_space !== 'normal') classes[`white-space_${this.white_space}`] = true;

      switch (labelType) {
        case 'absolute_big': this.component_type = 'h1'; classes['abs-big'] = true; break;
        case 'h1':
        case 'headline/h1/bold 28': this.component_type = 'h1'; classes['headline-h1-bold-28'] = true; break;
        case 'h2':
        case 'headline/h2/bold 22': this.component_type = 'h2'; classes['headline-h2-bold-22'] = true; break;
        case 'h3':
        case 'headline/h3/medium 18': this.component_type = 'h3'; classes['headline-h3-medium-18'] = true; break;
        case 'h4':
        case 'headline/h4/medium 12': this.component_type = 'h4'; classes['headline-h4-medium-12'] = true; break;
        case 'second_big': classes['sec-big'] = true; break;

        case 'button/large/medium 18': classes['button-large-medium-18'] = true; break;
        case 'button/large/regular 16': classes['button-large-regular-16'] = true; break;
        case 'button/large/regular 25': classes['button-large-regular-25'] = true; break;
        case 'button/large/medium 14': classes['button-large-medium-14'] = true; break;
        case 'button/large/regular 14': classes['button-large-regular-14'] = true; break;
        case 'button/small/medium 12': classes['button-small-medium-12'] = true; break;
        case 'button/small/regular 12': classes['button-small-regular-12'] = true; break;

        case 'body/large/regular 16': classes['body-large-regular-16'] = true; break;
        case 'body/large/regular/italic 16': classes['body-large-regular-16'] = true; break;
        case 'body/large/regular 18': classes['body-large-regular-18'] = true; break;
        case 'body/large/regular 14': classes['body-large-regular-14'] = true; break;
        case 'body/large/regular 12': classes['body-large-regular-12'] = true; break;

        case 'caption/big/bold 20': classes['caption-big-bold-20'] = true; break;
        case 'caption/big/bold 16': classes['caption-big-bold-16'] = true; break;
        case 'caption/small/medium 10': classes['caption-small-medium-10'] = true; break;
        case 'caption/small/medium 12': classes['caption-small-medium-12'] = true; break;
        case 'caption/small/medium 14': classes['caption-small-medium-14'] = true; break;
        case 'caption/small/regular 10': classes['caption-small-regular-10'] = true; break;
        case 'caption/small/regular 8': classes['caption-small-regular-8'] = true; break;
      }

      return classes;
    },
    get_label(label) {
      if (label === null) return '';

      if (typeof label === 'number') {
        return label.toLocaleString(
            'ru-RU',
            {useGrouping: true,
              minimumFractionDigits: this.digits_precision,
              maximumFractionDigits: this.digits_precision},
        ).replace(/,/g, '.');
      }

      if (!this.auto_translate) {
        return label;
      }

      const val = this.langs[label];
      return val == null ? label : val;
    },
  },
});
</script>

<style lang="less">
// Стили для отдельных элементов

h1, h2, h3, h4, p {
  font-family: @base-font;
  font-style: normal, italic;
  //color: @dark;
}

.label{
  transition: font-weight 200ms, font-size 200ms, line-height 200ms;
}

.abs-big { font-weight: bold; font-size: 80px; line-height: 70px; }
.sec-big { font-weight: bold; font-size: 30px; line-height: 70px; }
.headline-h1-bold-28 { font-weight: bold; font-size: 28px; line-height: 33px; }
.headline-h2-bold-22 { font-weight: bold; font-size: 22px; line-height: 25px; }
.headline-h3-medium-18 { font-weight: 500;  font-size: 18px; line-height: 21px; }
.headline-h4-medium-12 { font-weight: 500;  font-size: 12px; line-height: 14px; }

.button-large-medium-18  {font-weight: 500;     font-size: 18px; line-height: 21px; }
.button-large-regular-16 {font-weight: normal;  font-size: 16px; line-height: 19px; }
.button-large-regular-25 {font-weight: normal;  font-size: 25px; line-height: 19px; }
.button-large-medium-14  {font-weight: 500;     font-size: 14px; line-height: 17px; }
.button-large-regular-14 {font-weight: normal;  font-size: 14px; line-height: 17px; }
.button-small-medium-12  {font-weight: 500;     font-size: 16px; line-height: 14px; }
.button-small-regular-12 {font-weight: normal;  font-size: 12px; line-height: 14px; }

.body-large-regular-16  {font-weight: normal; font-size: 16px; line-height: 19px; }
.body-large-regular-italic-16  { font-weight: normal; font-size: 16px; line-height: 19px; font-style: italic; }
.body-large-regular-18  {font-weight: normal; font-size: 18px; line-height: 19px; }
.body-large-regular-14  {font-weight: normal; font-size: 16px; line-height: 17px; }
.body-large-regular-12  {font-weight: normal; font-size: 12px; line-height: 14px; }

.caption-big-bold-20      {font-weight: bold;    font-size: 20px; line-height: 24px; text-transform: capitalize;}
.caption-big-bold-16      {font-weight: bold;    font-size: 16px; line-height: 19px; text-transform: capitalize;}
.caption-small-medium-10  {font-weight: 500;     font-size: 10px; line-height: 12px; }
.caption-small-medium-12  {font-weight: normal;     font-size: 12px; line-height: 12px; }
.caption-small-medium-14  {font-weight: normal;     font-size: 14px; line-height: 12px; }
.caption-small-regular-10 {font-weight: normal;  font-size: 10px; line-height: 12px; }
.caption-small-regular-8 {font-weight: normal;  font-size: 8px; line-height: 12px; }

@media screen and (max-width: @transition-threshold-3) {
  .abs-big { font-size: 55px; line-height: 50px; }
  .caption-big-bold-16      {font-weight: bold;    font-size: 14px; line-height: 19px; text-transform: capitalize;}
  // .headline-h1-bold-28 { font-weight: bold; font-size: 28px; line-height: 33px; }
  // .headline-h2-bold-22 { font-weight: bold; font-size: 22px; line-height: 25px; }
  // .headline-h3-medium-18 { font-weight: 500;  font-size: 18px; line-height: 21px; }
  // .headline-h4-medium-12 { font-weight: 500;  font-size: 12px; line-height: 14px; }

  // .button-large-medium-18  {font-weight: 500;     font-size: 18px; line-height: 21px; }
  // .button-large-regular-16 {font-weight: normal;  font-size: 16px; line-height: 19px; }
  // .button-large-medium-14  {font-weight: 500;     font-size: 14px; line-height: 17px; }
  // .button-large-regular-14 {font-weight: normal;  font-size: 14px; line-height: 17px; }
  // .button-small-medium-12  {font-weight: 500;     font-size: 12px; line-height: 14px; }
  // .button-small-regular-12 {font-weight: normal;  font-size: 12px; line-height: 14px; }

  // .body-large-regular-16  {font-weight: normal; font-size: 16px; line-height: 19px; }
  // .body-large-regular-14  {font-weight: normal; font-size: 14px; line-height: 17px; }
  // .body-large-regular-12  {font-weight: normal; font-size: 12px; line-height: 14px; }

  // .caption-big-bold-20      {font-weight: bold;    font-size: 20px; line-height: 24px; text-transform: capitalize;}
  // .caption-big-bold-16      {font-weight: bold;    font-size: 16px; line-height: 19px; text-transform: capitalize;}
  // .caption-small-medium-10  {font-weight: 500;     font-size: 10px; line-height: 12px; }
  // .caption-small-regular-10 {font-weight: normal;  font-size: 10px; line-height: 12px; }
  // .caption-small-regular-8 {font-weight: normal;  font-size: 8px; line-height: 12px; }
}
@media screen and (max-width: @transition-threshold-2) {
  .abs-big { font-size: 43px; line-height: 48px; }
}

.label{
  background-color: transparent;
  &_colored{
    border-radius: 3px;
    padding: 2px 5px;
  }
  &_monospace * {
    font-family: Rubik-mono, Rubik, Rubik-italic, serif;
  }
  &-inner {
    font-family: inherit;
    white-space: pre-wrap;
    font-style: italic;
  }
}
.white-space{
  &_nowrap{
    white-space: nowrap;
  }
  &_pre{
    white-space: pre;
  }
  &_pre-line{
    white-space: pre-line;
  }
  &_pre-wrap{
    white-space: pre-wrap;
  }
}

.table-data__main-cell{
  .label-zero{
    color: @dark;
  }
}

</style>
