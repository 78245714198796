<template>
  <div class="auth-page">
    <div class="left-panel">
      <div class="left-panel__text">
        <LabelComponent class="left-panel__text-main" label_text="INTERNATIONAL" label_type="absolute_big" :auto_translate="false"/>
        <LabelComponent class="left-panel__text-colored" label_text="MARRIAGE" label_type="absolute_big" :auto_translate="false"/>
        <LabelComponent class="left-panel__text-main" label_text="AGENCY" label_type="absolute_big" :auto_translate="false"/>
      </div>
    </div>
    <div class="right-panel">
      <!-- <div class="right-panel__header">
        <SwitcherComponent
                         size="default"
                         :values="this.get_lang_switcher_values"
                         :init_selected_index="this.getUserLangId()"
                         @select_changed="this.switchLanguage"/>

      </div> -->
      <div class="right-panel__content">
          <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import LabelComponent from '@/units/Label';
// import SwitcherComponent from '@/units/forms/Switcher';

import langChangeMixin from '@/assets/mixins/langChangeMixin';

export default ({
  components: {
    LabelComponent,
    // SwitcherComponent,
  },
  mixins: [langChangeMixin],
  props: {

  },
  data: ()=> ({

  }),
  mounted() {

  },
  unmounted() {

  },
  computed: {

  },
  methods: {

  },
});
</script>

<style lang="less">
.auth-page{
  .flex(row, flex-start, stretch);
  width: 100vw;
  height: 100%;

  .left-panel{
    .flex(column, flex-start, flex-start);
    background: @pink ;
    position: relative;
    flex-basis: 650px; // здесь регулируем ширину левой зеленой панели

    .main-logo {
      position: absolute;
      margin: @margin-vertical-default @margin-horizontal-default;
    }
  }

  .right-panel{
    .flex(column, space-between, stretch);
    flex-grow: 1;
    flex-basis: 890px;

    &__header{
      .flex(column, flex-end, flex-end);
      margin-top: 15px;
      padding-right: 15px;
      &__languages {
        margin: @margin-vertical-default @margin-horizontal-default;
      }
    }
    &__content{
      .flex(column, center, center);
      flex-grow: 1;
      position: relative;

      &__panel {
        .flex(column, flex-start, center);
        position: absolute;
        width: 390px;
        //border: 1px solid #000000;
        border-radius: 15px;
        box-shadow: 0 10px 15px #505050;
        padding: 20px;
      }
    }

    &__footer{
      .flex(row, center, center);
      flex-basis: 35px;
      flex-wrap: wrap;
      gap: 8px;
      //padding: 10px 0;
      //margin-bottom: 40px;

    }
  }
}


@media (max-width: @transition-threshold-1) {
  .auth-page {
    .left-panel {
      display: none;
    }

    .right-panel {
      &__content {
        &__panel {
          width: calc(100% - 2 * @margin-horizontal-800);
        }
      }
    }
  }
}

.left-panel__text {
  position: absolute;
  top: 40%;

  &-main {
    color: @dark;
  }
  &-colored {
    color: @error;
  }
}
</style>
