import {ROOT_URL, store} from '@/store';

const REQUEST_LINK = '/data/add_object/';

function createObjectRequest(objectTypeName, objectForm, handler) {
  store.commit('set_full_screen_loading', true);

  const formData = new FormData();
  Object.keys(objectForm).forEach((key) => formData.append(key, objectForm[key]));

  fetch(ROOT_URL + REQUEST_LINK + objectTypeName, {
    method: 'POST',
    credentials: 'include',
    body: formData,
  }).then(async function(response) {
    // const objectTypeData = await response.json();
    store.commit('set_full_screen_loading', false);
    handler(response);
  }).catch((errData) => {
    store.commit('set_full_screen_loading', false);
  });
}

export default createObjectRequest;
