<template>
    <BasePage :name="'My profile'">
      <div class="page_name_userprof">
                <div class='security_icon'>
          <IconComponent :url="securityLink" type="png" style="min-width: 144px;max-width: 24px;min-height: 34px;max-height: 24px;"/>
        </div>
      </div>
  <div class="profile_wrapper">
        <LabelComponent style="color: red;" v-if="this.global_error_text != null" :label_text="this.global_error_text" />
              <div class="first_block">
                  <LabelComponent label_text="YOUR ACCOUNT INFORMATION"
                              class="profile_label"
                              label_type="h2"/>
              </div>
              <!--Первый блок с фото-->
              <div class="vertical_blocks">
                <div class="profile_text">
                    <div class="profile_avatar">
                      <img :src="form_data.media_photo1.val || '/icons/person-circle-svgrepo-com.svg'" alt="User Avatar" />

                    </div>
                    <div class="user_profile_info">
                      <LabelComponent label_text="User ID"
                                    class="profile_label"
                                    label_type="h3"/>
                      <LabelComponent :label_text="String(this.form_data['unique_code'].val)" />
                    </div>

                    <div class="user_profile_info">
                      <LabelComponent label_text="Account"
                                  class="profile_label"
                                  label_type="h3" />
                      <LabelComponent :label_text="String(this.form_data['email'].val)" />
                    </div>

                    <div class="user_profile_info">
                      <LabelComponent label_text="Status"
                                  class="profile_label"
                                  label_type="h3" />
                      <LabelComponent :label_text="this.form_data['verified'].val ? 'Verified' : 'Unverified'" />
                    </div>
                    <div class="button_user" >
                      <ButtonComponent
                              label_type="button/large/medium 18"
                              elementSize="big"
                              elementType = "default-bg"
                              @click="this.changePasswordModal = true"
                              label="CHANGE PASSWORD"
                              />
                    </div>
                    <div class = "request_to_close_acc">
              <ButtonComponent
                                  label_type="button/large/medium 18"
                                  elementSize="default"
                                  elementType = "default"
                                  @click="this.close_acc=true"
                                  label="Request to close Personal Account"/>
              <ModalView v-if="this.close_acc" class="close_acc_modal" :open_level="1" min-width="350px" type_modal="internal" position="fixed" caption=" " @click_close="this.close_acc = false">
                <LabelComponent  class="close_acc_modal" label_type = "button/large/medium 14" label_text="Вы уверены, что хотите закрыть свой Личный Кабинет?"/>
                <br />
                <LabelComponent  class="close_acc_modal" label_type = "button/large/medium 14" label_text="Это действие необратимо, и Вы потеряете доступ ко всем своим данным и функциональности."/>
                <br />
                <LabelComponent  class="close_acc_modal" label_type = "button/large/medium 14" label_text="Пожалуйста, примите во внимание следующее:"/>
                <LabelComponent  class="close_acc_modal" label_type = "button/large/regular 14" label_text="Вся Ваша личная информация, включая профиль, сообщения и настройки, будут удалены."/>
                <br />
                <LabelComponent  class="close_acc_modal" label_type = "button/large/regular 14" label_text="Вы больше не сможете входить в систему под текущим аккаунтом."/>
                <br />
                <LabelComponent  class="close_acc_modal" label_type = "button/large/regular 14" label_text="Если у Вас есть какие-либо вопросы или сомнения, рекомендуем связаться с нашей службой поддержки перед принятием окончательного решения."/>
                <br />
                <ButtonComponent  class="close_acc_modal"
                                  label_type="button/large/regular 25"
                                  elementSize="big"
                                  elementType = "default"
                                  @click="this.closeAccount"
                                  label="Close Personal Account"/>
                  <br /><br />
              </ModalView>
        </div>
                </div>

                 <!--Блок со сменой имени -->
          <Separator separatorType="vertical" class="user_info__separator"/>
          <Separator  v-show="this.get_is_mobile_info" separatorType="horisontal" class = "user_info__separator"/>

        <div class="info_block_userpfor">
              <div class="form_field_userprof">
                <LabelComponent   :label_text="this.form_data['name'].label"/>
                <InputField       label_text="For our Agency only"
                                  :set_values="this.form_data['name'].val"
                                  :input_field_status="this.getFieldStatus('name')"
                                  :error_text="this.form_data['name'].error"
                                  @input_change="(data) => this.setFormValue('name', data)"
                                  />
              </div>

              <div class="form_field_userprof">
                <LabelComponent   :label_text="this.form_data['surname'].label"/>
                <InputField       label_text="For our Agency only"
                                  :set_values="this.form_data['surname'].val"
                                  :input_field_status="this.getFieldStatus('surname')"
                                  :error_text="this.form_data['surname'].error"
                                  @input_change="(data) => this.setFormValue('surname', data)"
                                  />
              </div>

              <div class="form_field_userprof">
                <LabelComponent label_text="Email Address" />
                <InputField
                                  :label_text="this.form_data['email'].label"
                                  :set_values="this.form_data['email'].val"
                                  :input_field_status="this.getFieldStatus('email')"
                                  :error_text="this.form_data['email'].error"
                                  @input_change="(data) => this.setFormValue('email', data)"
                                  type= "email"
                                  />
              </div>

              <div class="form_field_userprof_text">
                <LabelComponent style="color: #777b75ff; margin-top: 25px;"
                                label_text="Attention. You can change the email address for receiving our messages. But the user name for entering your Personal Account will remain the same." />
              </div>
              <div class = "save">
                  <ButtonComponent
                      label_type="button/large/medium 18"
                      elementSize="big"
                      elementType = "default-bg"
                      @click="this.sendProfileToServer('Profile saved! Thank you for updating.')"
                      label="SAVE CHANGES"/>
              </div>
              <div class="form_field_userprof_doc">
                <LabelComponent label_text="Here You can see the latest version of" style="line-height: 1.5" />
                <ButtonComponent
                                 :label="contractLabel"
                                  elementType="default"
                                  :href="contractLink"
                                :href_in_new_tab="true"
                                 style="line-height: 1.5"/>
            </div>
              <br/>
          </div>

                <Separator separatorType = "vertical" class = "user_info__separator"/>
                <Separator  v-show="this.get_is_mobile_info" separatorType = "horisontal" class = "user_info__separator"/>
                <div v-if="!this.form_data.__presentation_payed__.val && !this.form_data['verified'].val" class="verification">
                                <div class="other_text">
                      <LabelComponent
                                label_text="После заполнения Анкеты"
                                class="profile_label"
                                label_type="button/large/regular 14" />
                                <LabelComponent
                                label_text="здесь появится процедура идентификации."
                                class="profile_label"
                                label_type="button/large/regular 14" />
                                <LabelComponent
                                label_text="Желаем удачи!"
                                class="profile_label"
                                label_type="button/large/regular 14" />
                      </div>
                </div>
                    <div v-if="this.form_data.__presentation_payed__.val && this.form_data['verified'].val" class="verification">
                      <div class="other_text">
                      <LabelComponent
                                label_text="Поздравляем,"
                                class="profile_label"
                                label_type="button/large/regular 14" />
                                <LabelComponent
                                label_text="Ваша идентификация прошла успешно."
                                class="profile_label"
                                label_type="button/large/regular 14" />
                                <LabelComponent
                                label_text="Желаем удачи!"
                                class="profile_label"
                                label_type="button/large/regular 14" />
                      </div>
                    </div>
                            <!--Блок верификации-->
              <div v-if="this.form_data.__presentation_payed__.val && !this.form_data['verified'].val" class="verification">
                <div class="verification">
                    <div class="caption_user_photo">
                      <LabelComponent
                                label_text="Before entering your Presentation into our database, we would ask you identify your personality."
                                class="profile_label"
                                label_type="button/large/regular 14" />
                    </div>

                    <div class="caption_user_photo">
                      <LabelComponent
                                label_text="Please provide us a photo of your valid passport and a selfy of yourselves made at this very moment."
                                class="profile_label"
                                label_type="button/large/regular 14" />
                    </div>

                    <br/>
                    <div class="info_block">
                      <div class="form_field_userprof_photo">
                        <LabelComponent :label_text="this.form_data['passport_img'].label"/>
                        <InputField  label_text=" "
                                    style="min-height: 100px;"
                                    :set_values="this.form_data['passport_img'].val"
                                    :input_field_status="this.getFieldStatus('passport_img')"
                                    :error_text="this.form_data['passport_img'].error" @input_change="(data) => this.setFormValue('passport_img', data)" accept_files_type="images" type="file" />
                      </div>
                      <div class="form_field_userprof_photo">
                        <LabelComponent :label_text="this.form_data['self_img'].label" />
                        <InputField label_text=" "
                                    :set_values="this.form_data['self_img'].val"
                                    :input_field_status="this.getFieldStatus('self_img')"
                                    :error_text="this.form_data['self_img'].error" @input_change="(data) => this.setFormValue('self_img', data)" accept_files_type="images" type="file" />
                      </div>
                    </div>
                    <div class = "save">
                        <ButtonComponent
                          label_type="button/large/medium 18"
                          elementSize="big"
                          elementType = "default-bg"
                          @click="this.sendProfileToServer('Thank You for Your photos. You will receive notification of identification status within 24 hours.')"
                          label="IDENTIFY ME"/>
                    </div>
                      <!-- <ModalView v-if="this.passport_photo_text != null" :open_level="1" type_modal="internal" position="fixed" caption=" " @click_close="this.passport_photo_text = null">
                          <LabelComponent :label_text="this.passport_photo_text" label_type="button/large/medium 18" class="saved_text" />
                          <br /><br />
                      </ModalView> -->
                </div>
              </div>
      </div>
        <!-- Закрытие профиля -->

                <ChangePasswordModal v-if="this.changePasswordModal" @closeChangePasswordModal="this.changePasswordModal = false"/>
                <br />
                <ModalView v-if="this.global_success_text != null" :open_level="1" type_modal="internal" position="fixed" caption=" " @click_close="this.global_success_text = null">
                <LabelComponent :label_text="this.global_success_text" label_type="button/large/medium 18" class="saved_text" />
                <br /><br />
                </ModalView>

  </div>
                <!-- <ChatComponent v-show="this.get_is_mobile_info"/> -->
                <ChatComponent/>

    </BasePage>
  </template>

<script>
import BasePage from '@/baseLayout/BasePage';
import ChangePasswordModal from './ChangePasswordModal';
import isMobileMixin from '@/assets/mixins/isMobileMixin';
import ModalView from '@/layoutTools/ModalView';
import LabelComponent from '@/units/Label';
import Separator from '@/units/forms/Separator';
import ButtonComponent from '@/units/forms/Button';
import IconComponent from '@/units/Icon';
import InputField from '@/units/forms/InputField';

import ChatComponent from '@/plugins/businessChat/layout/ChatRoot';

import sendFormData from '@/assets/dataSources/sendFormData';
import getFormData from '@/assets/dataSources/getFormData';

export default ({
  components: {
    BasePage,
    Separator,
    LabelComponent,
    IconComponent,
    InputField,
    ButtonComponent,
    ChangePasswordModal,
    ModalView,
    // ButtonComponent,
    ChatComponent,
  },
  props: {
    // path: {
    //   type: String,
    //   required: true,
    // },
  },
  data: ()=> ({
    close_acc: false,
    changePasswordModal: false,
    chatModal: false,
    global_error_text: undefined,
    global_success_text: undefined,
    form_data: {
      media_photo1: {label: 'Select first photo', error: null, val: null, required: false},
      surname: {label: 'Last name', error: null, val: null, required: true},
      name: {label: 'First name', error: null, val: null, required: true},
      email: {label: 'Email', error: null, val: null, itype: 'email', required: false},
      unique_code: {label: 'UserID', error: null, val: null, required: false},
      verified: {label: 'Status', error: null, val: null, required: false},
      passport_img: {label: 'Select passport photo', error: null, val: null, required: false},
      self_img: {label: 'Select selfie', error: null, val: null, required: false},
      __presentation_payed__: {label: 'Select selfie ', error: null, val: null, required: false},
    },
  }),
  mounted() {
    this.updateRefs();
  },
  computed: {
    baseLang() {
      // Get the current language from the Vuex store
      return this.$store.state.base_lang;
    },
    securityLink() {
      // Return the corresponding link based on the language
      return this.baseLang === 'en' ? '/icons/lock en.png' : '/icons/lock 1.png';
    },
    contractLabel() {
      // Return the corresponding label based on the language
      return this.baseLang === 'en' ? 'PUBLIC CONTRACT OFFER' : 'ПУБЛИЧНОГО ДОГОВОРA-ОФЕРТЫ';
    },
    contractLink() {
      // Return the corresponding link based on the language
      return this.baseLang === 'en' ? '/documents/ima-public-contract-en.pdf' : '/documents/ima-public-contract-ru.pdf';
    },
  },
  mixins: [isMobileMixin],
  methods: {
    updateRefs() {
      getFormData((servData) => {
        Object.keys(servData).forEach((extKey) => {
          if (this.form_data[extKey] == null) return;

          if (this.form_data[extKey].itype == 'inline') {
            this.form_data[extKey].val = [];

            servData[extKey].forEach((data) => {
              const newElem = {};

              Object.keys(this.form_data[extKey].content_form).forEach((field) => {
                const newField = {};
                const curField = this.form_data[extKey].content_form[field];
                Object.keys(curField).forEach((attr) => newField[attr] = curField[attr]);
                newElem[field] = newField;
                if (data[field] != null && newElem[field].itype == 'dropdown' && !Array.isArray(data[field])) {
                  data[field] = [data[field]];
                }
                newElem[field].val = data[field];
              });

              this.form_data[extKey].val.push(newElem);
            });

            return;
          }

          if (servData[extKey] != null && this.form_data[extKey].itype == 'dropdown' && !Array.isArray(servData[extKey])) {
            servData[extKey] = [servData[extKey]];
          }

          this.form_data[extKey].val = servData[extKey];
        });
      }, 'profile');
    },
    getFieldStatus(field) {
      return this.form_data[field].error != null ? 'error' : undefined;
    },
    setFormValue(field, value) {
      this.global_error_text = null;
      this.form_data[field].val = value;
      this.form_data[field].error = null;
    },
    sendProfileToServer(successMessageText) {
      let isError = false;
      const fillCorrectText = 'Please, revise the fields marked by red!';

      Object.keys(this.form_data).forEach((key) => {
        if (this.form_data[key].required == true && (!this.form_data[key].val || this.form_data[key].val.length < 1)) {
          this.form_data[key].error = 'Поле должно быть заполнено';
          isError = true;
        }
      });

      if (isError) {
        // this.global_error_text = fillCorrectText;

        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: {
            name: 'Error',
            message: fillCorrectText,
          },
        }]);
        return;
      }

      console.log('confirmed', this.form_data);

      const postForm = new FormData();
      Object.keys(this.form_data).forEach((key) => {
        if (this.form_data[key].val == null) {
          return;
        }

        if (this.form_data[key].itype == 'inline') {
          postForm.append(key + '-TOTAL_FORMS', this.form_data[key].val.length);
          postForm.append(key + '-INITIAL_FORMS', 0);
          this.form_data[key].val.forEach((elem, idx) => {
            Object.keys(elem).forEach((inlineKey) => {
              postForm.append(key + '-' + idx + '-' + inlineKey, elem[inlineKey].val);
            });
          });
          return;
        }
        if (Array.isArray(this.form_data[key].val)) {
          this.form_data[key].val.forEach((elem) => postForm.append(key, elem));
          return;
        }

        if (this.form_data[key].val instanceof Date) {
          postForm.append(key, JSON.stringify(this.form_data[key].val).split('T')[0].substring(1));
          return;
        }

        if (this.form_data[key].val === true) {
          postForm.append(key, 'on');
          return;
        }

        if (this.form_data[key].val === false) {
          return;
        }

        postForm.append(key, this.form_data[key].val);
      });

      sendFormData(async (response) => {
        this.updateRefs();

        if (response.status == 200) {
          this.global_success_text = successMessageText;
          return;
        }
        if (response.status == 400) {
          this.global_error_text = fillCorrectText;
          const errorPack = await response.json();
          Object.keys(errorPack).forEach((erField) => {
            this.form_data[erField].error = errorPack[erField].join(' ');
          });
          window.globalEvents.callEvent('new_message', [{
            messageType: 'error',
            messageData: {
              name: 'Error',
              message: fillCorrectText,
            },
          }]);
          return;
        }

        this.global_error_text = 'Bad server response! ' + response.status;

        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: {
            name: 'Error',
            message: 'Bad server response! ' + response.status,
          },
        }]);
      }, 'profile', postForm);
    },
  },
});
</script>


<style lang="less">
.other_text{
  display: flex;
    text-align: center;
    margin-top: 20px;
    gap:5px;
    flex-direction: column;
}
.request_to_close_acc{
  margin-left: 10px;
  margin-top: 45px;
}
.user_profile_info{
  display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}
.chat_userprof{
  position: absolute;
  bottom: 0;
  left: 15px;
}
.form_field_userprof_doc{
  display: flex;
  margin-top: 10px;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.form_field_userprof_text{
width: 95%;
align-items: center;
margin-top: -20px;
margin-bottom: 15px;
}
.form_field_userprof_photo{
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 5px;
}
.profile_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vertical_blocks {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.profile_text,
.info_block_userpfor,
.verification {
  flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    margin-top: 20px;
    align-items: center;
    justify-content: flex-start;
}

.user_info__separator {
  align-self: stretch;
  width: 2px;
  margin-top: 20px;
  background-color: #ccc;
}

.profile_avatar img {
  height: 150px;
  margin-top: 20px;
  border-radius: 20%;
  object-fit: cover;
}

.first_block,
.caption_user_photo,
.caption_user_photo br {
  text-align: center;
}

.security_icon {
  display: flex;
  justify-content: flex-start;
}

.profile_label {
  font-weight: bold;
}

.form_field_userprof {
  margin: 10px 0;
  width: 90%;
}

.save {
  margin-top: 10px;
  margin-bottom: 20px;
}

.button_user {
  margin-top: 20px;
}

@media screen and (max-width: @transition-threshold-1) {
  .vertical_blocks {
    flex-direction: column;
    align-items: center;
  }

  .user_info__separator {
    width: 102%;
    height: 2px;
    margin: 10px 0;
  }

  .profile_text,
  .info_block_userpfor,
  .verification {
    margin: 20px 0;
  }

}
</style>
