<template>
  <routerLink v-if="this.isPartLink && !this.newTab" :to="{path: this.getHrefForRouter}">
    <slot>{{'Место для контента'}}</slot>
  </routerLink>
  <a v-else :href="this.href" :target="this.newTab ? '_blank' : null">
    <slot>{{'Место для контента'}}</slot>
  </a>
</template>

<script>
// import builder from '@/assets/v1/js/builder';

export default /* builder */({
  props: {
    href: {default: '/'},
    newTab: {default: false},
  },
  computed: {
    isPartLink() {
      return this.href && this.href.startsWith('/');
    },
    getHrefForRouter() {
      if (!this.isPartLink) {
        return '/';
      }
      return this.href;
    },
  },
});
</script>

<style lang="less">

</style>
