<template>
  <BasePage :name="'Выбор ' + (this.workObjectTypeInfo ? this.workObjectTypeInfo.view : '-')" :onlyContent="this.isModal">
    <div class="object-list-view">
        <!-- <div v-if="Object.keys(this.$route.query).length > 0">
            Обнаружены GET Параметры! {{ this.$route.query }} <br><br>
        </div> -->

        <div style="display: flex;">
          <ButtonComponent
            v-if="this.getCanAddElem"
            :label="'Добавить ' + (this.workObjectTypeInfo ? this.workObjectTypeInfo.view : '-')"
            :href="'/object_create_form/' + this.workObjectName"
          />
        </div>

        <ButtonComponent
          v-for="(draw_item, index) in this.drawObjects"
          :key="index"
          :elementType="'default'"
          :label="draw_item.view"
          @click_to_button="this.selectObject(draw_item)"
          :href="this.isModal ? undefined : `/object_edit_form/${this.workObjectName}/${draw_item.uid}`"
          class="select-object-button"
        />

        <!-- <ChangeObjectForm v-if="this.workObjectType != null"
                 :raw_fields="this.workObjectType.form.fields"
                 :raw_actions="this.workObjectType.form.actions"
                 @completeFormAction="this.completedForm"
        /> -->
    </div>
  </BasePage>
</template>

<script>
import BasePage from '@/baseLayout/BasePage';
import ButtonComponent from '@/units/forms/Button';
import getObjectTypeInfo from '@/assets/dataSources/admin/getObjectTypeInfo';
import getObjectList from '@/assets/dataSources/admin/getObjectList';

export default ({
  name: 'SelectObjectList',
  components: {
    BasePage,
    ButtonComponent,
  },
  data: () => ({
    drawObjects: null,
    workObjectTypeInfo: null,
  }),
  props: {

  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        return;
      }

      this.rebuildForm();
    },
  },
  computed: {
    isModal() {
      return this.$route.query['modal_handler_id'] != null;
    },
    modalHandlerId() {
      return this.$route.query['modal_handler_id'];
    },
    isManySelect() {
      return this.$route.query['select_many'] == '1';
    },
    workObjectName() {
      return this.$route.params.objectName ? this.$route.params.objectName : '-';
    },
    getCanAddElem() {
      return this.$store.getters.hasPermission(this.workObjectName + '__add_object');
    },
  },
  methods: {
    selectObject(object) {
      if (!this.isModal) {
        return;
      }

      if (this.isModal && !this.isManySelect) {
        const handler = window.opener.getModalHandler(this.modalHandlerId);
        handler([object]);
        window.close();
      }
    },
    rebuildForm() {
      const curThis = this;

      getObjectTypeInfo(curThis.workObjectName, function(objectTypeInfo) {
        curThis.workObjectTypeInfo = objectTypeInfo;
      });

      getObjectList(curThis.workObjectName, function(drawObjects) {
        curThis.drawObjects = drawObjects;
      });
    },
  },
  mounted() {
    this.rebuildForm();
  },
});
</script>

<style lang="less">
.object-list-view {
    margin: 14px;
}
</style>
