<template>
  <div v-if="this.isChatOpen" class="resize-handle" @mousedown="this.startResize">
    <div class="chat-container" :style="{ height: this.chatContainerHeight + 'px' }">
      <div class="chat-frame">
        <div class="chat-header">
          <div class="close-cont">
            <img :src="avatarSrc" alt="avatar" class="avatar" />
            <LabelComponent label_type="headline/h2/bold 22" label_text="MARRIAGE CHAT" style="margin-left:-23px; color: #ffffffff; text-align: center; margin-top:5px; margin-bottom: 5px;" />
            <ButtonComponent elementType="hover" @click="this.toggleChat" iconType="png" style="margin-top: 5px; margin-bottom: 5px;" iconUrl="/icons/close 1.png" iconSize="22" />
          </div>
          <div class="chat-info">
            <LabelComponent label_type="button/large/regular 14" label_text="Questions?" style="margin-top: 9px;" />
            <LabelComponent label_type="button/large/regular 14" label_text="No problem, we can help." />
            <LabelComponent label_type="button/large/regular 14" label_text="Just start a chat with one of our advisors." />
          </div>
        </div>
        <div class="messages" ref="chatContainer">
          <div v-for="(item, index) in message_list" :key="index">
            <div v-if="item.type === 'date'" class="date-separator">{{ formatedDate(item.date) }}</div>
            <div v-else class="message" :class="item.is_self ? 'message-self' : 'message-other'">
              <div class="message-text">
                {{ item.text }}
                <div class="message-time">{{ formatTime(item.time) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="input-block">
          <textarea v-model="user_text" :placeholder="typetext" rows="1" @input="adjustHeight" ref="messageInput" ></textarea>
          <ButtonComponent label=" " iconUrl="/icons/send.png" iconType="png" iconPosition="left" iconSize="30" elementSize="default" @click="sendMessage" />
        </div>
      </div>
    </div>
  </div>
  <div v-show="!this.get_is_mobile_info" class="user_profile_chat">
    <ButtonComponent  label="CHAT WITH US" elementSize="big" iconUrl="/icons/chat.png" iconType="png" iconPosition="left" iconSize="30" iconMargin="5px" @click="toggleChat" />
  </div>
  <div   v-show="this.get_is_mobile_info"  class="user_profile_chat_mobile">
    <ButtonComponent  label="CHAT WITH US" elementSize="big" iconUrl="/icons/chat.png" iconType="png" iconPosition="left" iconSize="30" iconMargin="5px" @click="toggleChat" />
  </div>
</template>


<script>
import getFormData from '@/assets/dataSources/getFormData';
import sendFormData from '@/assets/dataSources/sendFormData';
import isMobileMixin from '@/assets/mixins/isMobileMixin';
import ModalView from '@/layoutTools/ModalView';
import ButtonComponent from '@/units/forms/Button';
import LabelComponent from '@/units/Label';

export default {
  components: {
    ButtonComponent,
    LabelComponent,
  },
  name: 'ChatRoot',
  data: () => ({
    close_acc: false,
    chatContainerHeight: 500,
    message_list: [],
    user_text: '',
    isChatOpen: false,
  }),
  watch: {
    get_is_mobile_info() {
      if (!this.get_is_mobile_info) {
        this.isChatOpen = true;
      } else {
        this.isChatOpen = false;
      }
    },
  },
  mixins: [isMobileMixin],
  computed: {
    baseLang() {
      return this.$store.state.base_lang;
    },
    avatarSrc() {
      return this.$store.state.base_lang === 'en' ? '/icons/phone 1.png' : '/icons/phone 1.png';
    },
    typetext() {
      return this.baseLang === 'en' ? 'Type a message...' : 'Введите сообщение...';
    },
  },
  mounted() {
    this.updateMessages(); // Загружаем сообщения при открытии страницы

    // Дожидаемся загрузки и отображения сообщений
    // this.$nextTick(() => {
    //   this.scrollChatToBottom(); // Вызываем функцию для прокрутки чата вниз
    // });
    // setTimeout(() => {
    //   this.scrollChatToBottom();
    // }, 1000);
    this.intervalID = setInterval(() => {
      this.updateMessages(); // Обновляем сообщения каждые 2 секунды
    }, 2000);
  },

  updated() {
    // this.scrollChatToBottom(); // Вызываем функцию для прокрутки чата вниз после обновления компонента
  },
  unmounted() {
    clearInterval(this.intervalID);
  },
  methods: {
    closeAccount() {
      if (this.sentClosed) {
        return;
      }

      this.sentClosed = true;

      sendFormData(async (response) => {
        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: {
            name: 'Error',
            message: 'Account is closed!',
          },
        }]);
        window.location.reload();
      }, 'disable_account');
    },
    scrollChatToBottom() {
      const chatContainer = this.$refs.chatContainer;
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    },
    formatedDate(date) {
      if (!date) {
        return '-';
      }
      date=new Date(date);
      const dateText = date.toLocaleDateString('ru-RU', {timeZone: 'Europe/Zurich'});
      // datetime
      return dateText;
    },
    formatTime(date) {
      if (!date) {
        return '-';
      }
      date = new Date(date);
      const timeText = date.toLocaleTimeString('en-EN', {timeZone: 'Europe/Zurich', hour: '2-digit', minute: '2-digit'});
      return timeText;
    },
    startResize(e) {
      this.startY = e.clientY;
      this.startHeight = parseInt(this.chatContainerHeight, 10);
      document.addEventListener('mousemove', this.resize, false);
      document.addEventListener('mouseup', this.stopResize, false);
    },
    resize(e) {
      let newHeight = this.startHeight + (this.startY - e.clientY);
      if (newHeight > 460) newHeight = 460; // максимальная высота
      if (newHeight < 200) newHeight = 200; // минимальная высота
      this.chatContainerHeight = newHeight;
    },
    stopResize() {
      document.removeEventListener('mousemove', this.resize, false);
      document.removeEventListener('mouseup', this.stopResize, false);
    },
    updateMessages() {
      getFormData((servData) => {
        const groupedMessages = [];
        let currentDate = null;

        servData.forEach((message) => {
          const messageDate = new Date(message.time).toLocaleDateString('ru-RU', {timeZone: 'Europe/Zurich'});

          // Добавляем разделитель дат, если текущая дата отличается от предыдущей
          if (messageDate !== currentDate) {
            groupedMessages.push({type: 'date', date: message.time}); // Используем message.time, чтобы сохранить исходную дату для сортировки
            currentDate = messageDate;
          }

          // Добавляем сообщение
          groupedMessages.push({
            type: 'message',
            time: message.time,
            text: message.text,
            is_self: message.is_self,
            id: message.id,
          });
        });
        const oldLen = this.message_list.length;

        this.message_list = groupedMessages;
        if (oldLen != this.message_list.length) {
          setTimeout(() => {
            this.scrollChatToBottom();
          }, 1);
        }
      }, '/chat/get_messages', true);
    },
    sendMessage() {
      if (this.user_text.length < 1) {
        return;
      }
      const formData = new FormData();
      formData.append('message', this.user_text);
      sendFormData((servAns) => {
        if (servAns.status === 200) {
          this.user_text = '';
          this.updateMessages();
        }
      }, '/chat/new_message', formData);
    },
    toggleChat() {
      this.isChatOpen = !this.isChatOpen;
    },
    adjustHeight() {
      const textarea = this.$refs.messageInput;
      textarea.style.height = '40px'; // Установить минимальную высоту
      textarea.style.height = `${Math.min(textarea.scrollHeight, 80)}px`; // Установить высоту в зависимости от содержимого, но не выше 80px
    },
  },
};
</script>

<style lang="less">
.user_profile_chat_mobile {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.user_profile_chat {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 10px;
  margin-right: 10px;
}

.resize-handle {
  width: 370px;
  cursor: ns-resize;
  z-index: 200;
  position: fixed;
  bottom: 0;
  padding: 10px;
  right: 0;
  margin-right: 13px;
  margin-bottom: 7px;
}

.chat-container {
  width: 100%;
  max-height: 460px; // Максимальная высота контейнера чата
  border: 1px solid #ccc;
  background: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .chat-frame {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .close-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ff9595;
  }

  .chat-header {
    display: flex;
    padding: 15px;
    flex-direction: column;
    background: #f7f7f7;
    border-bottom: 1px solid #ddd;
    border-radius: 8px 8px 0 0;

    .avatar {
      width: 40px;
      height: 40px;
      padding: 5px;
      margin-right: 15px;
    }

    .chat-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .messages {
    overflow-y: auto;
    padding: 15px;
    flex: 1;
    background: #f9f9f9;
  }

  .date-separator {
    padding: 10px 15px;
    text-align: center;
    color: #666;
    font-size: 12px;
    background-color: #f0f0f0;
    margin: 10px 0;
    border-radius: 8px;
    font-weight: bold;
  }

  .message-time {
    font-size: 10px;
    color: #888;
    margin-bottom: 2px;
    text-align: right;
    display: block; /* Убедитесь, что время всегда будет на новой строке над текстом сообщения */
  }

  .message {
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    justify-content: flex-end;

    &.message-self {
      .message-text {
        align-self: flex-end;
        text-align: left;
        margin-top: 5px;
        background: #e0f7fa;
        color: #00796b;
        white-space: pre-wrap;
      }
    }

    &.message-other {
      justify-content: flex-start;

      .message-text {
        align-self: flex-start;
        text-align: left;
        margin-top: 5px;
        background: #eeeeee;
        color: #333;
        white-space: pre-wrap;
      }
    }

    .message-text {
      max-width: 85%;
      padding: 10px;
      border-radius: 15px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      word-wrap: break-word;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }

  .input-block {
    padding: 10px;
    display: flex;
    border-top: 1px solid #ddd;
    flex-direction: row;
    align-items: flex-end;

    textarea {
      flex: 1;
      padding: 5px;
      margin-right: 10px;
      border: 1px solid #ddd;
      border-radius: 15px;
      min-height: 40px;
      max-height: 80px;
      word-wrap: break-word;
      outline: none;
      word-break: break-word;
      overflow: hidden;
      resize: none; /* Отключить возможность изменения размера вручную */
    }
  }
}

@media screen and (max-width: @transition-threshold-1) {
  .resize-handle {
    width: 85%;
  }
}
</style>

