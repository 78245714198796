import {ROOT_URL, store} from '@/store';

const REQUEST_LINK = '/api/';

function sendFormData(handler, method, formData) {
  store.commit('set_full_screen_loading', true);
  let addonUrl = REQUEST_LINK + method;

  if (method[0] == '/') {
    addonUrl = method;
  }

  fetch(ROOT_URL + addonUrl + '/', {
    method: 'POST',
    credentials: 'include',
    body: formData,
  }).then(function(response) {
    handler(response);
  }).finally(() => store.commit('set_full_screen_loading', false));
}

export default sendFormData;
