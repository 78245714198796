<template>
  <div class="qr-code"></div>
</template>

<script type="text/javascript">

export default ({
  components: {
  },
  emits: [
    'input_change',
  ],
  data: () => ({
    data_value: '',
  }),
  mounted() {
    this.data_value = this.rawField['value'] == null ? '' : this.rawField['value'];
  },
  methods: {

  },
  computed: {
    getTitle() {
      const title = this.rawField.view || this.rawField.system_name;
      return title;
    },
  },
  watch: {
    rawField(newData) {
      if (newData) this.rawField['value'] == null ? '' : this.rawField['value'];
    },
    data_value(newData) {
      QrCreator.render({
        text: newData,
        radius: 0.0, // 0.0 to 0.5
        ecLevel: 'H', // L, M, Q, H
        fill: '#000000', // foreground color
        background: '#ffffff', // color or null for transparent
        size: 350, // in pixels
      }, document.querySelector('.qr-code'));

      const borderWidth = 100;

      const cv = document.querySelector('.qr-code canvas');

      const oldCanvas = cv.toDataURL();

      cv.width = cv.width + borderWidth * 2;
      cv.height = cv.height + borderWidth * 2;

      const context = cv.getContext('2d');
      context.lineWidth = borderWidth * 2;
      context.strokeStyle='#FFFFFF';
      context.strokeRect(0, 0, cv.width, cv.height);

      const img = new Image();
      img.onload = function() {
        context.drawImage(img, borderWidth, borderWidth);
      };
      img.src = oldCanvas;
    },
  },
  props: {
    rawField: {
      required: true,
      type: Object,
    },
  },
});
</script>

<style lang="less">

</style>
