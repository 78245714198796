import {ROOT_URL, store} from '@/store';

const REQUEST_LINK = '/data/get_report_info/';

function getReportInfo(reportName, handler) {
  store.commit('set_full_screen_loading', true);
  fetch(ROOT_URL + REQUEST_LINK + reportName, {
    method: 'GET',
    credentials: 'include',
    // body: JSON.stringify({params: {}}),
  }).then(async function(response) {
    const reportData = await response.json();
    store.commit('set_full_screen_loading', false);
    handler(reportData);
  }).catch((errData) => {
    store.commit('set_full_screen_loading', false);
  });
}

export default getReportInfo;
