<template>
  <BasePage :name="'My Wallet'">
    <div class="wallet_div">
    <div class="wallet-header">
      <LabelComponent class="balance-label" label_text="История транзакций"/>
    </div>

    <div class="wallet-transactions">
      <div class="balance_wallet">
      <LabelComponent class="transactions-label" label_text="Текущий баланс:"/>
      <div class="balance-value">
        <LabelComponent :is_monospace="true" :label_text="getFormattedBalanceWithCurrency"/>
      </div>
    </div>
    <div class="table-container">
      <table class="transactions-table">
        <thead>
          <tr>
            <th>
              <LabelComponent label_text="Описание"/>
            </th>
            <th>
              <LabelComponent v-if="this.$store.state.auth_settings.user_obj.pay_region === 'ru'" label_text="Сумма, RUB"/>
              <LabelComponent v-if="this.$store.state.auth_settings.user_obj.pay_region !== 'ru'" label_text="Сумма, CHF"/>
            </th>
            <th >
              <LabelComponent label_text="Дата"/>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="transaction in this.transactions" :key="transaction">
            <td>
              <LabelComponent :label_text="transaction.description"/>
            </td>
            <td >
              <div class="one_line_wallet">
              <span class="amount-sign">{{ transaction.type.view == 'ADD' ? '+' : '-' }}</span>
              <span class="amount-value">{{ formatTransactionValue(transaction.value) }}</span>
              </div>
            </td>
            <td>
              <span class="amount-date">{{ formatedDate(transaction.date) }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
    <div class="bottom_text_wallet">
      <div class="add_money_text">
            <LabelComponent label_text="Here you can fill in your Wallet for future transactions."
                      label_type="headline/h3/medium 18"/>
      </div>
    <ButtonComponent  label="ПОПОЛНИТЬ" @click="this.add_money=true"
                      elementSize="default"/>
    </div>
    <ModalView v-if="this.add_money"
               :open_level="1"
               type_modal="internal"
               position="fixed"
               caption="Пополнение кошелька"
               @click_close="this.add_money = false">
      <InputField label_text="Сумма пополнения" @input_change="(data) => this.add_money_val = data" type="number" :set_values="this.add_money_val"/>
        <br/>
      <ButtonComponent label="ПОПОЛНИТЬ" @click="this.makeMoneyAdd" />
    </ModalView>
    </div>
  </BasePage>
</template>

<script>
import BasePage from '@/baseLayout/BasePage';
import LabelComponent from '@/units/Label';
import ModalView from '@/layoutTools/ModalView';
import ButtonComponent from '@/units/forms/Button';
import InputField from '@/units/forms/InputField';

import getWalletInfo from '@/assets/dataSources/pages/getWalletInfo';
import getPayLink from '@/assets/dataSources/getPayLink';

export default ({
  components: {
    BasePage,
    ButtonComponent,
    ModalView,
    LabelComponent,
    InputField,
  },
  data: ()=> ({
    balance: 0,
    add_money: false,
    add_money_val: 10,
    transactions: [],
  }),
  props: {

  },
  mounted() {
    this.updateWalletData();
  },
  computed: {
    get_user_info() {
      return this.$store.state.auth_settings.user_obj;
    },
    getFormattedBalanceWithCurrency() {
      const currencySymbol = this.get_user_info.pay_region === 'ru' ? '₽' : 'CHF';
      return `${currencySymbol} ${this.formattedBalance}`;
    },
    formattedBalance() {
    // Форматируем баланс как число с двумя десятичными знаками и разделителем в виде запятой
      return this.balance.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
  methods: {
    formatedDate(curDate) {
      curDate = new Date(curDate);
      const dateText = curDate.toLocaleDateString('ru-RU', {timeZone: 'Europe/Zurich'});
      const timeText = curDate.toLocaleTimeString('en-EN', {timeZone: 'Europe/Zurich', hour: '2-digit', minute: '2-digit'});
      return dateText + ' ' + '(' + timeText + ')';
    },
    formatTransactionValue(value) {
      return value.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    updateWalletData() {
      getWalletInfo((newData) => {
        this.balance = newData.balance;
        this.transactions = newData.transactions.reverse();
      });
    },
    // openInNewTab(href) {
    //   // Create link in memory
    //   const a = window.document.createElement('a');
    //   document.body.appendChild(a);
    //   a.target = '_blank';
    //   a.href = href;
    //   a.click();
    //   document.body.removeChild(a);
    // },
    makeMoneyAdd() {
      getPayLink((link) => {
        this.$store.commit('set_full_screen_loading', true);
        // Используйте новый метод для открытия ссылки в новой вкладке
        // this.openInNewTab(link);
        window.location.href = link;
        this.$store.commit('set_full_screen_loading', false);
      }, this.add_money_val);
    },
  },
});
</script>

<style lang="less">
.wallet-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 13px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;

  .balance-label, .balance-value {
    font-size: 1.2em;
    font-weight: bold;
  }
}
.balance_wallet{
    display: flex;
    /* padding: 15px; */
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    align-content: stretch;
    justify-content: center;
}
.wallet-transactions {
  .transactions-label {
    font-size: 1.1em;
    padding: 15px;
    margin-bottom: 10px;
  }

  .transactions-table {
    width: 75%;
    border-collapse: collapse;
    /* padding: 8px; */
    margin-left: 15px;

    th, td {
      border: 1px solid #ddd;
      padding: 8px;
    }

    th {
      background-color: #f2f2f2;
    }
  }
}
.table-container{
  .flex(row, center, center);
}
.one_line_wallet{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.transaction-amount{
  display: flex;
    justify-content: flex-end;
}
.amount-sign {
  margin-right: 5px;
}
.amount-date{
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.amount-value {
  margin-right: 2px;
  display: flex;
}
.add_money_text{
  text-align: center;
}
.bottom_text_wallet{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
    /* max-width: 300px; */
    /* min-width: 300px; */
    /* padding: 17px; */
    gap: 13px;
}
.wallet_div{
  padding:15px;
}
.add-money-button {
  .flex(row, center, center);
    padding: 15px;
    gap: 15px;
}

</style>
