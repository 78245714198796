<template>
  <div id="base_app">
    <div class="left-column"
         v-if="!this.onlyContent"
         :opened="this.leftColumnOpened">

      <div class="left-column__background"
           @click="this.close_left_column()"/>

        <MainLogo class="logo"
                  branch=""
                  colorStyle="black-green"
                  width="190"
                  @click_to_logo="this.toLogoClick"/>

        <LeftMenu @select_changed="this.close_left_column()"/>
      </div>
    <div class="right-column"
         ref="frameClickMouseRightColumn">
        <div class="top-panel" v-if="!this.onlyContent">
          <TopPanel @left-menu="this.open_left_column" :name="this.name"/>
        </div>

      <div class="content-panel">
        <BaseContentWrapper
            :name="this.name"
            :is_with_background="this.is_with_background"
            :fixedMobileHeader="this.fixedMobileHeader"
            >
          <slot>
            <div v-if="logoff" style="margin: auto; padding-top:32px; width: fit-content;">
              <LabelComponent label_text="Для доступа нужно авторизоваться"/>
              <br/><br/>
              <ButtonComponent :label="'Авторизация'" href="/login"/>
            </div>
            <div v-else style="margin: 15px;">
             <!-- <LabelComponent :label_text=" this.form_data['name'].val + " /> -->
            </div>
          </slot>
        </BaseContentWrapper>
      </div>
          <div class="bottom-panel" v-if="!this.onlyContent">
            <BottomPanel/>
          </div>

      <slot name="modal" />
    </div>
  </div>
</template>

<script type="text/javascript">
// import builder from '@/assets/v1/js/builder';
import TopPanel from './topPanel/TopPanel';
import LabelComponent from '@/units/Label';
import LeftMenu from './navigationPanel/LeftMenu';
import BottomPanel from './bottomPanel/BottomPanel';
// import ContentWrapper from '@/components/navigation/ContentWrapper';
import BaseContentWrapper from '@/layoutTools/BaseContentWrapper';
import MainLogo from '@/units/logo/MainLogo';

import ButtonComponent from '@/units/forms/Button';

export default /* builder */({
  name: 'BasePage',
  components: {
    BaseContentWrapper,
    LeftMenu,
    MainLogo,
    LabelComponent,
    TopPanel,
    BottomPanel,
    ButtonComponent,
  },
  data: ()=>({
    leftColumnOpened: false,
    scroll_position_freeze: undefined,
  }),
  mounted() {

  },
  computed: {
    logoff() {
      // console.log('isLoggedIn', this.$store.getters.isLoggedIn);
      // console.log('isLoggedIn', this.$store.state.auth_settings.user_obj);
      return !this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    toLogoClick() {
      this.$router.push('/');
    },
    open_left_column() {
      this.leftColumnOpened = true;
      this.fix_html();
    },
    close_left_column() {
      this.leftColumnOpened = false;
      this.fix_html(false);
    },
    fix_html(fix = true) {
      // const elem = document.getElementsByTagName('html')[0];
      // if (fix) {
      //   this.scroll_position_freeze = window.scrollY;
      //   elem.style.top = `-${this.scroll_position_freeze}px`;
      //   // elem.style.position = 'fixed';
      // } else {
      //   elem.removeAttribute('style');
      //   window.scrollTo(0, this.scroll_position_freeze);
      //   this.scroll_position_freeze = undefined;
      // }
    },
    /* set_is_mobile_info() {
      const appElem = document.getElementById('app');
      const appStyles = window.getComputedStyle(appElem, null);
      const mobileTransitionThreshold = appStyles.getPropertyValue('--transition-threshold-1').slice(0, -2);

      const isMobile = window.innerWidth <= parseInt(mobileTransitionThreshold);
      localStorage.setItem('is_mobile', isMobile);
    }, */
  },
  /* beforeMount() {
    this.set_is_mobile_info();
  }, */
  beforeUnmount() {
    localStorage.removeItem('is_mobile');
  },
  props: {
    content_template: undefined,
    parent_structure: undefined,
    name: {
      type: String,
      default: 'Добро пожаловать!',
    },
    is_with_background: {
      type: Boolean,
      default: true,
    },
    fixedMobileHeader: {
      type: Boolean,
      default: false,
    },
    onlyContent: {
      type: Boolean,
      default: false,
    },
  },
});

</script>

<style lang="less">

@global__background-color: @background;
@left__column__background-color: @white;
@left__column__background__background-color: @white-opacity;
@logo__text__color: @white;
@top__panel_background-color: @white;

#base_app {
  .flex(row, flex-start, flex-start);
  height: 100%;
  // width: 100vw;
  z-index: 1;
  background: @global__background-color;
  color: @dark;
  overflow: hidden;
  .left-column {
    .set_custom_scrollbar(6px, 10px, 0px, 20px, 0px);
    .flex(column, flex-start, flex-start);
    height: 100%;
    min-width: 260px;
    max-width: 260px;
    background: @left__column__background-color;
    // background: linear-gradient(135deg, black, #A1D992);

    &__background{
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: transparent;
      max-width: 0;
    }

    .logo{
      min-height: 36px;
      min-width: 112px;
      margin: 27px auto 55px auto;
      // margin: auto;
      border-radius: 10px;
      color: @logo__text__color;
    }
  }
  .right-column{
    .flex(column, flex-start, flex-start);
    width: 100%;
    height: 100%;
    position: relative;
    .top-panel{
      .flex(column, center, center);
      min-height: 60px;
      max-height: 60px;
      width: 100%;
      background: @top__panel_background-color;
      box-shadow: 0 4px 24px rgba(152, 152, 152, 0.07);
      z-index: 100;
      // position: absolute;
    }
    .content-panel{
      .flex(column, flex-start, flex-start);
      flex-grow: 1;
      width: 100%;
      // width: 100%;
      height: 1px;
      border-radius: 10px;
      // padding-top: 60px;
    }
    .bottom-panel {
      display: none;
    }
  }
}

@media screen and (max-width: @transition-threshold-1) {
  #base_app {

    .left-column {
      position: fixed;
      top: 0;
      overflow: hidden;
      transition: min-width 100ms, max-width 100ms, background 2s, background-color 2s;
      z-index: 150;

      &[opened=true] {
        min-width: 260px;
        max-width: 260px;

        .left-column__background{
          background-color: @left__column__background__background-color;
          max-width: 100%;
          z-index: -1;
        }
      }
      &[opened=false] {

        min-width: 0;
        max-width: 0;

        .left-column__background{
          background-color: transparent;
          max-width: 0;
        }
      }
    }

    .right-column{
       .content-panel{
        margin-top: 10px;
       }

      .bottom-panel {
        //min-height: 60px;
        //max-height: 60px;
        display: block;
        width: 100%;
        // position: absolute;
        // bottom: 0;
        // left: 0;
        // right: 0;
        // height: 60px;
        .flex(row, flex-start, stretch);
      }
    }
  }
}

</style>
