<template>
  <div class = "desktop-user-menu">
    <div v-for = "(item, idx) in menuItems"
         :key = "idx">
      <Separator v-if = "item.separatorTop" separatorType = "horizontal" class = "desktop-user-menu__separator"/>
      <div class = "desktop-user-menu__item">

        <div class = "desktop-user-menu__link"
             @click = "$emit('select_elem', idx)">
          <IconComponent type = 'svg' size = 16 :name = "item.icon" class = "desktop-user-menu__icon"/>
          <LabelComponent label_type = 'body/large/regular 12'
                          :label_text = "item.name"/>
        </div>

      </div>
      <!-- <Separator v-if = "item.separatorBottom" separatorType = "horizontal" class = "desktop-user-menu__separator"/> -->
    </div>
  </div>

</template>

<script>
import Separator from '@/units/forms/Separator';
import LabelComponent from '@/units/Label';
import IconComponent from '@/units/Icon';
// import SeparatorComponent from '../../unit/Separator';

export default ({
  name: 'Test_ProfileUserMenu',
  components: {
    IconComponent,
    LabelComponent,
    Separator,
    // SeparatorComponent,
  },
  emits: {select_elem: null},
  props: ['menuItems'],
});
</script>

<style lang = "less">
@profile__icons__background__color: @gray;
@profile__desktop__text__color: @gray;
@profile__desktop__background-color: @white;
@profile__desktop__item__hover__background-color: @light-green-opacity-10;
@profile__desktop__icon__color: @gray;

.icon-style-color {
  background-color: @profile__icons__background__color;
}

.desktop-user-menu {
  position: absolute;
  top: 5px;
  right: 0;

  //.flex(column, flex-start, flex-start);
  color: @profile__desktop__text__color;
  background-color: @profile__desktop__background-color;
  box-shadow: 0 6px 40px rgba(46, 50, 45, 0.12);
  border-radius: 7px;
  /*Ширина профиль меню*/
  min-width: 210px;
  /*отступы в выпадающем Профиль-меню*/
  padding: 8px 0 8px;
  z-index: 100;

  &__item {
    width: 100%;

    &:hover {
      cursor: pointer;
      background: @profile__desktop__item__hover__background-color;
    }

  }

  &__link {
    .flex(row, flex-start, center);
    //padding-top: 6px;
    //padding-bottom: 6px;
    //padding-left: 20px;
    //padding-right: 20px;
    padding: 6px 20px;
  }

  &__icon {
    margin-right: 6px;
    .set_icon_color(@profile__desktop__icon__color);
  }

  &__separator {
    margin: 6px 20px;
  }

}

@media screen and (max-width: @transition-threshold-1) {
  .desktop-user-menu {
    display: none;
  }
}
</style>
