const curUrl = new URL(window.location.href);

const serverPort = '';
const IS_RU_INTERFACE = false;
export const ROOT_URL = curUrl.protocol + '//' + curUrl.hostname + serverPort;

import {createStore} from 'vuex';
import getLanguages from '@/assets/dataSources/getLanguages';
import getTranslations from '@/assets/dataSources/getTranslations';
import getNotificationInfo from '@/assets/dataSources/pages/getNotificationInfo';

export const store = createStore({
  state: {
    urls: {
      root: ROOT_URL,
    },
    auth_settings: {
      user_obj: null,
      is_user_obj_updated: false,
    },
    navigate_settings: {
      web_site_struct: null,
      web_site_tree_struct: null,
      is_web_site_struct_updated: false,
      current_page_info: undefined,
    },
    full_screen_loading: 0,
    languages: [],
    base_lang: (curUrl.hostname.startsWith('online-ru') || IS_RU_INTERFACE) ? 'ru' : 'en',
    translations: {},
    notification_count: 0,
    is_active: false,
  },
  mutations: {
    mutate_notification_count(state) {
      if (!this.getters.isLoggedIn) {
        return;
      }
      getNotificationInfo((newCount) => state.notification_count = newCount, true);
    },
    set_full_screen_loading(state, up) {
      if (up) {
        state.full_screen_loading = state.full_screen_loading + 1;
      } else {
        state.full_screen_loading = Math.max(state.full_screen_loading - 1, 0);
      }
    },
    is_user_login_now(state) {
      state.auth_settings.is_user_obj_updated = false;
      state.navigate_settings.is_web_site_struct_updated = false;
    },
    user_obj_mut(state, data) {
      state.auth_settings.is_user_obj_updated = true;
      state.auth_settings.user_obj = data;

      if (data.is_anonymous != true && window.WORK_IN_APPLICATION) {
        window.applicationCallback.setPushToken = (status, key, value) => {
          if (value != null && value !== '') {
            let appType = null;

            if (window.WORK_IN_IOS_APPLICATION) {
              appType = 'APNS';
            }

            if (window.WORK_IN_ANDROID_APPLICATION) {
              appType = 'FBS';
            }

            if (appType == null) {
              return;
            }

            const body = new FormData();
            body.append('push_system', appType);
            body.append('token', value);

            fetch(
                ROOT_URL+'/push_system/register_token/',
                {
                  method: 'POST',
                  credentials: 'include',
                  body,
                },
            );
          }
        };

        window.sendMessageInApplication({
          type: 'get_saved_string',
          key: '__push_token__',
          callback: 'window.applicationCallback.setPushToken',
        });
      }

      // translations loading
      getLanguages((langs) => state.languages = langs);
      state.base_lang = state.auth_settings.user_obj.lang == null ? state.base_lang : state.auth_settings.user_obj.lang;
      getTranslations((trans) => state.translations[state.base_lang] = trans, state.base_lang);
    },
    web_site_struct_mut(state, data) {
      state.navigate_settings.is_web_site_struct_updated = true;
      state.navigate_settings.web_site_tree_struct = data; // resortPages(data);
      state.navigate_settings.web_site_struct = data;
    },
    current_page_info_mut(state, data) {
      state.navigate_settings.current_page_info = data;
    },
  },
  actions: {
  },
  modules: {
    // adminModule,
  },
  getters: {
    getNotificationCount: (state) => {
      return state.notification_count;
    },
    getLanguages: (state) => {
      return state.languages;
    },
    getTranslations: (state) => {
      return state.translations[state.base_lang] == null ? {} : state.translations[state.base_lang];
    },
    getUserObj: (state) => {
      return state.auth_settings.user_obj;
    },
    isLoggedIn: (state, getters) => {
      return getters.isEndLoadAuthInfo &&
            state.auth_settings.user_obj !== null &&
            state.auth_settings.user_obj.is_anonymous == false; // && state.navigate_settings.web_site_struct !== null;
    },
    hasPermission: (state) => (perm) => {
      return null != state.auth_settings.user_obj.permissions.find((item) => item === perm);
    },
    isEndLoadAuthInfo(state) {
      return state.auth_settings.is_user_obj_updated && state.navigate_settings.is_web_site_struct_updated;
    },
    getSortedLayer: (state) => (parentPageId) => {
      if (!state.navigate_settings.web_site_struct) return [];
      const layer = state.navigate_settings.web_site_struct.filter((item) => item.parent_page_id === parentPageId);
      layer.sort((itemA, itemB) => {
        if (itemA.priority < itemB.priority) return -1;
        else if (itemA.priority > itemB.priority) return 1;
        else return 0;
      });
      return layer;
    },
    getBreadCrumbsToPage: (state, getters) => (pageId) => {
      const breadCrumbs = [getters.getPageInfo(pageId)];
      while (breadCrumbs[0]['parent_page_id']) {
        breadCrumbs.unshift(getters.getPageInfo(breadCrumbs[0]['parent_page_id']));
      }
      return breadCrumbs;
    },
    getPageInfo: (state) => (pageId) => {
      const layer = state.navigate_settings.web_site_struct.filter((item) => item.id === pageId);
      return (layer.length > 0) ? layer[0] : undefined;
    },
  },
});

export default store;
