import {ROOT_URL} from '@/store';
// import {errorHandler} from '@/assets/dataSources/requestErrorHandler.js';

const REQUEST_LINK = '/auth/get_user_info/';

function getUserInfo(handler, isOnlyPing) {
  const headers = !isOnlyPing ? undefined : {
    'Ping-Package': true,
  };

  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'GET',
    credentials: 'include',
    // body: JSON.stringify({params: {}}),
    headers: headers,
  }).then(async function(response) {
    const userData = await response.json();
    handler(userData);
  });

  // dataRequester({
  //   url: ROOT_URL + REQUEST_LINK,
  //   method: 'post',
  //   body: {
  //     params: {},
  //   },
  // }).then((userData) => {
  //   handler(userData);
  // }).catch((errData) => {});
}

export default getUserInfo;
