<template>
  <AuthBase>
     <div class="login-frame">
      <div class="login_frame_header_text">
        <LabelComponent class="login-frame__header"
                            label_type="h1"
                            :label_text="'Войдите в свой Личный Кабинет'"/>
      </div>
        <form>
          <div class="login-frame__footer" >
            <LabelComponent label_type="button/large/regular 14"
                            :label_text="'Нет аккаунта?'"/>

            <ButtonComponent :label="'ЗАРЕГИСТРИРОВАТЬСЯ'"
                            elementType="default"
                            @click="this.$router.replace({path: '/register'});"/>

          <LabelComponent class="login-frame__global-error"
                          v-show="this.global_error_text != null"
                          :label_text="this.global_error_text"/>
          <InputField class="login-frame__login-field"
                      @input_change="set_login"
                      name="username"
                      autocomplete="on"
                      :label_text="'Имя Пользователя (e-mail адрес)'"
                      :input_field_status="this.login_input_data.status"
                      :error_text="this.login_input_data.error_text"/>
          <InputField class="login-frame__login-field"
                      @input_change="set_password"
                      name="password"
                      autocomplete="on"
                      :label_text="'Пароль'"
                      :input_field_status="this.password_input_data.status"
                      :error_text="this.password_input_data.error_text"
                      type="password"/>

          <ButtonComponent class="login-frame__button1"
                          label_type="button/large/medium 18"
                          elementSize="default"
                           @click="this.loginUserInSystem()"
                           :label="'ВОЙТИ'"/>
          <ButtonComponent class="login-frame__button2"
                           elementSize="regular"
                           elementType="default"
                           @click="this.$router.replace({path: '/reset_password'});"
                           :label="'Забыли пароль?'"/>
          <ButtonComponent v-if="this.work_in_application()"
                           class="login-frame__button2"
                           elementType="default"
                           @click="this.clear_cache()"
                           :label="'Очистить кэш приложения'"/>
                          </div>
        </form>
        <br/>
        <div class="header_text_registration">
            <LabelComponent
                      label_type="body/large/regular 12"
                      label_text="Our Clients use so-called two-factor authentication to enter their online Personal Account. This security system guarantees that neither hackers nor identity thieves will steal your private data."/>
        </div>
        <div class="header_text_registration">
            <LabelComponent
                      label_type="body/large/regular 12"
                      label_text=""/>
        </div>
    </div>
        <PinPage v-if="this.usePinWindow/* || true*/"
                 :next_page="this.next_page"
                 :cryptUserDataSaveKey="this.cryptUserDataSaveKey"
                 :savedUserData="this.readApplicationUserData"/>
        <TFAModal v-if="this.tfa_session != null"
                  :error_message="this.tfa_error"
                  @setTfaCode="this.setTfaCode"
                  :tfa_code_symbol_count="this.tfa_code_symbols"
                  @closeModal="this.tfa_session = null"
                  />
  </AuthBase>
</template>

<script>
import LabelComponent from '@/units/Label';
import ButtonComponent from '@/units/forms/Button';
import InputField from '@/units/forms/InputField';
import PinPage from './PinPage';
import AuthBase from './AuthBase';
import TFAModal from './TFAModal';

import {authRequest} from '@/assets/dataSources/auth/authRequest';
import tfaAuth from '@/assets/dataSources/auth/tfaAuth';

export default ({
  components: {
    LabelComponent,
    ButtonComponent,
    InputField,
    PinPage,
    AuthBase,
    TFAModal,
  },
  props: {
    next_page: {default: '/'},
  },
  data: ()=> ({
    global_error_text: undefined,
    login_input_data: {
      status: undefined,
      error_text: undefined,
    },
    password_input_data: {
      status: undefined,
      error_text: undefined,
    },
    login: undefined,
    password: undefined,
    usePinWindow: false,
    readApplicationUserData: undefined,
    cryptUserDataSaveKey: 'cryptCurrentUser',
    tfa_code_symbols: 6,
    tfa_error: null,
    tfa_session: null,
    tfa_requesting: false,
  }),
  watch: {
    tfa_session() {
      if (this.tfa_session == null) {
        const index = window.acceptableElements.indexOf(this.loginUserInSystem);

        if (index < 0) window.acceptableElements.push(this.loginUserInSystem);
      } else {
        const index = window.acceptableElements.indexOf(this.loginUserInSystem);
        if (index > -1) {
          window.acceptableElements.splice(index, 1);
        }
      }
    },
  },
  mounted() {
    window.acceptableElements.push(this.loginUserInSystem);

    if (!this.usePin) {
      return;
    }

    window.applicationCallback.setCryptCurrentUser = (status, key, value) => {
      this.readApplicationUserData = value;

      if (value != null && value !== '') {
        this.usePinWindow = true;
      }
    };

    window.sendMessageInApplication({
      type: 'get_saved_string',
      key: this.cryptUserDataSaveKey,
      callback: 'window.applicationCallback.setCryptCurrentUser',
    });
  },
  unmounted() {
    const index = window.acceptableElements.indexOf(this.loginUserInSystem);
    if (index > -1) {
      window.acceptableElements.splice(index, 1);
    }
  },
  computed: {
    usePin() {
      return window.WORK_IN_APPLICATION && window.hasFunctions([
        'save_string',
        'get_saved_string',
        'delete_saved_string',
      ]);
    },
  },
  methods: {
    work_in_application() {
      return window.WORK_IN_APPLICATION;
    },
    clear_cache() {
      window.sendMessageInApplication({
        type: 'clear_cache',
        callback: 'window.applicationCallback.cacheCleared',
      });
    },
    set_login(data) {
      this.login=data;
    },
    set_password(data) {
      this.password=data;
    },
    nextUserLogin() {
      if (this.usePin) {
        this.usePinWindow = true;
      } else {
        this.$store.commit('is_user_login_now');
        this.$router.replace({path: this.next_page});
      }
    },
    setTfaCode(newTfaCode) {
      if (this.tfa_requesting) {
        return;
      }
      this.tfa_requesting = true;
      // console.log('newTfaCode', newTfaCode);

      tfaAuth((response) => {
        if (response.status == 200) {
          this.tfa_session = null;
          this.nextUserLogin();
          return;
        }

        this.tfa_error = 'Неправильный код!';
      }, this.tfa_session, newTfaCode).finally(() => (this.tfa_requesting = false));
    },
    loginUserInSystem() {
      const errorText = 'Поле должно быть заполнено';
      this.login_input_data.status = !this.login ? 'error' : undefined;
      this.login_input_data.error_text = !this.login ? errorText : undefined;
      this.password_input_data.status = !this.password ? 'error' : undefined;
      this.password_input_data.error_text = !this.password ? errorText : undefined;
      if (!this.login || !this.password) return 0;

      this.$store.commit('set_full_screen_loading', true);

      const request = authRequest(
          {
            login: this.login,
            password: this.password,
          });

      const curThis = this;

      request
          .then(async (result) => {
            if (result.status != 200) {
              throw result;
            }

            const resultText = await result.text();

            const tfaHeader = 'req_tfa|';

            if (String(resultText).startsWith(tfaHeader)) {
              curThis.tfa_error = null;
              curThis.tfa_session = resultText.substring(tfaHeader.length);
              return;
            }

            this.nextUserLogin();
          }).catch(async function(result) {
            if (result.status == 401) {
              const serverText = await result.text();
              console.log('serverText', serverText);

              if (serverText == 'account is not active') {
                curThis.global_error_text = 'Не удалось авторизоваться. Оплатите регистрацию по ссылке на почте!';
              } else {
                curThis.global_error_text = 'You were unable to log into your Personal Account: wrong email or password. Please try again.';
              }
            } else {
              const errorText = await result.text();
              const errorStatus = result.statusText;
              const errorCode = result.status;

              curThis.global_error_text = 'Не удалось авторизоваться. Возникла техническая ошибка.' +
              'Код:' + errorCode + errorText;
            }
          }).finally(() => {
            this.$store.commit('set_full_screen_loading', false);
          });
    },
  },
});
</script>

<style lang="less">
.login_frame_header_text{
    max-width: 360px;
    width: 360px;
    min-width: 360px;
}
.login-frame {
  .flex(column, flex-start, center);
  position: absolute;
  width: 390px;
  //border: 1px solid #000000;
  border-radius: 15px;
  box-shadow: 0 10px 15px #505050;
  padding: 20px;

  &__header {
    .flex(row, center, flex-end);
    text-align: center;
    flex-basis: 0.4;
    vertical-align: middle;
    margin-bottom: 10px;
    font-size: 20px;
  }
  &__global-error {
    color: red;
    text-align: center;
  }

  &__login-field {
    margin: 11px 0;
  }
  &__button1 {
    width: 75%;
    max-width: none;
    margin-top: 10px;
  }
  &__button2 {
    margin-top: 20px;
    color: hsl(0, 100%, 55%);
  }

  &__footer{
    .flex(row, center, center);
    flex-basis: 35px;
    flex-wrap: wrap;
    gap: 8px;
  }
}
.left_text_login{
  width: 277px;

}
.header_text_registration{
    .flex(row, center, flex-end);
    text-align: center;
    flex-basis: 0.4;
    width: 316px;
    vertical-align: middle;
    margin-bottom: 10px;
  }
.content_login_page{    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: nowrap;}
</style>
