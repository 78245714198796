<template>
  <DateRange :label_text="this.getTitle"
             :init_range="this.data_value"
             :base_select_element="(new Date()).getUTCDate() > 6 ? 4 : 5"
             componentType="input_field"
             @date_range_changed="this.updateValue"
             style="max-width: 400px;"
             >
  </DateRange>
  <!-- <InputField v-model="this.data_value"
              :label_text="this.getTitle"
              :type="'date'"
              :set_values="this.data_value"
              :input_field_status="this.rawField.readonly ? 'disabled' : undefined"
              @input_change="this.updateValue"
              /> -->
</template>

<script type="text/javascript">
import DateRange from '@/units/forms/DateRange';

export default ({
  components: {
    DateRange,
  },
  emits: [
    'input_change',
  ],
  data: () => ({
    data_value: {
      from: null,
      to: null,
    },
  }),
  mounted() {
    this.data_value = this.rawField['value'] == null ? {
      from: null,
      to: null,
    } : this.rawField['value'];
  },
  methods: {
    updateValue(newData) {
      this.data_value = newData;
      this.$emit('input_change', newData);
    },
  },
  staticMethods: {
    getPackedInputData(forPackData, rawField) {
      return JSON.stringify(forPackData);
    },
  },
  computed: {
    getTitle() {
      const title = this.rawField.view || this.rawField.system_name;
      return title;
    },
  },
  watch: {
    rawField(newData) {
      if (newData && newData.value) {
        this.data_value = {
          from: new Date(Date.parse(newData.value.from)),
          to: new Date(Date.parse(newData.value.to)),
        };
      } else {
        this.data_value = {
          from: null,
          to: null,
        };
      }
    },
    // set_values(newData) {
    //   // при получении новых данных через props меняем data_values
    //   this.data_value = newData;
    // },
    data_value(newData) {
      // сообщаем родителю, что данные изменены изнутри
      this.$emit('input_change', newData);
    },
  },
  props: {
    rawField: {
      required: true,
      type: Object,
    },
  },
});
</script>

<style lang="less">

</style>
