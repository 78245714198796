<template>
  <BasePage :name="/* 'Отчет ' + */ (this.workReport ? this.workReport.name : '-')">
    <!--<div v-if="Object.keys(this.$route.query).length > 0">
        Обнаружены GET Параметры! {{ this.$route.query }} <br><br>
    </div>-->

    <ReportFiltersForm
      :raw_fields="this.workReport ? (this.workReport.filters ? this.workReport.filters : []) : []"
      @form_changed="this.filterFormChanged"
      style="padding: 10px;"
    />

     <ButtonComponent class="report-generate-button" :label="'Сформировать'"
                      v-if="!this.isAutoBuild"
                      style="margin: auto;"
                      @click_to_button="this.requestReportData"/>

    <div class="uni-report uni-report__padding">
      <component
          v-if="this.views.length > 0"
          ref="firstView"
          :is="this.views[0].component"
          :draw_index="0"
          :draw_info="this.views[0].draw_info"
          :draw_data="this.views[0].draw_data"
          @next_select="this.changeViewSelect">
      </component>
    </div>

    <template v-slot:modal>
      <ModalViewConductor ref="modalViewConductor">
        <template v-slot="{level, rev_level}">
          <ModalView
                     :open_level="rev_level"
                     :type_modal="this.views[level].modal_type"
                     :ref="'modal_'+level"
                     :caption="this.views[level].modalCaption"
                     @click_close="this.closed_modal_view_first"
                     @click_closing="this.$refs.modalViewConductor.close_level_shadow();">

            <component
                :is="this.views[level].component"
                :draw_index="level"
                :draw_info="this.views[level].draw_info"
                :draw_data="this.views[level].draw_data"
                :before_total="this.views[level].before_total"
                @next_select="this.changeViewSelect"
                @close_view="this.closeView">
            </component>
          </ModalView>
        </template>
      </ModalViewConductor>
    </template>
  </BasePage>
</template>

<script>
import ButtonComponent from '@/units/forms/Button';
import BasePage from '@/baseLayout/BasePage';
import getReportInfo from '@/assets/dataSources/reports/getReportInfo';
import buildReport from '@/assets/dataSources/reports/buildReport';
import ModalView from '@/layoutTools/ModalView';
import ModalViewConductor from '@/layoutTools/ModalViewConductor';

import ReportFiltersForm from './forms/ReportFiltersForm';

import uniTable from './views/uni_table';
import {markRaw} from '@vue/reactivity';

const accordanceViews={
  'table': uniTable,
  // 'pie_chart': uniPieChart,
  // 'sliced_table': uniSlicedTable,
  // 'map': uniMap,
  // 'redirect_link': uniOpenLinkAction,
  // 'download_file': uniDownloadFileAction,
  // 'card_list': uniCardList,
  // 'external_form': uniExternalForm,
  // 'form': uniForm,
};

function normaliseValue(value, settings) {
  if (settings == null) {
    settings = {};
  }

  if (typeof(value) == typeof(true)) {
    return value ? 'Да' : 'Нет';
  }

  try {
    if (value && 'view' in value) {
      return value.view;
    }
  } catch {}

  if (!value || value == '') {
    return '';
  }

  function isDate(rawVar) {
    rawVar = String(rawVar);
    // date samples:

    // 0001-01-01T16:15*
    // 0001.01.01T16:15*
    // 0001/01/01T16:15*
    let dateReg = /^\d{4}([./-])\d{2}\1\d{2}T\d{2}:\d{2}.*$/;
    if (dateReg.test(rawVar)) return true;
    // 0001-01-01 16:15*
    // 0001.01.01 16:15*
    // 0001/01/01 16:15*
    dateReg = /^\d{4}([./-])\d{2}\1\d{2} \d{2}:\d{2}.*$/;
    if (dateReg.test(rawVar)) return true;
    // 01-01-0001T16:15*
    // 01.01.0001T16:15*
    // 01/01/0001T16:15*
    dateReg = /^\d{2}([./-])\d{2}\1\d{4}T\d{2}:\d{2}.*$/;
    if (dateReg.test(rawVar)) return true;
    // 01-01-0001 16:15*
    // 01.01.0001 16:15*
    // 01/01/0001 16:15*
    dateReg = /^\d{2}([./-])\d{2}\1\d{4} \d{2}:\d{2}.*$/;
    if (dateReg.test(rawVar)) return true;
    // 16:15
    dateReg = /^\d{2}:\d{2}$/;
    if (dateReg.test(rawVar)) return true;
    // 16:15:00
    dateReg = /^\d{2}:\d{2}:\d{2}$/;
    if (dateReg.test(rawVar)) return true;
    // 0001-01-01
    // 0001.01.01
    // 0001/01/01
    dateReg = /^\d{4}([./-])\d{2}\1\d{2}$/;
    if (dateReg.test(rawVar)) return true;
    // 22.03.1981
    // 22-03-1981
    // 22/03/1981
    dateReg = /^\d{2}([./-])\d{2}\1\d{4}$/;
    if (dateReg.test(rawVar)) return true;
    // 20231129T0934
    dateReg = /^\d{8}T\d{4}$/;
    if (dateReg.test(rawVar)) return true;
    // 20231129T093431*
    dateReg = /^\d{8}T\d{6}.*$/;
    if (dateReg.test(rawVar)) return true;
    return false;
  }

  if (isDate(value)) {
    try {
      const checkDate = new Date(value);

      if (isNaN(checkDate)) {
        const a = {}.var.value;
        console.log(a);
      }

      if (settings.type == 'datetime') {
        const hours = checkDate.getUTCHours();
        const minutes = checkDate.getUTCMinutes();

        const timeString = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');

        return checkDate.toLocaleDateString('ru-RU', {timeZone: 'UTC'}) + ' ' + timeString;
      }

      if (checkDate.getUTCFullYear() < 2000) {
        const hours = checkDate.getUTCHours();
        const minutes = checkDate.getUTCMinutes();

        const timeString = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
        return timeString;
      } else {
        return checkDate.toLocaleDateString('ru-RU', {timeZone: 'UTC'});
      }
    } catch {}
  }

  return value;
}

function packValueToNormal(values, columnSettings) {
  if (!values) {
    return {};
  }

  const ans = {};
  Object.keys(values).forEach(function(key) {
    ans[key] = {
      value: normaliseValue(values[key], columnSettings[key]),
    };
  });

  return ans;
}

function repackTableToNormalVersion(allColumns, level, rows) {
  if (allColumns.length <= level) {
    return {
      next_level: [],
      next_type_data: null,
    };
  }

  const zeroLevelTypeData = {
    type: 'table',
    columns: {},
  };

  const zeroNextLevel = [];

  allColumns[level].forEach(function(elem) {
    zeroLevelTypeData.columns[elem.type] = {
      label: elem.name,
      name: elem.type,
      type: elem.d_type,
    };
  });

  rows.forEach(function(elem) {
    const res = repackTableToNormalVersion(allColumns, level+1, elem.child);

    res.columns = packValueToNormal(elem.values, zeroLevelTypeData.columns);

    zeroNextLevel.push(res);
  });

  const ans = {
    next_level: zeroNextLevel,
    next_type_data: zeroLevelTypeData,
  };

  return ans;
}

export default ({
  name: 'ReportPage',
  components: {
    ButtonComponent,
    BasePage,
    ModalView,
    ModalViewConductor,
    ReportFiltersForm,
  },
  props: {

  },
  data: () => ({
    workReport: null,
    curPageVersion: 0,
    rawReportData: null,
    views: [], // массив объектов с оутпутами для модальных окон
    noData: false,
    filledForm: {},
  }),
  watch: {
    $route(to, from) {
      if (String.comparePaths(to.path, from.path)) {
        // this.rebuildViewsFromParams();
        return;
      }

      // this.currentReportId = -1;
      // this.isFirstPreRequest = true;
      // this.reportVersionsText = undefined;
      // при переключении роутера заново инициализируем параметры
      this.resetParams();
    },
  },
  mounted() {
    window.escapeableElements.push(this.close_all_levels);

    this.resetParams();
  },
  methods: {
    filterFormChanged(newData) {
      this.filledForm = newData;
      // console.log('newData', newData);
    },
    requestReportInfo() {
      this.noData = false;

      const curThis = this;
      const requestForVersion = this.curPageVersion;

      getReportInfo(curThis.$route.params.reportName, function(reportInfo) {
        if (curThis.curPageVersion != requestForVersion) {
          return;
        }

        curThis.workReport = reportInfo;

        if (curThis.isAutoBuild) {
          curThis.requestReportData();
        }
      });
    },
    requestReportData() {
      const curThis = this;
      const requestForVersion = this.curPageVersion;

      buildReport(curThis.$route.params.reportName, this.filledForm, function(builtReport) {
        if (curThis.curPageVersion != requestForVersion) {
          return;
        }

        curThis.rawReportData = builtReport;
        const packedData = repackTableToNormalVersion(curThis.rawReportData.columns, 0, curThis.rawReportData.rows);
        curThis.changeViewSelect(-1, packedData, 0);
      });
    },
    close_all_levels() {
      for (let i = 1; i < this.views.length; i++) {
        this.closed_modal_view_first();
      }
      this.$router.push({query: {}});
    },
    closed_modal_view_first() {
      this.$refs.modalViewConductor.close_level();

      this.views.pop(); // удаляем последний объект в массиве

      const newQuery = JSON.parse(JSON.stringify(this.$route.query));
      newQuery['uni_level__' + (this.views.length-1)] = undefined;
      this.$router.push({query: newQuery});
    },
    rebuildViewsFromParams() {
      if (this.views.length < 1) {
        this.$router.push({query: {}});
        return;
      }

      // const firstView = this.views[0].__orig__;
      this.views.length = 1;
      this.$refs.modalViewConductor.clearAll();
    },
    resetParams() {
      this.curPageVersion = this.curPageVersion + 1;
      this.$refs.modalViewConductor.clearAll();
      // this._availableReports = undefined;
      // this.additionalPageName = undefined;
      // this.rawInputFields = {};
      // this.hour_update = false;
      // this.show_generate_button = true,
      // this.show_export_to_excel = true,
      this.views.length = 0;
      this.filledForm = {};
      // this.cacheDate = null;
      // this.isFormChanged = false;
      // this.auto_first_request = false;
      // this.show_background = true;
      // this.excel_is_primary = false;
      // this.current_standard_version = 0;
      // this.noDataText = 'Нет данных соответствующих, заданным фильтрам';
      this.requestReportInfo(); // первоначально запрашиваем данные
    },
    setView(index, view) {
      this.views.length = index;

      const curView = accordanceViews[view.next_type_data.type];

      if (curView == null) {
        console.warn('View type not found!', view.next_type_data.type, 'Level:', index, 'View:', view);
        return;
      }

      this.views.push({
        component: markRaw(curView),
        modal_type: view.next_type_data.modal_type === 'fly' ? 'internal' : 'bottom',
        modalCaption: view.modalCaption,
        draw_info: view.next_type_data,
        draw_data: view.next_level,
        before_total: view.before_total,
        __orig__: view,
      });

      return true;
    },
    closeView(clickIndex) {
      this.$refs['modal_'+clickIndex].close_window();
    },
    changeViewSelect(clickIndex, nextView, selectIdx) {
      const nextIsHard = nextView.next_type_data.is_hard_loading;

      if (!this.__setFullLoad__ && nextIsHard) {
        this.$store.commit('set_full_screen_loading', true);
      }
      this.__setFullLoad__ = true;

      const workFunc = () => {
        const nextViewComponent = accordanceViews[nextView.next_type_data.type];
        let alreadyQuery = nextViewComponent ? nextViewComponent.ignoreHistory === true : false;

        if (clickIndex > -1 && !alreadyQuery) {
          const newQuery = JSON.parse(JSON.stringify(this.$route.query));
          alreadyQuery = newQuery['uni_level__' + clickIndex] === selectIdx;

          if (!alreadyQuery) {
            newQuery['uni_level__' + clickIndex] = selectIdx;
            this.$router.push({query: newQuery});

            alreadyQuery = true;
          }
        } else {
          if ((nextView.next_level ? nextView.next_level : []).length < 1) {
            this.noData = true;
          }
        }

        if (clickIndex < 0 || alreadyQuery) {
          this.__setFullLoad__ = false;
          if (this.setView(clickIndex + 1, nextView)) {
            while (this.$refs.modalViewConductor.last_level < this.views.length - 1) {
              this.$refs.modalViewConductor.up_level();
            }
          } else {
            if (nextIsHard) {
              this.$store.commit('set_full_screen_loading', false);
            }
          }
        }
      };

      if (nextIsHard === true) {
        setTimeout(workFunc, 0);
      } else {
        workFunc();
      }
    },
  },
  computed: {
    isAutoBuild() {
      return this.workReport != null && (!this.workReport.filters || this.workReport.filters.length < 1);
    },
  },
});
</script>

<style lang="less">
.uni-report{
  // overflow: auto;
  &__padding{
    padding: 10px;
  }
  &__gen-buttons-row {
    margin: auto;
    .flex(row, flex-start, unset);
  }
  &__generate-button{
    max-width: 250px;
    align-self: center;
  }
  &__download-button{
    align-self: center;
    margin-left: 14px;
  }
  // &__select-report{
  //   max-width: 400px;
  //   align-self: flex-start;
  // }
}
</style>
