import {ROOT_URL, store} from '@/store';

const REQUEST_LINK = '/auth/tfa_auth';

function tfaAuth(handler, tfaSession, tfaCode) {
  store.commit('set_full_screen_loading', true);

  const tfaForm = new FormData();
  tfaForm.append('tfa_session', tfaSession);
  tfaForm.append('tfa_code', tfaCode);

  return fetch(ROOT_URL + REQUEST_LINK + '/', {
    method: 'POST',
    credentials: 'include',
    body: tfaForm,
  }).then(function(response) {
    handler(response);
  }).finally(() => store.commit('set_full_screen_loading', false));
}

export default tfaAuth;
