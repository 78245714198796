<template>
  <div class="button-daterange">
    <!--<LabelComponent :label_text="this.label_text"/>-->
    <div class="date-range-buttons-wrapper">
      <DropDownInput class="select-report-dropdown"
                     :dropdown_items_init="this.dropdownData"
                     :init_selected_item="[this.curSelect]"
                     :label_text="this.label_text"
                     style_type="default"
                     @input_change="(number) => this.activateRange(number[0].return_value, number[0].id)"/>
    </div>

    <ModalView v-if="this.is_date_range_picker_opened" ref="modal_view_ref"
               :open_level="1"
               type_modal="internal"
               position="fixed"
               :caption="'Выбор периода'"
               @click_close="this.closed_modal">

      <ButtonComponent v-if="false && this.get_is_mobile_info"
                       class="submit-button"
                       :label="'Применить'"
                       @click_to_button="this.applyAndClose"/>

      <InputField type="date"
                :label_text="'Начало'"
                :set_values="this.convertDatetimeToInput(this.customSelectorDate.from)"
                style="max-width: 250px;"
                class="date-range-picker"
                      :class="{'date-range-picker-active': this.tmpSelectedRangeId === 4,
                          'date-range-picker': this.tmpSelectedRangeId !== 4}"
                @input_change="(val) => { this.set_date_changed(val, true); }"/>

      <InputField type="date"
                :label_text="'Конец'"
                :set_values="this.convertDatetimeToInput(this.customSelectorDate.to)"
                style="max-width: 250px;"
                class="date-range-picker"
                      :class="{'date-range-picker-active': this.tmpSelectedRangeId === 4,
                          'date-range-picker': this.tmpSelectedRangeId !== 4}"
                @input_change="(val) => { this.set_date_changed(val, false); }"/>

      <ButtonComponent v-if="true || !this.get_is_mobile_info"
                       class="submit-button"
                       :label="'Применить'"
                       @click_to_button="this.applyAndClose"/>
    </ModalView>
  </div>
</template>

<script>
import ButtonComponent from '@/units/forms/Button';
import DropDownInput from '@/units/forms/DropDownInput';
import ModalView from '@/layoutTools/ModalView';
import InputField from '@/units/forms/InputField';

export default ({
  name: 'DateRange',
  components: {
    ButtonComponent,
    DropDownInput,
    ModalView,
    InputField,
  },
  data: function() {
    return {
      // date_range_current принимает начальные данные из props
      date_range_current: this.init_range || {from: new Date(), to: new Date()},
      customSelectorDate: this.init_range || {from: new Date(), to: new Date()},
      is_date_range_picker_opened: false,
      selectedRangeId: this.init_range ? 4 : 0,
      tmpSelectedRangeId: this.init_range ? 4 : 0,
      curSelect: 0,
    };
  },
  props: {
    label_text: {
      required: true,
      type: String,
    },
    elementType: {
      required: false,
      default: 'hover-bg',
    },
    elementStatus: {
      required: false,
      default: 'enabled',
    },
    init_range: {
      type: Object,
      required: false,
    },
    base_select_element: {
      required: false,
      default: 1,
    },
    componentType: {
      required: false,
      default: 'button',
      validator: (prop) => ['button', 'input_field'].includes(prop),
    },
  },
  emits: ['date_range_changed_by_click', 'date_range_changed', 'click_to_button'],
  created() {
    this.activateRange(this.dropdownData[this.base_select_element]['return_value'], 0);
  },
  methods: {
    convertDatetimeToInput(datetime) {
      const ans = (datetime.getUTCFullYear() + '').padStart(4, '0') + '-' +
                  String('00' + (datetime.getUTCMonth()+1)).slice(-2) + '-' +
                  String('00' + datetime.getUTCDate()).slice(-2);

      return ans;
    },
    activateRange(id, number) {
      this.curSelect = id;
      // console.log('aaaaaaaa', id, number);

      this.selectedRangeId = id;
      this.tmpSelectedRangeId = this.selectedRangeId;
      if (id === 4) {
        this.click_to_button();
      } else {
        this.applyAndClose();
      }
    },
    format_date(date) {
      return new Intl.DateTimeFormat('ru-Ru').format(date);
    },
    set_date_changed(datetime, isFrom) {
      datetime = new Date(datetime);

      if (isNaN(datetime)) {
        return;
      }

      if (isFrom) {
        this.customSelectorDate.from = datetime;
      } else {
        this.customSelectorDate.to = datetime;
      }
    },
    date_range_changed(range) {
      // пришло событие, что изменились данные на DateRangePicker
      this.customSelectorDate = range;
    },
    click_to_button() {
      // получили клик по элементу ButtonDataRange
      // открываем или закрываем DataPicker
      this.is_date_range_picker_opened = !this.is_date_range_picker_opened;
      if (this.is_date_range_picker_opened) {
        this.tmpSelectedRangeId = this.selectedRangeId;
        this.customSelectorDate = this.date_range_current;

        // если открыт дата пикер, то посылаем событие, что элемент (но пока не диапазон) выбран
        // this.$refs.modalViewConductor.up_level();

        this.$emit('date_range_changed_by_click');
      }
    },
    applyAndClose() {
      this.selectedRangeId = this.tmpSelectedRangeId;
      this.date_range_current = this.getCurrentDateRange;

      this.$emit('date_range_changed', this.date_range_current);
      if (this.$refs.modal_view_ref) {
        this.$refs.modal_view_ref.close_window();
      }
    },
    closed_modal() {
      // метод вызываемый снаружи закрывает модалку
      // посылаем событие родителю что выбран период
      // this.$emit('date_range_changed', this.date_range_current);
      this.is_date_range_picker_opened=false;
      this.applyAndClose();
      // this.$refs.modalViewConductor.close_level();
    },
  },
  computed: {
    dropdownData() {
      return [
        {
          value_show: 'Текущий день',
          return_value: 7,
        },
        {
          value_show: 'Вчерашний день',
          return_value: 8,
        },
        {
          value_show: 'Текущая неделя',
          return_value: 9,
        },
        {
          value_show: 'Прошлая неделя',
          return_value: 10,
        },
        {
          value_show: 'Текущий месяц',
          return_value: 0,
        },
        {
          value_show: 'Прошлый месяц',
          return_value: 1,
        },
        {
          value_show: 'Текущий год',
          return_value: 2,
        },
        {
          value_show: 'Прошлый год',
          return_value: 3,
        },
        {
          value_show: 'Позапрошлый год',
          return_value: 5,
        },
        {
          value_show: 'Последние 3 года',
          return_value: 6,
        },
        {
          value_show: 'Произвольный: ' + this.get_data_value,
          return_value: 4,
        },
      ];
    },
    getCurrentDateRange() {
      const ansPack = {
        7: {
          from: new Date().today(),
          to: new Date().today().shiftDateFromCurrent(0, 0, 1),
        }, // Текущий день
        8: {
          from: new Date().today().shiftDateFromCurrent(0, 0, -1),
          to: new Date().today(),
        }, // Вчерашний день
        9: {
          from: new Date().beginCurrentWeek(),
          to: new Date().beginCurrentWeek().shiftDateFromCurrent(0, 0, 7),
        }, // Текущая неделя
        10: {
          from: new Date().beginCurrentWeek().shiftDateFromCurrent(0, 0, -7),
          to: new Date().beginCurrentWeek(),
        }, // Прошлая неделя
        0: {
          from: new Date().beginCurrentMonth(),
          to: new Date().beginCurrentMonth().shiftDateFromCurrent(0, 1, 0).shiftDateFromCurrent(0, 0, -1),
        }, // Текущий месяц
        1: {
          from: new Date().beginCurrentMonth().shiftDateFromCurrent(0, -1, 0),
          to: new Date().beginCurrentMonth().shiftDateFromCurrent(0, 0, -1),
        }, // Прошлый месяц
        2: {
          from: new Date().beginCurrentYear(),
          to: new Date().beginCurrentYear().shiftDateFromCurrent(1, 0, 0).shiftDateFromCurrent(0, 0, -1),
        }, // Текущий год
        3: {
          from: new Date().beginCurrentYear().shiftDateFromCurrent(-1, 0, 0),
          to: new Date().beginCurrentYear(),
        }, // Прошлый год
        4: this.customSelectorDate, // Ручная установка
        5: {
          from: new Date().beginCurrentYear().shiftDateFromCurrent(-2, 0, 0),
          to: new Date().beginCurrentYear().shiftDateFromCurrent(-1, 0, 0).shiftDateFromCurrent(0, 0, -1),
        }, // Позапрошлый год
        6: {
          from: new Date().beginCurrentYear().shiftDateFromCurrent(-2, 0, 0),
          to: new Date().beginCurrentYear().shiftDateFromCurrent(1, 0, 0).shiftDateFromCurrent(0, 0, -1),
        }, // Последние 3 года
      };

      return ansPack[this.selectedRangeId];
    },
    get_data_value() {
      const val = this.date_range_current;
      return (val) ? `${this.format_date(val['from'])} - ${this.format_date(val['to'])}` : '';
    },
  },
  watch: {
    init_range(newData) {
      // сообщаем родителю, что данные изменены изнутри
      this.date_range_current = newData;
      this.applyAndClose();
    },
  },
});
</script>

<style lang="less">
.no-pointer-events {
  pointer-events: none;
}

.button-daterange{
  position: relative;
  // padding: 7px;
}

.submit-button {
  width: min-content;
  margin-left: auto;
  margin-top: 25px;
}

.date-range-buttons-wrapper {
  .flex(row, flex-start, flex-start);
  flex-wrap: wrap;

  & > * {
    margin-right: 9px;
    margin-top: 9px;
  }
}

.date-range-picker {
  margin-top: 15px;
  transition: all 0.5s;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.date-range-picker-active {
  margin-top: 15px;
  transition: all 0.5s;
  opacity: 1;
  max-height: 600px;
  overflow: hidden;
}

.picker-wrapper {
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  background: white;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid @super-light;
  display: flex;
  box-shadow: 0 16px 20px rgba(46, 50, 45, 0.06);
  z-index: 1002;

  &__back{
    position: fixed;
    inset: 0;
    z-index: -1;
  }
}

@media screen and (max-width: @transition-threshold-1) {
  .picker-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    &__wrapper {
      position: fixed;
    }
  }
}
</style>
