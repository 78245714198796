import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import eventsHandler from '@/assets/events_handler';
// import {ROOT_URL} from '@/assets/v1/js/project_management/DataRequester';
import 'inputmask';
// const util = require('util');

class MyDate extends Date {
  constructor(...args) {
    if (args.length == 1 && typeof args[0] == typeof '' && args[0][args[0].length - 1] != 'Z') {
      args[0] = args[0] + 'Z';
      // console.log('aaaaaa');
    }

    // console.log(args);

    super(...args);
  }

  // toString() {
  //   return this.toISOString();
  // }
}

window.Date = MyDate;

window.WORK_IN_IOS_APPLICATION = window.webkit != null &&
                                 window.webkit.messageHandlers != null &&
                                 window.webkit.messageHandlers.jsHandler != null;
window.WORK_IN_ANDROID_APPLICATION = window.Android != null &&
                                     window.Android.additionalFunction != null;

window.WORK_IN_APPLICATION = window.WORK_IN_IOS_APPLICATION || window.WORK_IN_ANDROID_APPLICATION;

if (window.WORK_IN_APPLICATION) {
  window.applicationCallback = {};
  window.availableApplicationFunctions = [];
  window.emptyCallback = () => {};

  window.hasFunctions = (checkFunctions) => {
    for (const funcIdx in checkFunctions) {
      if (window.availableApplicationFunctions.find((item) => item === checkFunctions[funcIdx]) == null) {
        return false;
      }
    }

    return true;
  };

  if (window.WORK_IN_IOS_APPLICATION) {
    window.sendMessageInApplication = (data) => {
      if (data['callback'] == null) {
        data['callback'] = 'window.emptyCallback';
      }
      window.webkit.messageHandlers.jsHandler.postMessage(data);
    };
  }

  if (window.WORK_IN_ANDROID_APPLICATION) {
    window.sendMessageInApplication = (data) => {
      if (data['callback'] == null) {
        data['callback'] = 'window.emptyCallback';
      }
      window.Android.additionalFunction(JSON.stringify(data));
    };
  }

  window.applicationCallback.availableTypes = (types) => {
    window.availableApplicationFunctions = types;

    if (window.hasFunctions(['check_permissions'])) {
      window.sendMessageInApplication({type: 'check_permissions'});
    }
  };

  window.CallApplicationEvent = (platform, type, data) => {
    alert(platform + ' ' + type + ' ' + data.token);
  };

  window.applicationCallback.setIsFileSelectMode = (status, key, value) => {
    window.IntIsFileSelectMode = value == '1';
  };

  window.sendMessageInApplication({type: 'available_types', callback: 'window.applicationCallback.availableTypes'});
  window.sendMessageInApplication({type: 'get_saved_string', key: 'APP__IS_FILE_SELECT_MODE', callback: 'window.applicationCallback.setIsFileSelectMode'});
}

window.registeredModalHandlers = {};
window.lastOpenedModalId = 0;

window.registerModalHandler = function(handler) {
  const newHandlerId = window.lastOpenedModalId + 1;
  window.lastOpenedModalId = newHandlerId;

  window.registeredModalHandlers[newHandlerId] = handler;

  return newHandlerId;
};

window.getModalHandler = function(handlerId) {
  return window.registeredModalHandlers[handlerId];
};

window.unregisterModalHandler = function(handlerId) {
  window.registeredModalHandlers[handlerId] = undefined;
};

if (Object.fromEntries == undefined) {
  Object.fromEntries = (arr) => Object.assign({}, ...Array.from(arr, ([k, v]) => ({[k]: v}) ));
}

const VueInputMask = require('vue-inputmask').default;

window.globalEvents = eventsHandler();
createApp(App)
    .use(store)
    .use(router)
    .use(VueInputMask)
    .mount('#app');
