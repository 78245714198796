<template>
  <template v-if="this.levelData">
    <div v-for="(item, key) in this.levelData" :key="key">
         <!-- v-show="this.selectedChildKey > -1"> -->
      <linkTo v-if="this.getCanChangeNavElem"
              :href="'/object_edit_form/self__NavigationElementModelObject/' + item.uid"
              :newTab="false" class="menu__change_nav">
        <IconComponent size=12 :url="'/icons/gear.svg'" color="red"/>
      </linkTo>
      <linkTo :href="this.normaliseItemLink(item.link)" :newTab="item.open_new_tab === true"
                    @click="this.$emit('select_changed')"
                    class="menu__item"
                    :class="{'menu__item_active': this.isItemSelected(item, key)}">

          <div class="menu__content" :style="{'margin-left': this.currentDepth*17+'px'}">
            <IconComponent v-if="!item.icon" size=24 :url="'/icons/caret-circle-down.svg'" color="red" style="transform: rotate(-90deg);"/>
            <IconComponent v-else size=24 :url="item.icon" :type="'png'"/>
            <LabelComponent class="menu__text"
                            label_type="body/large/regular 14"
                            :label_text="item.name">

            </LabelComponent>
          </div>
          <div class="menu__pointer"/>
      </linkTo>
      <StructNode :levelData="item.children"
                  :currentDepth="this.currentDepth+1"
                  @select_changed="this.$emit('select_changed')"/>
    </div>
  </template>
</template>

<script type="text/javascript">
import LabelComponent from '@/units/Label';
import IconComponent from '@/units/Icon';
// import builder from '@/assets/v1/js/builder';
import linkTo from '@/units/linkTo.vue';

export default /* builder */({
  name: 'StructNode',
  components: {
    LabelComponent,
    IconComponent,
    linkTo,
  },
  emits: ['select_changed'],
  data: ()=> ({
    // selectedChildKey: -1,
  }),
  watch: {
    // selectedChildKey: function() {
    //   if (this.selectedChildKey > -1) {
    //     this.$emit('is_active', this.levelKey);
    //   }
    //   this.$nextTick(() => {
    //     this.$forceUpdate();
    //   });
    // },
    // $route(to, from) {
    //   if (to.path === from.path) {
    //     return;
    //   }

    //   // this.selectedChildKey = -1;
    // },
  },
  props: {
    levelData: {default: undefined},
    // levelKey: {default: undefined},
    currentDepth: {default: 0},
  },
  computed: {
    get_current_page_info() {
      return this.$store.state.navigate_settings.current_page_info;
    },
    getCanChangeNavElem() {
      return this.$store.getters.hasPermission('self__NavigationElementModelObject__change_object');
    },
  },
  methods: {
    /* setActiveItem(itemKey) {
      this.selectedChildKey = itemKey;
    }, */
    normaliseItemLink(link) {
      if (!String(link).startsWith('http') && !String(link).startsWith('/') && !String(link).startsWith('\\')) {
        return '/' + String(link);
      }
      return String(link);
    },
    isItemSelected(item, key) {
      // const ans = this.selectedChildKey === key || (this.get_current_page_info &&
      //     item.id === this.get_current_page_info.id);
      // if (ans) {
      //   this.selectedChildKey = key;
      // }
      // return ans;
    },
  },
});
</script>

<style lang="less">
@left__menu__content__text__color: @dark;
@left__menu__icon__color: black;

@left__menu__item__text__color: @white;
@left__menu__item__hover__background-color: @light-gray-opacity-10;
@left__menu__item__active__background-color: @light-green-opacity-10;
@left__menu__item__active__menu_pointer__color: @light-green;
@left__menu__item__active__hover__background-color: @gray;

.menu {
  &__change_nav{
    position: fixed;
    margin: 15px;
  }

  &__content{
    .flex(row, flex-start, center);
    padding: 10px 10px 10px 32px;
    color: @left__menu__content__text__color;
    .set_icon_color(@left__menu__icon__color)
  }
  &__text{
    margin-left: 14px;
  }

  &__item{
    .flex(row, space-between, stretch);
    width: 100%;
    color: @left__menu__item__text__color;
    text-decoration: none;

    &:hover{
      background: @left__menu__item__hover__background-color;
    }

    &_active{
      background: @left__menu__item__active__background-color;
      pointer-events: none;
      .menu__pointer{
        min-width: 4px;
        max-width: 4px;
        background: @left__menu__item__active__menu_pointer__color;
        display: flex;
        border-radius: 8px 0 0 8px;
      }

      &:hover{
        background: @left__menu__item__active__hover__background-color;
      }
    }
  }
}

@media screen and (max-width: @transition-threshold-1) {
  .left-column[opened=false] {
    .menu {
      &__change_nav {
        display: none;
      }
    }
  }
}

</style>
