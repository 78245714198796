<template>
  <div class="content-block-no-resize">
    <div class="content-wrapper">
      <slot></slot>
   </div>
  </div>
</template>

<script type="text/javascript">

export default ({
});


</script>

<style lang="less">
.content-block-no-resize{
  .flex(column, flex-start, flex-start);
  position: relative;
  flex-grow: 1;

  width: 100%;

  overflow: hidden;

  .set_custom_scrollbar(6px, 10px, 0px, 20px, 20px);

  .content-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
}


</style>
