<template>
  <div class="time_picker">
    {{ this.ten_hour }}{{ this.one_hour }} : {{ this.ten_minute }}{{ this.one_minute }}

    <br/><br/>

    <div v-if="this.date_select_mode == 'manual' || this.date_select_mode == 'both'" style="white-space: nowrap;">
      <input v-model="this.hours_picker"
            ref="hours_picker"
            type="number"
            :min="0"
            :max="23"
            /> :

      <input v-model="this.minutes_picker"
            ref="minutes_picker"
            type="number"
            :min="0"
            :max="59"
            />

      <br/><br/>
    </div>

    <template v-if="this.date_select_mode == 'pick' || this.date_select_mode == 'both'">
      <table class="time-picker-table">
        <!-- <tr class="time-row-wrapper">
          <td v-for="idx in [0, 1, 2, 3]" :key="idx" class="up-buttons">
            <LabelComponent v-if="idx != 0" label_text="<"
                        @click="null"
                        label_type="body/large/regular 12"
                        class="arrow-active"/>
          </td>
        </tr> -->

        <tr v-for="rowIdx in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]" :key="rowIdx" class="time-row-wrapper">
            <td v-for="columnIdx in [0, 1, 2, 3, 4]"
                :key="columnIdx"
                @click="this.selectElem(rowIdx, columnIdx, this.selectStruct[columnIdx][rowIdx])">
                <div :class="rowIdx == this.base_select_pos ? 'active-element' : 'clickable-element'">
                  <LabelComponent :label_text="this.selectStruct[columnIdx][rowIdx] != null ? this.selectStruct[columnIdx][rowIdx] : ''"
                        label_type="body/large/regular 12"
                        />
                </div>
            </td>
        </tr>

        <!-- <tr class="time-row-wrapper">
          <td v-for="idx, item in [0, 1, 2, 3]" :key="item" class="bottom-buttons">
            <LabelComponent v-if="idx != 0" label_text=">"
                        @click="null"
                        label_type="body/large/regular 12"
                        class="arrow-active"/>
          </td>
        </tr> -->
      </table>
    </template>
  </div>
</template>

<script type="text/javascript">
import LabelComponent from '@/units/Label';

export default ({
  name: 'LabelTemplate',
  components: {LabelComponent},
  data: function() {
    return {
      hours_picker: 0,
      minutes_picker: 0,

      base_select_pos: 0,
      ten_hour: 0,
      one_hour: 0,
      ten_minute: 0,
      one_minute: 0,
    };
  },
  props: {
    // day_start_week_id: {
    //   default: 0,
    // },
    // restrictions_start: {
    //   type: Date,
    //   default: new Date(-8640000000000000),
    // },
    // restrictions_end: {
    //   type: Date,
    //   default: new Date(8640000000000000),
    // },
    init_time: {
      type: Date,
      default: new Date(0),
    },
    date_select_mode: {
      type: String,
      default: 'manual',
      validator: (prop) => ['manual', 'pick', 'both'].includes(prop),
    },
    // top_label: {
    //   type: String,
    //   default: null,
    // },
  },
  emits: ['select_changed'],
  computed: {
    selectStruct() {
      const tenHourStruct = Array.from([0, 1, 2, 0, 1, 2, 0, 1, 2].join('').substring(this.ten_hour + 3 - this.base_select_pos, this.ten_hour + 3 + (3 - this.base_select_pos)));

      let oneHourStruct = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      oneHourStruct = Array.from(oneHourStruct.join('').substring(this.one_hour + 10 - this.base_select_pos, this.one_hour + 10 + (10 - this.base_select_pos)));

      if (this.ten_hour == 2) {
        oneHourStruct = [0, 1, 2, 3, 0, 1, 2, 3, 0, 1, 2, 3];
        oneHourStruct = Array.from(oneHourStruct.join('').substring(this.one_hour + 4 - this.base_select_pos, this.one_hour + 4 + (4 - this.base_select_pos)));
      }

      let tenMinuteStruct = [0, 1, 2, 3, 4, 5, 0, 1, 2, 3, 4, 5, 0, 1, 2, 3, 4, 5];
      tenMinuteStruct = Array.from(tenMinuteStruct.join('').substring(this.ten_minute + 6 - this.base_select_pos, this.ten_minute + 6 + (6 - this.base_select_pos)));

      let oneMinuteStruct = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      oneMinuteStruct = Array.from(oneMinuteStruct.join('').substring(this.one_minute + 10 - this.base_select_pos, this.one_minute + 10 + (10 - this.base_select_pos)));

      const centerArray = Array.from({length: this.base_select_pos+1}, (_, i) => i == this.base_select_pos ? ':' : null);

      const ans = [tenHourStruct, oneHourStruct, centerArray, tenMinuteStruct, oneMinuteStruct];

      // console.log('ans', this.ten_hour, this.one_hour, this.ten_minute, this.one_minute, ans);

      return ans;
    },
  },
  watch: {
    hours_picker() {
      this.refillFromManualInputs();
    },
    minutes_picker() {
      this.refillFromManualInputs();
    },
    ten_hour() {
      this.emitTime();
    },
    one_hour() {
      this.emitTime();
    },
    ten_minute() {
      this.emitTime();
    },
    one_minute() {
      this.emitTime();
    },
    init_time() {
      this.initTime();
    },
  },
  mounted() {
    this.initTime();
  },
  methods: {
    refillFromManualInputs() {
      if (this.hours_picker < 0) this.hours_picker = 0;
      if (this.hours_picker > 23) this.hours_picker = 23;
      if (this.minutes_picker < 0) this.minutes_picker = 0;
      if (this.minutes_picker > 59) this.minutes_picker = 59;

      this.hours_picker = Math.floor(this.hours_picker);
      this.minutes_picker = Math.floor(this.minutes_picker);

      this.ten_hour = Number.parseInt(this.hours_picker / 10);
      this.one_hour = this.hours_picker - (this.ten_hour * 10);
      this.ten_minute = Number.parseInt(this.minutes_picker / 10);
      this.one_minute = this.minutes_picker - (this.ten_minute * 10);
    },
    // isManualValid() {
    //   const hAns = this.$refs.hours_picker.reportValidity();
    //   const mAns = this.$refs.minutes_picker.reportValidity();

    //   return hAns && mAns;
    // },
    initTime() {
      if (this.init_time == null) {
        this.hours_picker = 0;
        this.minutes_picker = 0;

        this.ten_hour = 0;
        this.one_hour = 0;
        this.ten_minute = 0;
        this.one_minute = 0;

        return;
      }

      const hours = String(this.init_time.getHours()).padStart(2, '0');
      const minutes = String(this.init_time.getMinutes()).padStart(2, '0');

      this.ten_hour = Number.parseInt(hours[0]);
      this.one_hour = Number.parseInt(hours[1]);
      this.ten_minute = Number.parseInt(minutes[0]);
      this.one_minute = Number.parseInt(minutes[1]);

      this.hours_picker = this.ten_hour * 10 + this.one_hour;
      this.minutes_picker = this.ten_minute * 10 + this.one_minute;
    },
    emitTime() {
      if (this.init_time != null &&
          this.init_time.getHours() == Number.parseInt(String(this.ten_hour) + String(this.one_hour)) &&
          this.init_time.getMinutes() == Number.parseInt(String(this.ten_minute) + String(this.one_minute))
      ) {
        this.$emit('select_changed', this.init_time);
      } else {
        const newDate = new Date(0);
        newDate.setHours(Number.parseInt(String(this.ten_hour) + String(this.one_hour)));
        newDate.setMinutes(Number.parseInt(String(this.ten_minute) + String(this.one_minute)));
        this.$emit('select_changed', newDate);
      }
    },
    selectElem(rowIdx, colIdx, elem) {
      if (rowIdx == this.base_select_pos || colIdx == 2 || elem == null) {
        return;
      }

      elem = Number.parseInt(elem);

      // console.log('elem', elem, typeof elem);

      if (colIdx == 0) {
        this.ten_hour = elem;

        if (this.ten_hour == 2 && this.one_hour > 3) {
          this.one_hour = 3;
        }
        return;
      }

      if (colIdx == 1) {
        this.one_hour = elem;
        return;
      }

      if (colIdx == 3) {
        this.ten_minute = elem;
        return;
      }

      if (colIdx == 4) {
        this.one_minute = elem;
        return;
      }
    },
  },
});
</script>

<style lang="less">
.up-buttons {
  writing-mode: tb;
}
.bottom-buttons {
  writing-mode: tb;
}

.active-element {
  .set_color(@green);
    border-radius: 24px;
    background-color: @mint;
    min-width: 24px;
    min-height: 24px;
    .flex(column, center, center);
    :hover {
      cursor: pointer;
    }
}

.clickable-element {
  .set_color(@green);
  // border-radius: 24px;
  // background-color: @mint;
  min-width: 24px;
  min-height: 24px;
  .flex(column, center, center);
  :hover {
    cursor: pointer;
  }
}
/* .date_picker{
  user-select: none;
  text-align: center;
  //width: 250px;
  //min-height: 200px;
  max-width: max-content;
}
.day_picker{
  max-width: max-content;
}
.month-picker {
  max-width: max-content;
}
.year-picker {
  max-width: max-content;
}

.top-label {
  color: @light-gray;
}

.buttons-wrapper{

  &-days {
    .flex(row, flex-start, center);
    margin: 0 16px 5px;
  }
  &-months {
    .flex(row, flex-start, center);
    margin: 0 16px 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid @super-light;
  }

  &-years {
    .flex(row, flex-start, center);
    margin: 0 16px 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid @super-light;
  }
}
.label-wrapper{
  margin: auto;
  color: @green;
  .flex(row, center, center);
  :hover {
    cursor: pointer;
  }
}


.picker-table {
  // .flex(column, flex-start, stretch);
  width: 100%;
  height: 100%;
  //max-width: 28px;
}

.week-names{
  color: @light-gray;
}

.day-wrapper{
  width: 28px;
  height: 28px;
}

.no-current-month {
  color: @super-light;
  .flex(column, center, center);
  :hover {
    cursor: pointer;
  }
}

.current-month {
  color: @dark;
  .flex(column, center, center);
  &-inactive {
    .set_color(@stroke-gray);
  }
  &-selected {
    background-color: @mint;
    .set_color(@green);
    border-radius: 50%;
  }
}

.current-month-active:hover {
  .set_color(@green);
  border: 1px solid @super-light;
  border-radius: 50%;
  cursor: pointer
}
.month {
  .flex(column, center, center);
  &-active {
    color: @gray;
    :hover {
      .set_color(@green);
      cursor: pointer;
    }
    p > span:hover {
      padding: 3px 6px;
      border: 1px solid @super-light;
      border-radius: 40px;
    }
  }
  &-inactive {
    color: @gray-opacity-10;
  }
  &-selected {
    .set_color(@green);
    :hover {
      cursor: pointer;
    }
    p > span {
      padding: 3px 6px;
      border-radius: 40px;
      background-color: @mint;
    }
  }
}

.row-wrapper {
  .flex(row, space-around, center);
}

.arrow {
  &-active {
    .set_color(@green);
    border-radius: 24px;
    background-color: @mint;
    min-width: 24px;
    min-height: 24px;
    .flex(column, center, center);
    :hover {
      cursor: pointer
    }
  }
  &-inactive {
    .set_color(@gray);
    border-radius: 24px;
    background-color: @super-light;
    min-width: 24px;
    min-height: 24px;
    .flex(column, center, center);
  }
  .set_color(@green);
  border-radius: 24px;
  background-color: @mint;
  min-width: 24px;
  min-height: 24px;
  .flex(column, center, center);
  :hover {
    cursor: pointer
  }
}
.arrow-active:hover{
  cursor: pointer;
}

.month-wrapper{
  width: 65.33px;
}

@media screen and (max-width: @transition-threshold-1) {
  .date_picker {
    max-width: unset;
  }
  .month-picker {
    max-width: unset;
  }
  .year-picker {
    max-width: unset;
  }
  .picker-table {
    & > * {
      margin-bottom: 6px;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }
} */

</style>
