<template>
  <BasePage :name="'Welcome to your Personal Account!'">
    <div v-if="logoff" style="margin: auto; padding-top:32px; width: fit-content;">
              <LabelComponent label_text="Для доступа нужно авторизоваться"/>
              <br/><br/>
              <ButtonComponent :label="'Авторизация'" href="/login"/>
            </div>
            <div v-else style="margin: 15px;">
                <div class="welcome_text">
                  <LabelComponent  label_text="In the menu on the left side, you can select the Section you are interested in."
                                   label_type="headline/h3/medium 18"/>
                </div>
                <br/>

                <div class="index_one_line">
                  <LabelComponent label_text="MY PROFILE"
                                  label_type="headline/h3/medium 18"/>
                  <LabelComponent label_text="This is the main Section, to manage your Personal Account."
                                  label_type="body/large/regular 16"/>
                  <LabelComponent label_text="Here you can communicate with our Agency via CHAT."
                                  label_type="body/large/regular 16"/>
                </div>
                <br/>

                <div  class="index_one_line">
                  <LabelComponent label_text="QUESTIONNAIRE"
                                  label_type="headline/h3/medium 18"/>
                  <LabelComponent label_text="You can enter here information about yourselves, to make potential Candidates and Challengers impressed by your personality and life achievements."
                                  label_type="body/large/regular 16"/>
                  <LabelComponent label_text="To fill in the Questionnaire, please use English characters only and punctuation marks."
                                  label_type="body/large/regular 16"/>
                </div>
                <br/>

                <div class="index_one_line">
                  <LabelComponent label_text="MY PRESENTATION"
                                  label_type="headline/h3/medium 18"/>
                  <LabelComponent label_text="The most important positions of your Questionnaire will be placed in this Section."
                                  label_type="body/large/regular 16"/>
                  <LabelComponent label_text="This data will be analyzed by our search system, to find within our database, for the beginning, the most suitable five Candidates, matching your requirements."
                                  label_type="body/large/regular 16"/>
                  <LabelComponent label_text="In fact, there will be more candidatures for dating, which you can read about below."
                                  label_type="body/large/regular 16"/>
                </div>
                <br/>

                <div class="index_one_line">
                  <LabelComponent label_text="CANDIDATES"
                                  label_type="headline/h3/medium 18"/>
                  <LabelComponent label_text="Here you can see Presentations of the suggested five Candidates."
                                  label_type="body/large/regular 16"/>
                  <LabelComponent label_text="And to send your Sympathy to the most liked Candidates."
                                  label_type="body/large/regular 16"/>
                </div>
                <br/>

                <div class="index_one_line">
                  <LabelComponent label_text="MY SYMPATHIES"
                                  label_type="headline/h3/medium 18"/>
                  <LabelComponent label_text="The Presentations of those chosen by you, will be relocated to this Section."
                                  label_type="body/large/regular 16"/>
                  <LabelComponent label_text="The Candidates will read your Presentation and reply to you."
                                  label_type="body/large/regular 16"/>
                </div>
                <br/>

                <div class="index_one_line">
                  <LabelComponent label_text="CHALLENGERS"
                                  label_type="headline/h3/medium 18"/>
                  <LabelComponent label_text="These are dozens of other Clients of our Agency, who have been impressed by your Presentation and sent you their Sympathies and Presentations."
                                  label_type="body/large/regular 16"
                                  class="italic-label"/>
                  <LabelComponent label_text="You don't have to pay for their Presentations."
                                  label_type="body/large/regular 16"
                                  style="color: red;"
                                  class="italic-label"/>
                  <LabelComponent label_text="You can study them and chose the most liked Challengers, who dream to get acquainted with you."
                                  label_type="body/large/regular 16"
                                  class="italic-label"/>
                </div>
                <br/>

                <div class="index_one_line">
                  <LabelComponent label_text="MUTUAL SYMPATHIES"
                                  label_type="headline/h3/medium 18"/>
                  <LabelComponent label_text="The Challengers chosen by you, will be relocated to this Section."
                                  label_type="body/large/regular 16"/>
                  <LabelComponent label_text="And they will be informed about your choice."
                                  label_type="body/large/regular 16"/>
                </div>
                <br/>

                <div class="index_one_line">
                  <LabelComponent label_text="MY DATES"
                                  label_type="headline/h3/medium 18"/>
                  <LabelComponent label_text="Here is a decisive moment: the Challengers and chosen by you Candidates, those of them, who have chosen you, will have to find a suitable date and time for online meeting with you."
                                  label_type="body/large/regular 16"/>
                </div>
                <br/>

                <div class="index_one_line">
                  <LabelComponent label_text="MY WALLET"
                                  label_type="headline/h3/medium 18"/>
                  <LabelComponent label_text="Here you keep your small money, to pay for the above-mentioned services."
                                  label_type="body/large/regular 16"/>
                  <LabelComponent label_text="You can fill up the wallet at the very moment of service, by paying through PayPal; just be sure to select your country from the list."
                                  label_type="body/large/regular 16"/>
                </div>
                <br/>

                <div class="index_one_line">
                  <LabelComponent label_text="NOTIFICATIONS"
                                  label_type="headline/h3/medium 18"/>
                  <LabelComponent label_text="All the current information and messages to be saved here, for your control over the situation."
                                  label_type="body/large/regular 16"/>
                  <LabelComponent label_text="Although we are emailing to you notifications about confidential messages and important events / changes in your Personal Account, we recommend you visit your Personal Account regularly, not to miss nothing significant."
                                  label_type="body/large/regular 16"/>
                </div>
                <br/>
            </div>
  </BasePage>
</template>

<script>
import BasePage from '@/baseLayout/BasePage';
import ButtonComponent from '@/units/forms/Button';
import LabelComponent from '@/units/Label';


export default ({
  components: {
    BasePage,
    ButtonComponent,
    LabelComponent,
  },
  data: ()=> ({
  }),
  props: {
  },
  mounted() {
  },
  computed: {
    logoff() {
      // console.log('isLoggedIn', this.$store.getters.isLoggedIn);
      // console.log('isLoggedIn', this.$store.state.auth_settings.user_obj);
      return !this.$store.getters.isLoggedIn;
    },
  },
  methods: {
  },
});
</script>

<style lang="less">
    .index_one_line{
          gap: 5px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: baseline;
    }
    .welcome_text{
      .flex(row, center, center);
    }
    .get-more-button-wraper {
      .flex(row, center, center);
    }
    .italic-label * {
      font-style: italic;
      font-weight: 400;
    }
</style>
