import getTranslations from '@/assets/dataSources/getTranslations';

export default {
  computed: {
    get_lang_switcher_values() {
      // console.log('this.$store.getters', this.$store.getters.getLanguages);

      return this.$store.getters.getLanguages.map((elem) => ({
        name: elem,
        return_value: elem,
      }));
    },
  },
  methods: {
    getUserLangId() {
      const selectLanguage = this.getUserLangName();

      const ans = this.get_lang_switcher_values.findIndex((item)=> item.return_value === selectLanguage);
      // console.log('ansans', ans);
      return ans;
    },
    getUserLangName() {
      let selectLanguage = this.$store.state.base_lang;
      if (selectLanguage == null) {
        selectLanguage = 'en';
      }
      return selectLanguage;
    },
    switchLanguage(obj) {
      if (obj == null) {
        return;
      }

      const lang = obj.return_value;
      this.$store.state.base_lang = lang;
      // localStorage.setItem('userLanguage', lang);
      // this.$store.commit('cur_lang_mut', lang);

      // getTranslations((trans) => this.$store.state.translations[state.base_lang] = trans, state.base_lang);

      const language = this.$store.state.translations[lang];
      if (language == null) {
        // this.$store.commit('set_full_screen_loading', true);
        getTranslations((trans) => this.$store.state.translations[lang] = trans, lang);
      }
    },
  },
};
