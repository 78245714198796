<!-- eslint-disable no-irregular-whitespace -->
<template>
  <BasePage :name="'Анкета'">
    <div class='security_icon'>
      <IconComponent :url="securityLink" type="png" style="min-width: 144px;max-width: 24px;min-height: 34px;max-height: 24px;"/>
  </div>
    <div class="questionnaire">
    <LabelComponent style="color: red;" v-if="this.global_error_text != null" :label_text="this.global_error_text" />
    <LabelComponent label_text="YOUR PERSONAL DATA" label_type="second_big" />

    <div class="first_line_text">
        <LabelComponent label_text="*You can change the Name, Surname and Email at Мy Profile above" />
    </div>
    <div class="first_line_text">
        <LabelComponent label_text="**To fill in the Questionnaire, please use English characters only and punctuation marks" style="margin-bottom: 13px"/>
    </div>
    <!-- <h1>YOUR PERSONAL DATA</h1> -->
    <div class="info_block">

        <div class="form_field__pair">
            <div class="form_field">
                <!-- Имя -->
                <LabelComponent :label_text="this.form_data['name'].label" />
                <InputField label_text="For our Agency only"
                 :set_values="this.form_data['name'].val"
                  input_field_status="disabled"
                   :error_text="this.form_data['name'].error"
                    @input_change="(data) => this.setFormValue('name', data)" />
            </div>

            <div class="form_field">
                <!-- Фамилия -->
                <LabelComponent :label_text="this.form_data['surname'].label" />
                <InputField label_text="For our Agency only"
                 :set_values="this.form_data['surname'].val"
                  input_field_status="disabled"
                   :error_text="this.form_data['surname'].error" @input_change="(data) => this.setFormValue('surname', data)" />
            </div>
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['citizenship'].label" />
            <!-- Гражданство -->
            <DropDownInput :is_filterable="true" :is_multi_select="true" :dropdown_items_init="this.ddDsCountries" label_text="Select from the list" :input_field_status="this.getFieldStatus('citizenship')" :error_text="this.form_data['citizenship'].error" :init_selected_item="this.form_data['citizenship'].val" @input_change="(data) =>
                         this.setFormValue('citizenship', data.map((elem) => elem.return_value))" :always_mobile_view="true" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['date_of_birth'].label" />
            <!-- Дата рождения -->
            <InputField label_text="DD . MM . YYYY" :set_values="this.form_data['date_of_birth'].val" :input_field_status="this.getFieldStatus('date_of_birth')" :error_text="this.form_data['date_of_birth'].error" @input_change="(data) => this.setFormValue('date_of_birth', data)" :min="(new Date()).shiftDateFromCurrent(-90, 0, 0)" :max="(new Date()).shiftDateFromCurrent(-18, 0, 0)" type="date" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['country_of_residence'].label" />
            <!-- Страна проживания -->
            <DropDownInput :is_filterable="true" :is_multi_select="false" :dropdown_items_init="this.ddDsCountries" label_text="Select from the list" :input_field_status="this.getFieldStatus('country_of_residence')" :error_text="this.form_data['country_of_residence'].error" :init_selected_item="this.form_data['country_of_residence'].val" @input_change="(data) =>
                         this.setFormValue('country_of_residence', data.map((elem) => elem.return_value))" :always_mobile_view="false" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['sex'].label" />
            <!-- Пол -->
            <DropDownInput :is_filterable="true" :set_values="this.form_data['sex'].val" :is_multi_select="false" :dropdown_items_init="this.ddSex" label_text="Select from the list" :input_field_status="this.getFieldStatus('sex')" :error_text="this.form_data['sex'].error" :init_selected_item="this.form_data['sex'].val" @input_change="(data) => this.setFormValue('sex', data.map((elem) => elem.return_value))" :always_mobile_view="false" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['ethnic_group'].label" />
            <!-- Этническая принадлежность -->
            <DropDownInput :is_filterable="true" :is_multi_select="false" :dropdown_items_init="this.ddDsEthnicGroup" label_text="Select from the list" :input_field_status="this.getFieldStatus('ethnic_group')" :error_text="this.form_data['ethnic_group'].error" :init_selected_item="this.form_data['ethnic_group'].val" @input_change="(data) =>
                         this.setFormValue('ethnic_group', data.map((elem) => elem.return_value))" :always_mobile_view="false" />
        </div>

        <div class="form_field__pair">
            <div class="form_field">
                <LabelComponent :label_text="this.form_data['height'].label" />
                <!-- Рост (см) -->
                <InputField label_text="cm" :input_field_status="this.getFieldStatus('height')" :error_text="this.form_data['height'].error" :set_values="this.form_data['height'].val" @input_change="(data) => this.setFormValue('height', data)" type="number" min="120" max="220" />
            </div>
            <div class="form_field">
                <LabelComponent :label_text="this.form_data['weight'].label" />
                <!-- Вес(кг) -->
                <InputField label_text="kg" :input_field_status="this.getFieldStatus('weight')" :error_text="this.form_data['weight'].error" :set_values="this.form_data['weight'].val" @input_change="(data) => this.setFormValue('weight', data)" type="number" min="45" max="150" />
            </div>
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['foreign_languages'].label" />
            <!-- Владение языками -->
            <DropDownInput :is_filterable="true" :is_multi_select="true" :set_values="this.form_data['foreign_languages'].val" :dropdown_items_init="this.ddDsLanguages" label_text="Select from the list" :input_field_status="this.getFieldStatus('foreign_languages')" :error_text="this.form_data['foreign_languages'].error" :init_selected_item="this.form_data['foreign_languages'].val" @input_change="(data) =>
                          this.setFormValue('foreign_languages', data.map((elem) => elem.return_value))" :always_mobile_view="true" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['color_of_your_eyes'].label" />
            <!-- Цвет глаз -->
            <InputField label_text="Write a color" :set_values="this.form_data['color_of_your_eyes'].val" :input_field_status="this.getFieldStatus('color_of_your_eyes')" :error_text="this.form_data['color_of_your_eyes'].error" @input_change="(data) => this.setFormValue('color_of_your_eyes', data)" :check_pattern="this.text_input_pattern" :pattern_invalid_message="this.pattern_invalid_message" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['email'].label" />
            <!-- Email -->
            <InputField label_text="For our Agency only" :set_values="this.form_data['email'].val" :input_field_status="this.getFieldStatus('email')" :error_text="this.form_data['email'].error" block_edit="true" style="background-color: #efefefff; border: 0px;" @input_change="(data) => this.setFormValue('email', data)" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['color_of_your_hairs'].label" />
            <!-- Цвет волос -->
            <InputField label_text="Write a color" :set_values="this.form_data['color_of_your_hairs'].val" :input_field_status="this.getFieldStatus('color_of_your_hairs')" :error_text="this.form_data['color_of_your_hairs'].error" @input_change="(data) => this.setFormValue('color_of_your_hairs', data)" :check_pattern="this.text_input_pattern" :pattern_invalid_message="this.pattern_invalid_message" />
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent :label_text="this.form_data['body_art_piercing'].label" />
                <!-- Боди-арт, пирсинг -->
                <CheckBox @input_check="(data) => this.form_data['body_art_piercing'].val=data" :is_toggle="true" label_text_left="No" label_text_right="Yes" :init_value="this.form_data['body_art_piercing'].val" />
            </div>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
        </div>

    </div>

    <br />
    <LabelComponent label_text="YOUR FIELD OF ACTIVITY" label_type="second_big" />

    <!-- <h1>YOUR FIELD OF ACTIVITY</h1> -->

    <div class="info_block">
        <div class="form_field">
            <LabelComponent :label_text="this.form_data['education'].label" />
            <!-- Образование -->
            <DropDownInput :is_filterable="false" :is_multi_select="false" :dropdown_items_init="this.ddDsEducation" label_text="Select from the list" :input_field_status="this.getFieldStatus('education')" :error_text="this.form_data['education'].error" :init_selected_item="this.form_data['education'].val" @input_change="(data) => this.setFormValue('education', data.map((elem) => elem.return_value))" :always_mobile_view="false" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['profession'].label" />
            <!-- Профессия -->
            <DropDownInput :is_filterable="false" :is_multi_select="false" :dropdown_items_init="this.ddDsProfession" label_text="Select from the list" :input_field_status="this.getFieldStatus('profession')" :error_text="this.form_data['profession'].error" :init_selected_item="this.form_data['profession'].val" @input_change="(data) => this.setFormValue('profession', data.map((elem) => elem.return_value))" :always_mobile_view="false" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['place_of_work'].label" />
            <!-- Место работы -->
            <InputField label_text="Place of work" :set_values="this.form_data['place_of_work'].val" :input_field_status="this.getFieldStatus('place_of_work')" :error_text="this.form_data['place_of_work'].error" @input_change="(data) => this.setFormValue('place_of_work', data)" :check_pattern="this.text_input_pattern" :pattern_invalid_message="this.pattern_invalid_message" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['position'].label" />
            <!-- Должность -->
            <InputField label_text="Write a few words" :set_values="this.form_data['position'].val" :input_field_status="this.getFieldStatus('position')" :error_text="this.form_data['position'].error" @input_change="(data) => this.setFormValue('position', data)" :check_pattern="this.text_input_pattern" :pattern_invalid_message="this.pattern_invalid_message" />
        </div>
    </div>

    <br />
    <LabelComponent label_text="YOUR FAMILY" label_type="second_big" />

    <!-- <h1>YOUR FAMILY</h1> -->
    <div class="info_block">
        <div class="form_field">
            <LabelComponent :label_text="this.form_data['family_status'].label" />
            <!-- Семейное положение -->
            <DropDownInput :is_filterable="false" :is_multi_select="false" :dropdown_items_init="this.ddFamily_status" label_text="Select from the list" :input_field_status="this.getFieldStatus('family_status')" :error_text="this.form_data['family_status'].error" :init_selected_item="this.form_data['family_status'].val" @input_change="(data) => this.setFormValue('family_status', data.map((elem) => elem.return_value))" :always_mobile_view="false" />
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent label_text="Do you have Children?" />
                <!-- Есть ли у вас дети -->
                <CheckBox @input_check="(data) => this.toggleRequireField('child_set', data)" :is_toggle="true" :init_value="this.form_data['child_set'].required" label_text_left="No" label_text_right="Yes" />
            </div>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />

            <!-- Если да, пол и возраст детей -->
            <InlineFormField v-if="this.form_data['child_set'].required" :set_values="this.form_data['child_set'].val" :extra="1" :formInitStruct="this.form_data['child_set']" @input_change="(data) => this.setFormValue('child_set', data)" />
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent :label_text="this.form_data['more_children'].label" />
                <!-- Хотели бы Вы иметь ещё детей -->
                <CheckBox @input_check="(data) => this.form_data['more_children'].val = data" :init_value="this.form_data['more_children'].val" :is_toggle="true" label_text_left="No" label_text_right="Yes" />
            </div>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
        </div>
        <div class="form_field"></div>
    </div>

    <br />
    <LabelComponent label_text="ABOUT YOU" label_type="second_big" />

    <!-- <h1>ABOUT YOU</h1> -->
    <div class="info_block">

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['personality'].label" />
            <!-- Ваш тип личности -->
            <DropDownInput :is_filterable="false" :is_multi_select="false" :dropdown_items_init="this.ddPersonality" label_text="Select from the list" :input_field_status="this.getFieldStatus('personality')" :error_text="this.form_data['personality'].error" :init_selected_item="this.form_data['personality'].val" @input_change="(data) =>
                              this.setFormValue('personality', data.map((elem) => elem.return_value))" :always_mobile_view="false" />
        </div>


        <div class="form_field">
            <LabelComponent :label_text="this.form_data['hobbies'].label" />
            <!-- Ваши увлечения -->
            <InputField label_text="Write a few words" :set_values="this.form_data['hobbies'].val" :input_field_status="this.getFieldStatus('hobbies')" :error_text="this.form_data['hobbies'].error" @input_change="(data) => this.setFormValue('hobbies', data)" :check_pattern="this.text_input_pattern" :pattern_invalid_message="this.pattern_invalid_message" />
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent label_text="Do you like to travel?" />
                <!-- Любите ли Вы путешествовать -->
                <CheckBox @input_check="(data) => this.form_data['like_to_travel'].val=data"
                  :init_value="this.form_data['like_to_travel'].val" :is_toggle="true" label_text_left="No" label_text_right="Yes" />
            </div>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
        </div>
              <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent :label_text="this.form_data['driving_licence'].label" />
                <!-- Имеете ли Вы водительские права -->
                <CheckBox @input_check="(data) => this.form_data['driving_licence'].val=data" :init_value="this.form_data['driving_licence'].val" :is_toggle="true" label_text_left="No" label_text_right="Yes" />
            </div>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent label_text="Do you have Pets?" />
                <!-- Есть ли у Вас домашние животные -->
                <CheckBox @input_check="(data) => this.form_data['pets'].val=data"
                  :init_value="this.form_data['pets'].val" :is_toggle="true" label_text_left="No" label_text_right="Yes" />
            </div>
            <Separator separatorType = "horizontal" class = "user_info__separator_quest"/>
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line ">
                <LabelComponent label_text="Do you play Sports?" />
                <!-- Занимаетесь ли Вы спортом -->
                <CheckBox @input_check="(data) => this.form_data['play_sport'].val=data" :init_value="this.form_data['play_sport'].val" :is_toggle="true" label_text_left="No" label_text_right="Yes" />
            </div>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent :label_text="this.form_data['like_to_cook'].label" />
                <!-- Любите ли вы готовить -->
                <CheckBox @input_check="(data) => this.form_data['like_to_cook'].val=data" :init_value="this.form_data['like_to_cook'].val" :is_toggle="true" label_text_left="No" label_text_right="Yes" />
            </div>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent :label_text="this.form_data['smoke'].label" />
                <!-- Курите ли Вы -->
                <CheckBox @input_check="(data) => this.form_data['smoke'].val=data" :init_value="this.form_data['smoke'].val" :is_toggle="true" label_text_left="No" label_text_right="Yes" />
            </div>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['other_people_smoking'].label" />
            <DropDownInput :is_filterable="false" :is_multi_select="false" :dropdown_items_init="this.ddOtherPeopleSmoking" label_text="Select from the list" :input_field_status="this.getFieldStatus('other_people_smoking')" :error_text="this.form_data['other_people_smoking'].error" :init_selected_item="this.form_data['other_people_smoking'].val" @input_change="(data) =>
                              this.setFormValue('other_people_smoking', data.map((elem) => elem.return_value))" :always_mobile_view="false" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['alcohol'].label" />
            <!-- Ваше отношение к алкоголю -->
            <DropDownInput :is_filterable="false" :is_multi_select="false" :dropdown_items_init="this.ddAlcohol" label_text="Select from the list" :input_field_status="this.getFieldStatus('alcohol')" :error_text="this.form_data['alcohol'].error" :init_selected_item="this.form_data['alcohol'].val" @input_change="(data) => this.setFormValue('alcohol', data.map((elem) => elem.return_value))" :always_mobile_view="false" />
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent label_text="Does the Place of Residence of your potential chosen one matter to you?" />
                <!-- Имеет ли для Вас значение место проживания Вашего потенциального избранника/избранницы -->
                <CheckBox @input_check="(data) => this.toggleRequireField('does_place_matterr', data)"
                          :init_value="this.form_data['does_place_matterr'].required"
                          :is_toggle="true"
                          label_text_left="No" label_text_right="Yes" />
            </div>
            <template v-if="this.form_data['does_place_matterr'].required">
                          <!-- Если да, то какое -->
                <DropDownInput :is_filterable="true"
                               :is_multi_select="true"
                               :dropdown_items_init="this.ddDsDoesPlaceMatter"
                               label_text="Select from the list"
                               :input_field_status="this.getFieldStatus('does_place_matterr')"
                               :error_text="this.form_data['does_place_matterr'].error"
                               :init_selected_item="this.form_data['does_place_matterr'].val"
                               @input_change="(data) => this.setFormValue('does_place_matterr', data.map((elem) => elem.return_value))"
                               :always_mobile_view="true" />
            </template>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['ideas_about_chosen'].label" />
             <DropDownInput :is_filterable="false" :is_multi_select="false" :dropdown_items_init="this.ddIdeasAboutChosen" label_text="Select from the list" :input_field_status="this.getFieldStatus('ideas_about_chosen')" :error_text="this.form_data['ideas_about_chosen'].error" :init_selected_item="this.form_data['ideas_about_chosen'].val" @input_change="(data) => this.setFormValue('ideas_about_chosen', data.map((elem) => elem.return_value))" :always_mobile_view="false" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['vacation_spot'].label" />
            <!-- Предпочтительное место отдыха -->
            <DropDownInput :is_filterable="false" :is_multi_select="false" :dropdown_items_init="this.ddVacationSpot" label_text="Select an appropriate option" :input_field_status="this.getFieldStatus('vacation_spot')" :error_text="this.form_data['vacation_spot'].error" :init_selected_item="this.form_data['vacation_spot'].val" @input_change="(data) => this.setFormValue('vacation_spot', data.map((elem) => elem.return_value))" :always_mobile_view="false" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['perfect_vacation'].label" />
            <!-- Мой идеальный отпуск -->
            <DropDownInput :is_filterable="false" :is_multi_select="false" :dropdown_items_init="this.ddPerfectVacation" label_text="Select an appropriate option" :input_field_status="this.getFieldStatus('perfect_vacation')" :error_text="this.form_data['perfect_vacation'].error" :init_selected_item="this.form_data['perfect_vacation'].val" @input_change="(data) => this.setFormValue('perfect_vacation', data.map((elem) => elem.return_value))" :always_mobile_view="false" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['music'].label" />
            <!-- Ваши музыкальные предпочтения -->
            <InputField label_text="Your musical preferences" :set_values="this.form_data['music'].val" :input_field_status="this.getFieldStatus('music')" :error_text="this.form_data['music'].error" @input_change="(data) => this.setFormValue('music', data)" :check_pattern="this.text_input_pattern" :pattern_invalid_message="this.pattern_invalid_message" />
        </div>

        <div class="form_field">
            <LabelComponent :label_text="this.form_data['movies'].label" />
            <!-- Предпочтения в кино -->
            <InputField label_text="Your movie preferences" :set_values="this.form_data['movies'].val" :input_field_status="this.getFieldStatus('movies')" :error_text="this.form_data['movies'].error" @input_change="(data) => this.setFormValue('movies', data)" :check_pattern="this.text_input_pattern" :pattern_invalid_message="this.pattern_invalid_message" />
        </div>

    </div>
    <br />
    <LabelComponent label_text="YOUR CHOSEN ONE" label_type="second_big" />
    <!-- <h1> YOUR CHOSEN ONE </h1> -->
    <div class="info_block">
        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent label_text="Does the Education matter?" />
                <!-- Имеет ли значение Образование избранника/избранницы -->
                <CheckBox @input_check="(data) => this.toggleRequireField('preferred_education', data)"
                          :init_value="this.form_data['preferred_education'].required"
                          :is_toggle="true" label_text_left="No" label_text_right="Yes" />
            </div>

            <!-- Если да, то какое -->
            <template v-if="this.form_data['preferred_education'].required">
                <LabelComponent :label_text="this.form_data['preferred_education'].label" />
                <!-- Образование -->
                <DropDownInput :is_filterable="false" :is_multi_select="true"
                               :dropdown_items_init="this.ddDsEducation" label_text="Select from the list"
                               :input_field_status="this.getFieldStatus('preferred_education')"
                               :error_text="this.form_data['preferred_education'].error"
                               :init_selected_item="this.form_data['preferred_education'].val"
                               @input_change="(data) => this.setFormValue('preferred_education', data.map((elem) => elem.return_value))"
                               :always_mobile_view="true" />
            </template>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
        </div>

        <div class="form_field__pair">
            <div class="form_field">
                <LabelComponent label_text="Age from" />
                <!-- Возраст (от и до) -->
                <InputField :label_text="this.form_data['age_from'].label" :set_values="this.form_data['age_from'].val" :input_field_status="this.getFieldStatus('age_from')" :error_text="this.form_data['age_from'].error" @input_change="(data) => this.setFormValue('age_from', data)" type="number" min="18" max="80" />
            </div>
            <div class="form_field">
                <LabelComponent label_text="Age to" />
                <InputField :label_text="this.form_data['age_to'].label" :set_values="this.form_data['age_to'].val" :input_field_status="this.getFieldStatus('age_to')" :error_text="this.form_data['age_to'].error" @input_change="(data) => this.setFormValue('age_to', data)" type="number" min="18" max="80" />
            </div>
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent label_text="Does the Profession matter?" />
                <!-- Имеет ли значение Профессия избранника/избранницы -->
                <CheckBox @input_check="(data) => this.toggleRequireField('preferred_profession', data)" :init_value="this.form_data['preferred_profession'].required" :is_toggle="true" label_text_left="No" label_text_right="Yes" />
            </div>

            <!-- Если да, то какое -->
            <template v-if="this.form_data['preferred_profession'].required">
                <LabelComponent :label_text="this.form_data['preferred_profession'].label" />
                <!-- Профессия -->
                <DropDownInput :is_filterable="false" :is_multi_select="true" :dropdown_items_init="this.ddDsProfession" label_text="Select from the list" :input_field_status="this.getFieldStatus('preferred_profession')" :error_text="this.form_data['preferred_profession'].error" :init_selected_item="this.form_data['preferred_profession'].val" @input_change="(data) => this.setFormValue('preferred_profession', data.map((elem) => elem.return_value))" :always_mobile_view="true" />
            </template>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
        </div>

        <div class="form_field__pair">
            <div class="form_field">
                <LabelComponent label_text="Height from" />
                <!-- Рост (от и до) -->
                <InputField label_text="cm" :set_values="this.form_data['height_from'].val" :input_field_status="this.getFieldStatus('height_from')" :error_text="this.form_data['height_from'].error" @input_change="(data) => this.setFormValue('height_from', data)" type="number" min="120" max="220" />
            </div>
            <div class="form_field">
                <LabelComponent label_text="Height to" />
                <InputField label_text="cm" :set_values="this.form_data['height_to'].val" :input_field_status="this.getFieldStatus('height_to')" :error_text="this.form_data['height_to'].error" @input_change="(data) => this.setFormValue('height_to', data)" type="number" min="120" max="220" />
            </div>
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent label_text="Does the Language matter?" />
                <!-- Имеет ли значение Владение языками избранника/избранницы -->
                <CheckBox @input_check="(data) => this.toggleRequireField('preferred_foreign_languages', data)" :init_value="this.form_data['preferred_foreign_languages'].required" :is_toggle="true" label_text_left="No" label_text_right="Yes" />
            </div>
            <!-- Если да, то какое -->
            <template v-if="this.form_data['preferred_foreign_languages'].required">
                <LabelComponent :label_text="this.form_data['preferred_foreign_languages'].label" />
                <!-- Владение языками -->
                <DropDownInput :is_filterable="true" :is_multi_select="true" :dropdown_items_init="this.ddDsLanguages" label_text="Select from the list" :input_field_status="this.getFieldStatus('preferred_foreign_languages')" :error_text="this.form_data['preferred_foreign_languages'].error" :init_selected_item="this.form_data['preferred_foreign_languages'].val" @input_change="(data) => this.setFormValue('preferred_foreign_languages', data.map((elem) => elem.return_value))" :always_mobile_view="true" />
            </template>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
        </div>

        <div class="form_field__pair">
            <div class="form_field">
                <LabelComponent label_text="Weight from" />
                <!-- Вес (от и до) -->
                <InputField label_text="kg" :set_values="this.form_data['weight_from'].val" :input_field_status="this.getFieldStatus('weight_from')" :error_text="this.form_data['weight_from'].error" @input_change="(data) => this.setFormValue('weight_from', data)" type="number" min="45" max="150" />
            </div>
            <div class="form_field">
                <LabelComponent label_text="Weight to" />
                <InputField label_text="kg" :set_values="this.form_data['weight_to'].val" :input_field_status="this.getFieldStatus('weight_to')" :error_text="this.form_data['weight_to'].error" @input_change="(data) => this.setFormValue('weight_to', data)" type="number" min="45" max="150" />
            </div>
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent label_text="Does the Citizenship matter?" />
                <!-- Имеет ли значение гражданство избранника/избранницы -->
                <CheckBox @input_check="(data) => this.toggleRequireField('preferred_citizenship', data)" :init_value="this.form_data['preferred_citizenship'].required" :is_toggle="true" label_text_left="No" label_text_right="Yes" />
            </div>
            <!-- Если да, то какое -->
            <template v-if="this.form_data['preferred_citizenship'].required">
                <LabelComponent :label_text="this.form_data['preferred_citizenship'].label" />
                <!-- Гражданство избранника/избранницы -->
                <DropDownInput :is_filterable="true" :is_multi_select="true" :dropdown_items_init="this.ddDsCountries" label_text="Select from the list" :input_field_status="this.getFieldStatus('preferred_citizenship')" :error_text="this.form_data['preferred_citizenship'].error" :init_selected_item="this.form_data['preferred_citizenship'].val" @input_change="(data) => this.setFormValue('preferred_citizenship', data.map((elem) => elem.return_value))" :always_mobile_view="true" />
            </template>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent :label_text="this.form_data['accept_have_children'].label" />
                <!-- Допускаете ли, что у него/нее могут быть дети -->
                <CheckBox @input_check="(data) => this.form_data['accept_have_children'].val=data" :init_value="this.form_data['accept_have_children'].val" :is_toggle="true" label_text_left="No" label_text_right="Yes" />
            </div>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent label_text="Does the Country of Residence matter?" />
                <!-- Имеет ли значение Страна проживания избранника/избранницы -->
                <CheckBox @input_check="(data) => this.toggleRequireField('preferred_country_of_residence', data)" :init_value="this.form_data['preferred_country_of_residence'].required" :is_toggle="true" label_text_left="No" label_text_right="Yes" />
            </div>
            <!-- Если да, то какое -->
            <template v-if="this.form_data['preferred_country_of_residence'].required">
                <LabelComponent :label_text="this.form_data['preferred_country_of_residence'].label" />
                <!-- Страна проживания избранника/избранницы -->
                <DropDownInput :is_filterable="true" :is_multi_select="true" :dropdown_items_init="this.ddDsCountries" label_text="Select from the list" :input_field_status="this.getFieldStatus('preferred_country_of_residence')" :error_text="this.form_data['preferred_country_of_residence'].error" :init_selected_item="this.form_data['preferred_country_of_residence'].val" @input_change="(data) => this.setFormValue('preferred_country_of_residence', data.map((elem) => elem.return_value))" :always_mobile_view="true" />
            </template>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
            <div class="is_toogle_one_line">
                <LabelComponent label_text="Does the Ethnic group matter?" />
                <!-- Имеет ли значение Этническая принадлежность избранника/избранницы -->
                <CheckBox @input_check="(data) => this.toggleRequireField('preferred_ethnic_group', data)" :init_value="this.form_data['preferred_ethnic_group'].required" :is_toggle="true" label_text_left="No" label_text_right="Yes" />
            </div>
            <!-- Если да, то какое -->
            <template v-if="this.form_data['preferred_ethnic_group'].required">
                <LabelComponent :label_text="this.form_data['ethnic_group'].label" />
                <!-- Этническая принадлежность избранника/избранницы -->
                <DropDownInput :is_filterable="false" :is_multi_select="true" :dropdown_items_init="this.ddDsEthnicGroup" label_text="Select from the list" :input_field_status="this.getFieldStatus('preferred_ethnic_group')" :error_text="this.form_data['preferred_ethnic_group'].error" :init_selected_item="this.form_data['preferred_ethnic_group'].val" @input_change="(data) => this.setFormValue('preferred_ethnic_group', data.map((elem) => elem.return_value))" :always_mobile_view="true" />
            </template>
            <Separator separatorType="horizontal" class="user_info__separator_quest" />
        </div>
        <div class="form_field"></div>
    </div>
    <br />
    <LabelComponent label_text="ATTACH  PHOTO/AUDIO/VIDEO MATERIALS" label_type="second_big" />

    <!-- <h1>ATTACH A PHOTO/AUDIO/VIDEO MATERIALS</h1> -->

    <div class="info_block">
        <div class="form_field">
            <LabelComponent label_text="Select first photo" />
            <InputField label_text="Select first photo"
            :set_values="this.form_data['media_photo1'].val"
            :input_field_status="this.getFieldStatus('media_photo1')"
            :error_text="this.form_data['media_photo1'].error"
            @input_change="(data) => this.setFormValue('media_photo1', data)"
            accept_files_type="images" type="file" />
        </div>

        <div class="form_field">
            <LabelComponent label_text="Select video*" />
            <InputField label_text="Select video"
            :set_values="this.form_data['media_video'].val"
            :input_field_status="this.getFieldStatus('media_video')"
            :error_text="this.form_data['media_video'].error" @input_change="(data) => this.setFormValue('media_video', data)" accept_files_type="video" type="file" />
        </div>

        <div class="form_field">
            <LabelComponent label_text="Select second photo" />
            <InputField label_text="Select second photo" :set_values="this.form_data['media_photo2'].val" :input_field_status="this.getFieldStatus('media_photo2')" :error_text="this.form_data['media_photo2'].error" @input_change="(data) => this.setFormValue('media_photo2', data)" accept_files_type="images" type="file" />
        </div>

        <div class="form_field">
            <LabelComponent label_text="Select audio*" />
            <InputField label_text="Select audio" :set_values="this.form_data['media_audio'].val" :input_field_status="this.getFieldStatus('media_audio')" :error_text="this.form_data['media_audio'].error" @input_change="(data) => this.setFormValue('media_audio', data)" accept_files_type="audio" type="file" />
        </div>

        <div class="form_field">
            <LabelComponent label_text="Select third photo" />
            <InputField label_text="Select third photo" :set_values="this.form_data['media_photo3'].val" :input_field_status="this.getFieldStatus('media_photo3')" :error_text="this.form_data['media_photo3'].error" @input_change="(data) => this.setFormValue('media_photo3', data)" accept_files_type="images" type="file" />
        </div>
        <div class="form_field">
            <div class="form_field"></div>
            <LabelComponent label_text="* When recording audio or video messages, it is not necessary to give your first and last name. You can introduce yourself later, during the online video date." />

        </div>

    </div>
    <div class="info_block">
        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator" />
            <div class="date_of_change_Q">
                <LabelComponent label_text="Date of completion or latest amendment" />
            <div class="is_toogle_one_line_D">
                <LabelComponent :label_text="this.formatedDate(this.form_data['filled_at'].val)" class="text_red" />
                <LabelComponent label_text="SWISS TIME" class="text_red" />
            </div>
            </div>
            <Separator separatorType="horizontal" class="user_info__separator" />
        </div>

        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator" />
            <LabelComponent label_text="Dear Clients of the International Marriage Agency! Your personal data is available exclusively to the Agency's employees." style="line-height: 1.5" />
            <Separator separatorType="horizontal" class="user_info__separator" />
        </div>

    </div>

    <div class="info_block">
        <div class="form_field">
            <Separator separatorType="horizontal" class="user_info__separator" />
            <div class="is_toogle_one_line">
                <LabelComponent :label_text="this.form_data['accuracy'].label" />
                <!-- <ButtonComponent label="I confirm the accuracy of the data"
                              labelType = 'button/large/medium 18'
                              elementType="default" href="#your_privacy_policy_document"
                              elementSize='big'/> -->
                <CheckBox :init_value="this.form_data['accuracy'].val" :check_box_field_status="this.getFieldStatus('accuracy')" :error_text="this.form_data['accuracy'].error" @input_check="(data) => this.setFormValue('accuracy', data)" />
            </div>
            <Separator separatorType="horizontal" class="user_info__separator" />
        </div>

        <div  v-if="!this.form_data['__accept_offers__'].val" class="policy-field form_field">
            <Separator separatorType="horizontal" class="user_info__separator" />
            <div class="is_toogle_one_line">
                <LabelComponent label_text="For full registration at the Agency, you will need to accept a " style="line-height: 1.5" />
                <ButtonComponent label="PUBLIC CONTRACT OFFER" elementType="default" href="/documents/ima-public-contract-en.pdf" :href_in_new_tab="true" style="line-height: 1.5" />
                <CheckBox :init_value="this.form_data['full_registration'].val" :check_box_field_status="this.getFieldStatus('full_registration')" :error_text="this.form_data['full_registration'].error" @input_check="(data) => this.setFormValue('full_registration', data)" />
            </div>
            <Separator separatorType="horizontal" class="user_info__separator" />
        </div>
    </div>

  <br />
  <br />
  <div>
    <div v-if="this.form_data['__accept_offers__'].val" class="create-pres-button">
        <ButtonComponent class="questionnaire-frame__button1" label_type="button/large/medium 18" elementSize="big" elementType="default-bg" @click="this.sendAnketatoServer" label="ОБНОВИТЬ ПРЕЗЕНТАЦИЮ" />
    </div>
    <div v-else class="create-pres-button">
        <ButtonComponent class="questionnaire-frame__button1" label_type="button/large/medium 18" elementSize="big" elementType="default-bg" @click="this.sendAnketatoServer" label="СОЗДАТЬ ПРЕЗЕНТАЦИЮ" />
    </div>
  </div>
    <ModalView v-if="this.global_success_text != null" :open_level="1" type_modal="internal" position="fixed" caption=" " @click_close="this.global_success_text = null">
    <LabelComponent :label_text="this.global_success_text" label_type="body/large/regular 14" class="saved_text" />
    <br /><br />
    </ModalView>
</div>
  </BasePage>
</template>

<script>
import BasePage from '@/baseLayout/BasePage';
import LabelComponent from '@/units/Label';
import IconComponent from '@/units/Icon';
import ButtonComponent from '@/units/forms/Button';
import InputField from '@/units/forms/InputField';
import ModalView from '@/layoutTools/ModalView';
import DropDownInput from '@/units/forms/DropDownInput';
import CheckBox from '@/units/forms/CheckBox';
import InlineFormField from '@/units/forms/InlineFormField';
import Separator from '@/units/forms/Separator';

import getRefList from '@/assets/dataSources/getRefList';
import sendFormData from '@/assets/dataSources/sendFormData';
import getFormData from '@/assets/dataSources/getFormData';


export default ({
  components: {
    BasePage,
    LabelComponent,
    IconComponent,
    ButtonComponent,
    InputField,
    DropDownInput,
    CheckBox,
    ModalView,
    Separator,
    InlineFormField,
  },
  props: {
    // path: {
    //   type: String,
    //   required: true,
    // },
  },
  data: ()=> ({
    save_to_localStorage: false,
    fieldsToExcludeFromLocalStorage: ['surname', 'name', 'media_photo1', 'media_photo2', 'media_photo3', 'media_video', 'media_audio', 'accuracy'], // Список полей, которые не сохраняем
    text_input_pattern: '[a-zA-Zа-яА-Я\\-\\s,\\.]+',
    pattern_invalid_message: 'Можно вводить только буквы алфавита, и символы: " ", ",", ".", "-", "’"',
    global_success_text: undefined,
    global_error_text: undefined,
    form_data: {
      surname: {label: '*Last Name (In English)', error: null, val: null, required: true},
      name: {label: '*First Name (In English)', error: null, val: null, required: true},
      citizenship: {label: 'Citizenship', error: null, val: [], required: true, itype: 'dropdown'},
      country_of_residence: {label: 'Country of Residence', error: null, val: [], required: false, itype: 'dropdown'},
      sex: {label: 'Gender', error: null, val: [], required: false, itype: 'dropdown'},
      email: {label: '*Email', error: null, val: null, itype: 'email', required: false},
      date_of_birth: {label: 'Date of Birth', error: null, val: (new Date()).shiftDateFromCurrent(-18, 0, 0), required: false},
      messenger: {label: 'Address of your Messenger', error: null, val: null, required: false},
      height: {label: 'Height', error: null, val: null, required: false},
      weight: {label: 'Weight', error: null, val: null, required: false},
      color_of_your_eyes: {label: 'Color of your Eyes', error: null, val: null, required: false},
      color_of_your_hairs: {label: 'Your Hair Color', error: null, val: null, required: false},
      body_art_piercing: {label: 'Body art, Piercing', error: null, val: null, required: false},
      ethnic_group: {label: 'Ethnic Group', error: null, val: [], required: false, itype: 'dropdown'},
      education: {label: 'Education', error: null, val: [], required: true, itype: 'dropdown'},
      profession: {label: 'Profession', error: null, val: [], required: false, itype: 'dropdown'},
      additional_education: {label: 'Additional education', error: null, val: null, required: false},
      other_skills: {label: 'Other Skills ', error: null, val: null, required: false},
      place_of_work: {label: 'Place of Work', error: null, val: null, required: false},
      position: {label: 'Position', error: null, val: null, required: false},
      foreign_languages: {label: 'Language Skills', error: null, val: [], required: false, itype: 'dropdown'},
      family_status: {label: 'Family Status', error: null, val: [], required: false, itype: 'dropdown'},
      like_to_cook: {label: 'Do you like to cook?', error: null, val: null, required: false},
      character_traits: {label: 'Your Character Traits', error: null, val: null, required: false},
      personality: {label: 'Your Personality type', error: null, val: [], required: false, itype: 'dropdown'},
      hobbies: {label: 'Your Hobbies', error: null, val: null, required: false},
      how_often_away_or_travel: {label: 'How often are you away?', error: null, val: null, required: false},
      play_sport: {label: 'Which one and how often', error: null, val: null, required: false},
      smoke: {label: 'Do you smoke?', error: null, val: null, required: false},
      other_people_smoking: {label: 'How do you feel about other people smoking?', error: null, val: [], required: false, itype: 'dropdown'},
      alcohol: {label: 'What is your attitude towards Alcohol?', error: null, val: [], required: false, itype: 'dropdown'},
      pets: {label: 'Do you have Pets', error: null, val: null, required: false},
      driving_licence: {label: 'Do you have a Driving License?', error: null, val: null, required: false},
      own_car: {label: 'Do you own a Car?', error: null, val: null, required: false},
      like_to_travel: {label: 'What Countries have you already visited?', error: null, val: null, required: false},
      life_values: {label: 'Your life Values', error: null, val: null, required: false},
      vacation_spot: {label: 'Preferred Vacation Spot', error: null, val: [], required: false, itype: 'dropdown'},
      perfect_vacation: {label: 'My perfect Vacation', error: null, val: [], required: false, itype: 'dropdown'},
      music: {label: 'Your musical Preferences', error: null, val: null, required: false},
      movies: {label: 'Your movie Preferences', error: null, val: null, required: false},
      character_of_chosen: {label: 'Character Traits of your chosen one', error: null, val: null, required: false},
      ideas_about_chosen: {label: 'What are your Ideas about an ideal Future with your chosen one?', error: null, val: [], required: false, itype: 'dropdown'},
      future_family: {label: 'How do you see your future family Relationships?', error: null, val: null, required: false},
      does_place_matterr: {label: 'Does the Place of Residence of your potential chosen one matter to you?', error: null, val: [], required: false},
      accuracy: {label: 'I confirm the accuracy of the data', error: null, val: false, required: true},
      full_registration: {label: 'For full registration at the Agency, you will need to accept a', error: null, val: false, required: true},
      // privacy_policy_agreed: {label: 'I agree with the privacy policy', error: null, val: false},
      filled_at: {label: 'Date of completion or latest amendment', error: null, val: null, required: false},

      child_set: {
        label: 'Children',
        error: null,
        val: null,
        itype: 'inline',
        content_form: {
          children_age: {label: 'Child age', error: null, val: null, itype: 'number', min: 0, max: 90},
          children_gender: {label: 'Child gender', error: null, val: null, itype: 'dropdown', ddVals: []},
          children_live_together: {label: 'Do your children live with you?', error: null, val: false, itype: 'boolean'},
        },
      },
      accept_have_children: {label: 'Do you accept that he/she may have Children?', error: null, val: null, required: false},

      more_children: {label: 'Would you like to have more Children?', error: null, val: false, required: false},

      preferred_education: {label: 'Education', error: null, val: [], required: false},
      preferred_profession: {label: 'Profession', error: null, val: [], required: false},
      age_from: {label: 'Age from', error: null, val: null, required: false},
      age_to: {label: 'Age to', error: null, val: null, required: false},
      height_from: {label: 'Height from', error: null, val: null, required: false},
      height_to: {label: 'Height to', error: null, val: null, required: false},
      weight_from: {label: 'Weight from', error: null, val: null, required: false},
      weight_to: {label: 'Weight to', error: null, val: null, required: false},
      preferred_citizenship: {label: 'Citizenship', error: null, val: [], required: false},
      preferred_country_of_residence: {label: 'Country of Residence', error: null, val: [], required: false},
      preferred_ethnic_group: {label: 'Preffered Ethnic Group', error: null, val: [], required: false},
      preferred_foreign_languages: {label: 'Language Skills', error: null, val: [], required: false},

      media_photo1: {label: 'Choose file', error: null, val: null, required: true},
      media_photo2: {label: 'Choose file', error: null, val: null, required: true},
      media_photo3: {label: 'Choose file', error: null, val: null, required: true},
      media_video: {label: 'Choose file (optional)', error: null, val: null, required: false},
      media_audio: {label: 'Choose file (optional)', error: null, val: null, required: false},

      __presentation_payed__: {label: null, error: null, val: false, required: false},
      __accept_offers__: {label: null, error: null, val: false, required: false},
    },

    countries: [],
    profession: [],
    education: [],
    languages: [],
    family_status: [],
    sex: [],
    personality: [],
    vacation_spot: [],
    perfect_vacation: [],
    ideas_about_chosen: [],
    other_people_smoking: [],
    alcohol: [],
    does_place_matterr: [],
    ethnic_group: [],
  }),
  mounted() {
    window.acceptableElements.push(this.sendAnketatoServer);


    this.updateRefs();
    Object.keys(this.form_data).forEach((field) => {
      const savedFieldValue = JSON.parse(localStorage.getItem(field));
      if (savedFieldValue !== null) {
        this.form_data[field].val = savedFieldValue;
      }
    });
  },
  unmounted() {
    const index = window.acceptableElements.indexOf(this.sendAnketatoServer);
    if (index > -1) {
      window.acceptableElements.splice(index, 1);
    }
  },
  computed: {
    baseLang() {
      // Get the current language from the Vuex store
      return this.$store.state.base_lang;
    },
    securityLink() {
      // Return the corresponding link based on the language
      return this.baseLang === 'en' ? '/icons/lock en.png' : '/icons/lock 1.png';
    },
    ddDsCountries() {
      return this.countries.filter((outVal) => outVal.id != 1).map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    ddDsProfession() {
      return this.profession.filter((outVal) => outVal.id != 1).map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    ddDsEducation() {
      return this.education.filter((outVal) => outVal.id != 1).map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    ddDsLanguages() {
      return this.languages.filter((outVal) => outVal.id != 1).map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    ddDsEthnicGroup() {
      return this.ethnic_group.filter((outVal) => outVal.id != 1).map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    ddFamily_status() {
      return this.family_status.map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    ddSex() {
      return this.sex.map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    ddPersonality() {
      return this.personality.map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    ddVacationSpot() {
      return this.vacation_spot.map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    ddPerfectVacation() {
      return this.perfect_vacation.map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    ddIdeasAboutChosen() {
      return this.ideas_about_chosen.map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    ddOtherPeopleSmoking() {
      return this.other_people_smoking.map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    ddAlcohol() {
      return this.alcohol.map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
    ddDsDoesPlaceMatter() {
      return this.does_place_matterr.filter((outVal) => outVal.id != 1).map((outVal) => ({
        value_show: outVal.view,
        return_value: outVal.id,
      }));
    },
  },
  methods: {
    formatedDate(date) {
      if (!date) {
        return '-';
      }
      date=new Date(date);
      const dateText = date.toLocaleDateString('ru-RU', {timeZone: 'Europe/Zurich'});
      const timeText = date.toLocaleTimeString('en-EN', {timeZone: 'Europe/Zurich', hour: '2-digit', minute: '2-digit'});

      // datetime
      return dateText + ' ' + timeText;
    },
    toggleRequireField(fieldName, toggleData) {
      if (toggleData) {
        this.form_data[fieldName].required = true;
        return;
      }

      this.form_data[fieldName].required = false;

      if (Array.isArray(this.form_data[fieldName].val)) {
        this.form_data[fieldName].val = [];
        return;
      }

      if (this.form_data[fieldName].val === true || this.form_data[fieldName].val === false) {
        this.form_data[fieldName].val = false;
        return;
      }

      this.form_data[fieldName].val = null;
    },
    updateRefs() {
      getFormData((servData) => {
        Object.keys(servData).forEach((extKey) => {
          if (this.form_data[extKey] == null) return;

          if (this.form_data[extKey].itype == 'inline') {
            // child_set: {
            //   label: 'Children',
            //   error: null,
            //   val: null,
            //   itype: 'inline',
            //   content_form: {
            //     children_age: {label: 'Child age', error: null, val: null, itype: 'number'},
            //     children_gender: {label: 'Child gender', error: null, val: null, itype: 'dropdown', ddVals: []},
            //     children_live_together: {label: 'Do your children live with you?', error: null, val: false, itype: 'boolean'},
            //   },
            // },

            this.form_data[extKey].val = [];

            servData[extKey].forEach((data) => {
              const newElem = {};

              Object.keys(this.form_data[extKey].content_form).forEach((field) => {
                const newField = {};
                const curField = this.form_data[extKey].content_form[field];
                Object.keys(curField).forEach((attr) => newField[attr] = curField[attr]);
                newElem[field] = newField;
                if (data[field] != null && newElem[field].itype == 'dropdown' && !Array.isArray(data[field])) {
                  data[field] = [data[field]];
                }
                newElem[field].val = data[field];
              });

              this.form_data[extKey].val.push(newElem);
            });

            return;
          }

          if (servData[extKey] != null && this.form_data[extKey].itype == 'dropdown' && !Array.isArray(servData[extKey])) {
            servData[extKey] = [servData[extKey]];
          }

          this.form_data[extKey].val = servData[extKey];
        });

        this.form_data['child_set'].required = this.form_data.child_set.val != null && this.form_data.child_set.val.length > 0;
        // this.form_data['how_often_sport'].required = this.form_data.how_often_sport.val != null;
        this.form_data['preferred_country_of_residence'].required = this.form_data.preferred_country_of_residence.val != null && this.form_data.preferred_country_of_residence.val.length > 0;
        this.form_data['preferred_citizenship'].required = this.form_data.preferred_citizenship.val != null && this.form_data.preferred_citizenship.val.length > 0;
        this.form_data['preferred_profession'].required = this.form_data.preferred_profession.val != null && this.form_data.preferred_profession.val.length > 0;
        this.form_data['preferred_education'].required = this.form_data.preferred_education.val != null && this.form_data.preferred_education.val.length > 0;
        this.form_data['preferred_foreign_languages'].required = this.form_data.preferred_foreign_languages.val != null && this.form_data.preferred_foreign_languages.val.length > 0;
        this.form_data['does_place_matterr'].required = this.form_data.does_place_matterr.val != null && this.form_data.does_place_matterr.val.length > 0;

        this.form_data['preferred_ethnic_group'].required = this.form_data.preferred_ethnic_group.val != null && this.form_data.preferred_ethnic_group.val.length > 0;

        // this.form_data['accuracy'].val = this.form_data.__accept_offers__.val;
        this.form_data['full_registration'].val = this.form_data.__accept_offers__.val;
        setTimeout(() => {
          this.save_to_localStorage = true;
          const savedFormData = JSON.parse(localStorage.getItem('formData'));
          if (savedFormData) {
            this.form_data = savedFormData;
          }
        }, 1000);
      }, 'presentation');
      getRefList((newList) => {
        // newList.shift();
        this.countries = newList;
      }, 'Country');
      getRefList((newList) => {
        // newList.shift();
        this.profession = newList;
      }, 'Profession');
      getRefList((newList) => {
        // newList.shift();
        this.education = newList;
      }, 'Education');
      getRefList((newList) => {
        // newList.shift();
        this.languages = newList;
      }, 'Languages');
      getRefList((newList) => {
        // newList.shift();
        this.family_status = newList;
      }, 'FamilyStatus');
      getRefList((newList) => {
        // newList.shift();
        this.sex = newList;
        this.form_data.child_set.content_form.children_gender.ddVals = this.ddSex;
      }, 'Sex');
      getRefList((newList) => {
        // newList.shift();
        this.personality = newList;
      }, 'Personality');
      getRefList((newList) => {
        // newList.shift();
        this.vacation_spot = newList;
      }, 'VacationSpot');
      getRefList((newList) => {
        // newList.shift();
        this.perfect_vacation = newList;
      }, 'PerfectVacation');
      getRefList((newList) => {
        // newList.shift();
        this.ideas_about_chosen = newList;
      }, 'IdeasAboutChosen');
      getRefList((newList) => {
        // newList.shift();
        this.other_people_smoking = newList;
      }, 'OtherPeopleSmoking');
      getRefList((newList) => {
        // newList.shift();
        this.alcohol = newList;
      }, 'Alcohol');
      getRefList((newList) => {
        // newList.shift();
        this.does_place_matterr = newList;
      }, 'DoesPlaceMatter');
      getRefList((newList) => {
        // newList.shift();
        this.ethnic_group = newList;
      }, 'EthnicGroup');
    },
    getFieldStatus(field) {
      return this.form_data[field].error != null ? 'error' : undefined;
    },
    setFormValue(field, value) {
      this.global_error_text = null;
      this.form_data[field].val = value;
      this.form_data[field].error = null;

      // Проверяем, не является ли поле одним из полей медиа
      if (!this.fieldsToExcludeFromLocalStorage.includes(field)) {
        // Сохраняем значение поля в localStorage с ключом, соответствующим имени поля
        localStorage.setItem(field, JSON.stringify(value));
      }
    },
    sendAnketatoServer() {
      let isError = false;
      const fillCorrectText = 'Please, revise the fields marked by red!';

      // const field = this.currentField;

      // if (!this.fieldsToExcludeFromLocalStorage.includes(field)) {
      //   // Сохраняем значение поля в localStorage с ключом, соответствующим имени поля
      //   localStorage.setItem(field, JSON.stringify(value));
      // }

      Object.keys(this.form_data).forEach((key) => {
        if (this.form_data[key].required == true && (!this.form_data[key].val || this.form_data[key].val.length < 1)) {
          this.form_data[key].error = 'Поле должно быть заполнено';
          isError = true;
        }
      });

      // if (!this.form_data.full_registration.val) {
      //   this.form_data.full_registration.error = 'Примите политику!';
      //   isError = true;
      // }

      // if (this.form_data.password1.val != this.form_data.password2.val) {
      //   this.form_data.password1.error = 'Введенные пароли не совпадают!';
      //   this.form_data.password2.error = this.form_data.password1.error;
      //   isError = true;
      // }

      if (isError) {
        this.global_error_text = fillCorrectText;

        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: {
            name: 'Error',
            message: fillCorrectText,
          },
        }]);
        return;
      }

      console.log('confirmed', this.form_data);

      const postForm = new FormData();
      Object.keys(this.form_data).forEach((key) => {
        if (this.form_data[key].val == null) {
          return;
        }

        if (this.form_data[key].itype == 'inline') {
          postForm.append(key + '-TOTAL_FORMS', this.form_data[key].val.length);
          postForm.append(key + '-INITIAL_FORMS', 0);
          this.form_data[key].val.forEach((elem, idx) => {
            Object.keys(elem).forEach((inlineKey) => {
              postForm.append(key + '-' + idx + '-' + inlineKey, elem[inlineKey].val);
            });
          });
          return;
        }
        if (Array.isArray(this.form_data[key].val)) {
          this.form_data[key].val.forEach((elem) => postForm.append(key, elem));
          return;
        }

        if (this.form_data[key].val instanceof Date) {
          postForm.append(key, JSON.stringify(this.form_data[key].val).split('T')[0].substring(1));
          return;
        }

        if (this.form_data[key].val === true) {
          postForm.append(key, 'on');
          return;
        }

        if (this.form_data[key].val === false) {
          return;
        }

        postForm.append(key, this.form_data[key].val);
      });

      sendFormData(async (response) => {
        if (response.status == 200) {
          const okText = await response.text();

          if (okText == 'pay_req') {
            this.global_success_text = 'Great, your Presentation has been created by us; see it in the Section MY PRESENTATION';
            return;
          }

          if (okText == 'payed') {
            this.global_success_text = 'Презентация сохранена и оплачена!';
            return;
          }

          this.global_success_text = 'Great, your Presentation has been created by us; see it in the Section MY PRESENTATION';
          return;
        }
        if (response.status == 400) {
          this.global_error_text = fillCorrectText;
          const errorPack = await response.json();
          Object.keys(errorPack).forEach((erField) => {
            this.form_data[erField].error = errorPack[erField].join(' ');
          });
          window.globalEvents.callEvent('new_message', [{
            messageType: 'error',
            messageData: {
              name: 'Error',
              message: fillCorrectText,
            },
          }]);
          return;
        }

        this.global_error_text = 'Bad server response! ' + response.status;

        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: {
            name: 'Error',
            message: 'Bad server response! ' + response.status,
          },
        }]);
      }, 'presentation', postForm);
    },
  },
});
</script>

<style lang="less">
.info_block{
 .flex(row, start, start);
 flex-wrap: wrap;
}
.security_icon {
  display: flex;
    flex-direction: row-reverse;
    margin-top: 13px;
    margin-right: 35px;
}
.date_of_change_Q{
  display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
    margin-left: 23px;
}
.form_field__pair {
  min-width: 200px;
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.text_red{
  color: red;
}
.first_line_text{
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  margin-left: 10px;
}
.form_field {
  min-width: 250px;
  width: 50%;
  display: flex;
  padding: 13px;
  flex-direction: column;
}
.saved_text{
  padding: 13px;
}
.is_toogle_one_line_D{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: fit-content;
}
.is_toogle_one_line{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-left: 32px;
  flex-wrap: wrap;
  width: fit-content;
}
.policy-field {
  .flex(row, start, center);
  flex-wrap: wrap;
}
.create-pres-button{
  .flex(row, center, center);
}

.user_info__separator_quest{
  height: 1px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.questionnaire {
  padding: 20px;
  margin-top: -30px;
 }
</style>
