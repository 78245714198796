<template>
  <CheckBox :is_toggle="true"
            :label_text_left="this.getTitle"
            :init_value="this.data_value"
            :check_box_field_status="this.rawField.readonly ? 'disabled' : undefined"
            @input_check="this.updateValue"
            />
</template>

<script type="text/javascript">
import CheckBox from '@/units/forms/CheckBox';

export default ({
  components: {
    CheckBox,
  },
  emits: [
    'input_change',
  ],
  data: () => ({
    data_value: false,
  }),
  mounted() {
    this.data_value = this.rawField['value'] == null ? false : this.rawField['value'];
  },
  methods: {
    updateValue(newData) {
      this.data_value = newData;
      this.$emit('input_change', newData);
    },
  },
  computed: {
    getTitle() {
      const title = this.rawField.view || this.rawField.system_name;
      return title;
    },
  },
  watch: {
    rawField(newData) {
      if (newData) this.data_value = newData.value == true;
    },
    // set_values(newData) {
    //   // при получении новых данных через props меняем data_values
    //   this.data_value = newData;
    // },
    data_value(newData) {
      this.$emit('input_change', newData);
    },
  },
  props: {
    rawField: {
      required: true,
      type: Object,
    },
  },
});
</script>

<style lang="less">

</style>
