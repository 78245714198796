import Chat from './layout/ChatRoot';


export const routes = [
  {
    path: '',
    name: 'Chat',
    component: Chat,
  },
];

