<template>
  <BasePage name="My Sympathies">
    <div v-if="cards && cards.length > 0">
    <ViewedProfileList :cards="this.cards" @card_click="this.cardClick">
      <template v-slot="{card_data}">
        <div class="waiting_text">
          <LabelComponent label_text="The Candidate whose feedback you were waiting for, did not yet make a decision. We will keep you posted about the progress."
                        label_type="body/large/regular 12" />
        </div>
        <br/>
        <ButtonComponent
                          label="CANCEL SYMPATHY"
                          elementType="hover-bg"
                          @click.stop="this.dislikeClick(card_data)"
                          />
      </template>
    </ViewedProfileList>
    </div>
    <div v-else class="text_if_no_card">
      <LabelComponent label_text="Вы еще не выбрали ни одного Кандидата"/>
    </div>
    <div class="get-more-button-wraper">
      <ButtonComponent label="ORDER ANOTHER PACK" elementType="default-bg" @click="this.buyNewPackage"/>
    </div>
    <ModalView v-if="this.global_message_text != null" :open_level="1" type_modal="internal" position="fixed" caption=" " @click_close="this.global_message_text = null">
      <LabelComponent :label_text="this.global_message_text" label_type="button/large/medium 18" class="liked_text" />
        <br />
    </ModalView>
  </BasePage>
</template>

<script>
import BasePage from '@/baseLayout/BasePage';
import ButtonComponent from '@/units/forms/Button';
import LabelComponent from '@/units/Label';
import ModalView from '@/layoutTools/ModalView';
import ViewedProfileList from './ViewedProfileList';

import getFormData from '@/assets/dataSources/getFormData';
import sendFormData from '@/assets/dataSources/sendFormData';


export default ({
  components: {
    ModalView,
    BasePage,
    ButtonComponent,
    LabelComponent,
    ViewedProfileList,
  },
  data: ()=> ({
    global_message_text: null,
    user_message_text: null,
    cards: [
      {
        id: '1',
        image_url: '/icons/law.svg',
        user_id: '0000000',
      },
      {
        id: '2',
        image_url: '/icons/law.svg',
        user_id: '0000001',
      },
    ],
  }),
  props: {

  },
  mounted() {
    this.updateCards();
  },
  computed: {

  },
  methods: {
    updateCards() {
      getFormData((servData) => {
        this.cards = servData;
      }, 'my_sympathies');
    },
    cardClick(card) {
      window.location.href = '/presentation/' + card.id + '/';
    },
    dislikeClick(card) {
      this.user_message_text= 'You canceled Sympathy. You can visit CANDIDATE Section to see the Candidate again.';
      const curForm = new FormData();
      curForm.append('viewed_profile_id', card.id);

      sendFormData(() => {
        this.updateCards();
      }, 'my_sympathies', curForm);
    },
    buyNewPackage() {
      sendFormData((result) => {
        if (result.status == 200) {
          this.global_message_text = 'The new package has been successfully purchased. Please expect Presentations of new Candidates to appear.';
        } else if (result.status == 400) {
          this.global_message_text = 'There are not enough funds to purchase the package, please top up your Wallet.';
        } else if (result.status == 409) {
          this.global_message_text = 'A new Pack can be ordered after all the Presentations of already ordered Candidates have arrived. Thank you for your desire to receive more Candidates.';
        };
      }, 'buy_candidates_package');
    },
  },
});
</script>

<style lang="less">

.get-more-button-wraper {
  .flex(row, center, center);
}
</style>
