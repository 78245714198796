<template>
    <SettingsModal v-if="this.settingsModal" @closeSettingsModal="this.toggleSettingsModal"/>
  <div class="profile-frame" ref="profile_frame">
    <div class="profile-frame__preview">
      <div class="notifications-icon" @click="this.$router.push('/notifications')">
        <IconComponent size="20" url="/icons/not.svg"/>
        <LabelComponent class="notifications-icon__counter"
                        v-show="this.notificationCount > 0"
                        :label_text="this.notificationCount"/>
      </div>
      <div class="avatar-container">
        <img :src="form_data.media_photo1.val || '/icons/person-circle-svgrepo-com.svg'" alt="User Avatar" class="avatar-image"/>
      </div>
    </div>
    <div class="profile-frame__user-menu" v-if="this.dropped">
      <span ref="dropdown">
        <ProfileUserMenu :menuItems="menuItems" @select_elem="select_elem"/>
        <ProfileUserMenuMobile :menuItems="menuItems"
                               @close_modal="dropped=false" @select_elem="select_elem"/>
      </span>
    </div>
  </div>
</template>

<script>
import IconComponent from '@/units/Icon';
import ProfileUserMenu from './ProfileUserMenu';
import ProfileUserMenuMobile from './ProfileUserMenuMobile';
import logoutUser from '@/assets/dataSources/auth/logoutUser';
import SettingsModal from './SettingsModal';
import isMobileMixin from '@/assets/mixins/isMobileMixin';
import LabelComponent from '@/units/Label';
import getFormData from '@/assets/dataSources/getFormData';

export default {
  components: {
    IconComponent,
    LabelComponent,
    ProfileUserMenu,
    ProfileUserMenuMobile,
    SettingsModal,
  },
  name: 'ProfileFrame',
  data: () => ({
    balance: 0,
    dropped: false,
    settingsModal: false,
    form_data: {
      media_photo1: {error: null, val: null, required: false},
    },
  }),
  mixins: [isMobileMixin],
  mounted() {
    this.updateRefs();
  },
  computed: {
    notificationCount() {
      return this.$store.getters.getNotificationCount;
    },
    get_user_info() {
      return this.$store.state.auth_settings.user_obj;
    },
    get_user_full_name() {
      if (!this.get_user_info) return '__anonymous__';
      if (!this.get_user_info.first_name && !this.get_user_info.last_name) return 'Неизвестное имя';
      return [this.get_user_info.first_name, this.get_user_info.last_name].join(' ');
    },
    menuItems() {
      if (this.get_user_info.is_anonymous) {
        return [
          {
            name: 'Agency’s site',
            icon: 'globe',
            callFunc: () => {
              window.open('https://marriageagency.family/', '_blank');
            },
          },
          {
            name: 'Вход',
            separatorTop: true,
            icon: 'sign-out',
            callFunc: () => {
              location.href = '/login';
            },
          },
        ];
      }

      return [
        {
          name: 'Agency’s site',
          icon: 'globe',
          callFunc: () => {
            window.open('https://marriageagency.family/', '_blank');
          },
        },
        {
          name: 'Выход',
          separatorTop: true,
          icon: 'sign-out',
          callFunc: () => {
            logoutUser(() => {
              location.href = '/';
            });
          },
        },
      ];
    },
  },
  props: ['user_info'],
  created() {
    this.menuItems.map((menuItem, idx) => {
      menuItem.id = idx;
    });
    document.addEventListener('click', this.clickOutHandler);
  },
  methods: {
    updateRefs() {
      getFormData((servData) => {
        Object.keys(servData).forEach((extKey) => {
          if (this.form_data[extKey] == null) return;

          if (this.form_data[extKey].itype == 'inline') {
            this.form_data[extKey].val = [];

            servData[extKey].forEach((data) => {
              const newElem = {};

              Object.keys(this.form_data[extKey].content_form).forEach((field) => {
                const newField = {};
                const curField = this.form_data[extKey].content_form[field];
                Object.keys(curField).forEach((attr) => newField[attr] = curField[attr]);
                newElem[field] = newField;
                if (data[field] != null && newElem[field].itype == 'dropdown' && !Array.isArray(data[field])) {
                  data[field] = [data[field]];
                }
                newElem[field].val = data[field];
              });

              this.form_data[extKey].val.push(newElem);
            });

            return;
          }

          if (servData[extKey] != null && this.form_data[extKey].itype == 'dropdown' && !Array.isArray(servData[extKey])) {
            servData[extKey] = [servData[extKey]];
          }

          this.form_data[extKey].val = servData[extKey];
        });
      }, 'profile');
    },
    toggleSettingsModal() {
      this.settingsModal = false;
    },
    select_elem(id) {
      this.dropped = false;
      this.menuItems[id].callFunc();
    },
    clickOutHandler(event) {
      let container = this.$refs.profile_frame;
      if (container && !container.contains(event.target)) {
        this.dropped = false;
      } else {
        container = this.$refs.dropdown;
        if (!container || !container.contains(event.target)) {
          this.dropped = !this.dropped;
        }
      }
    },
  },
  beforeUnmount() {
    document.removeEventListener('click', this.clickOutHandler);
  },
};
</script>

<style lang="less">
@profile__username__text__color: @gray;
@profile-frame__preview__text__color: @dark;
@profile-frame__preview__icon__color: @dark;

.test_avatar {
  height: 30px;
  width: 30px;
}

.profile-frame {
  .notifications-icon {
    padding: 14px;
    position: relative;

    &__counter {
      position: absolute;
      top: 5px;
      right: 7px;
      color: red;
    }
  }

  .flex(column, flex-start, flex-start);

  &__preview {
    .flex(row, space-between, center);
    position: relative;
    color: @profile-frame__preview__text__color;
    user-select: none;
    .set_icon_color(@profile-frame__preview__icon__color);

    &:hover {
      cursor: pointer;
    }
  }

  &__username {
    margin-left: 5px;
    color: @profile__username__text__color;
  }

  &__user-menu {
    width: 100%;
    overflow: visible;
    position: relative;
  }

  &__icon {
    margin-left: 10px;
    transition: transform 0.2s;

    &-opened {
      transform: rotate(-180deg);
    }
  }
}

.avatar-container {
  width: 40px;
    height: 40px;
    border-radius: 20%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Сохранение пропорций изображения */
}

.balance_top_panel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: @transition-threshold-1) {
  .profile-frame {
    &__username {
      display: none;
    }

    &__icon {
      display: none;
    }
  }
  .balance_top_panel {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
