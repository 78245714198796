<template>
  <div class="topPanelFrame">
    <div class="top-wrapper">
      <IconComponent class="topPanelFrame__burger"
                     @click="burgerClick"
                     name="burger"
                     type="svg"
                     size="28"/>
      <div class="my_profile_button">
      <ButtonComponent elementType="default"
                       label_type="button/large/medium 18"
                       elementSize="big"
                       @click="this.$router.push('/userprofile')"
                       label ="MY PROFILE"/>
      </div>
      <!-- <LabelComponent
      label_text="User Profile"
        @click ="this.$router.push('/userprofile')" /> -->
       <!--<SwitcherComponent ref="switcher"
                         size="medium"
                         :values="this.get_lang_switcher_values"
                         :init_selected_index="this.getUserLangId()"
                         @select_changed="this.switchLanguage"/>-->
    </div>
    <!-- <div class="top-wrapper" style="flex-wrap: wrap;"> -->
    <div class="top_panel_content">
      <div class="balance_top_panel" v-show="!this.get_is_mobile_info">
          <LabelComponent label_text="Текущий баланс"
                          label_type="caption/big/bold 16"
                          class="top_panel_text"
                           />
          <LabelComponent :is_monospace="true" :label_text="getFormattedBalanceWithCurrency"/>
      </div>
      <SeparatorComponent separatorType = "vertical" class = "top_panel_separator" />
      <div class="time_wrapper">
        <LabelComponent label_text="Швейцария"
                        label_type="caption/big/bold 16"
                        class="top_panel_text"
                         />
        <LabelComponent :is_monospace="true"
                        :label_text="this.getChTime"
                        />
      </div>

    </div>
    <ProfileFrame/>
  </div>
</template>

<script>

// import builder from '../@/assets/v1/js/builder';
import ProfileFrame from './ProfileFrame';
import LabelComponent from '@/units/Label';
import IconComponent from '@/units/Icon';
import SeparatorComponent from '@/units/forms/Separator';
import ButtonComponent from '@/units/forms/Button';
import getWalletInfo from '@/assets/dataSources/pages/getWalletInfo';
import isMobileMixin from '@/assets/mixins/isMobileMixin';
// import SwitcherComponent from '../../unit/Switcher';
// import ButtonComponent from '@/units/forms/Button';


export default /* builder */({
  components: {
    LabelComponent,
    IconComponent,
    ProfileFrame,
    ButtonComponent,
    SeparatorComponent,
    // SwitcherComponent,
  },
  name: 'TopPanel',
  data: ()=> ({
    balance: 0,
    current_time: new Date(),
  }),
  mounted() {
    this.updateWalletData();
    setInterval(() => {
      this.current_time = new Date();
    }, 1000);
  },
  mixins: [isMobileMixin],
  computed: {
    get_user_info() {
      return this.$store.state.auth_settings.user_obj;
    },
    getFormattedBalanceWithCurrency() {
      // Определяем символ валюты на основе pay_region
      const currencySymbol = this.get_user_info.pay_region === 'ru' ? '₽' : 'CHF';
      return `${currencySymbol} ${this.formattedBalance}`;
    },
    formattedBalance() {
    // Форматируем баланс как число с двумя десятичными знаками и разделителем в виде запятой
      return this.balance.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    getChTime() {
      const dateText = this.current_time.toLocaleDateString('ru-RU', {timeZone: 'Europe/Zurich'});
      const timeText = this.current_time.toLocaleTimeString('en-EN', {timeZone: 'Europe/Zurich', hour: '2-digit', minute: '2-digit'});
      return dateText + ' ' + timeText;
    },
  },
  methods: {
    updateWalletData() {
      getWalletInfo((newData) => {
        this.balance = newData.balance;
        this.transactions = newData.transactions.reverse();
      });
    },
    burgerClick() {
      this.$emit(
          'left-menu',
          'on',
      );
    },
  },
  props: {
    name: {
      type: String,
      default: 'Добро пожаловать!',
    },
  },
});


</script>

<style lang="less">
@panel__top_bottom__padding: @margin-horizontal-default;
@panel__top__padding__800: @margin-horizontal-800;
@panel__burger__color: @gray;
.top_panel_text  * {
  // font-style: italic;
  // font-weight: 400;
  color:@hover-error;
}
.top_panel_content{
  gap: 6px;
    display: flex;
}
.topPanelFrame{
  width: 100%;
  .flex(row, space-between, center);
  padding: 0 @panel__top_bottom__padding;
  &__burger{
    display: none;
  }

  .top-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > * {
      margin-right: 7px;
    }

    & > *:last-child {
      margin-right: 0;
    }
  }
}
.time_wrapper{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
}
.balance_top_panel{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
@media screen and (max-width: @transition-threshold-1) {
  .lang-toggle{
    display: none;
  }
  .time_wrapper{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
}
  .balance_top_panel{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .topPanelFrame{
    padding: 0 @panel__top__padding__800 0;
    .top-wrapper {
      .flex(row, center, center);

      & > * {
        margin-right: 7px;
      }

      & > *:last-child {
        margin-right: 0;
      }
    }
    &__burger{
      display: block;
      background-color: @panel__burger__color;
      &:hover { cursor: pointer}
    }
  }
}

</style>
