<template>
    <div class="profile-list">
      <div class="profile-card" v-for="card in this.cards" :key="card" @click="card_click(card)">
        <div class="user_id_card">
        <LabelComponent :label_text="'ID: ' + card.user_id" class="italic-label" label_type="body/large/regular 16"/>
        </div>
        <div class="photo_card">
        <IconComponent :url="card.image_url"
                              type="png"
                              size="100"
                              sizeUnit="%"/>
                            </div>
              <!-- <div class="card-left"> -->
                <!-- <IconComponent :url="card.image_url"
                              type="png"
                              size="100"
                              sizeUnit="%"/> -->
              <!-- </div> -->
              <!-- <div class="card-right"> -->
                <!-- <LabelComponent :label_text="'ID: ' + card.user_id"/> -->
                <!-- <br/> -->
                <slot :card_data="card"></slot>
              <!-- </div> -->
          <div class="found_time">
            <LabelComponent :label_text="formatedDate(card.created_date)"
                            class="italic-label"
                            style="color: red;"/>
          </div>
      </div>
    </div>
</template>

<script>
import LabelComponent from '@/units/Label';
import IconComponent from '@/units/Icon';


export default ({
  components: {
    IconComponent,
    LabelComponent,
  },
  data: ()=> ({
  }),
  emits: [
    'card_click',
  ],
  props: {
    cards: {
      type: Array,
      required: true,
    },
  },
  mounted() {

  },
  computed: {

  },
  methods: {
    formatedDate(createdDate) {
      if (!createdDate) {
        return '';
      }
      // Преобразуем created_date в объект Date, если он ещё не является таковым
      const date = new Date(createdDate);
      if (isNaN(date)) {
      // Проверяем, удалось ли корректно создать объект Date
        return 'Invalid date';
      }

      const dateText = date.toLocaleDateString('ru-RU', {timeZone: 'Europe/Zurich'});
      const timeText = date.toLocaleTimeString('en-EN', {timeZone: 'Europe/Zurich', hour: '2-digit', minute: '2-digit'});

      return `${dateText} ${timeText}`;
    },
    card_click(card) {
      this.$emit('card_click', card);
    },
  },
});
</script>

<style lang="less">
.cloud_backward {
    position: relative;
    padding: 10px 20px;
    background-color: #d5d5d5;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
}

.cloud_backward::before {
  content: ''; /* Необходимо для отображения псевдоэлемента */
  position: absolute;
  top: 100%; /* Позиционирование на нижнем крае облачка */
  right: 20px;
  border-width: 10px; /* Размер хвостика */
  border-style: solid;
  border-color: #d5d5d5 transparent transparent transparent; /* Цвета границ: для верхней и остальных сторон */
}
.waiting_text {
    position: relative;
    padding: 10px 20px;
    background-color: #d5d5d5;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
}

.waiting_text::before {
  content: ''; /* Необходимо для отображения псевдоэлемента */
  position: absolute;
  top: 100%; /* Позиционирование на нижнем крае облачка */
  left: 20px; /* Сдвиг хвостика вправо */
  border-width: 10px; /* Размер хвостика */
  border-style: solid;
  border-color: #d5d5d5 transparent transparent transparent; /* Цвета границ: для верхней и остальных сторон */
}
.cancel_button{
  margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}
.user_id_card{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.found_time{
  display: flex;
  width: 350px;
  justify-content: center;
}
.other_profiles_card{
  padding: 13px;
}
.card_viewed_profile{
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Slight shadow */
  border-radius: 8px; /* Rounded corners */
  min-width: 350px;
  width: 350px;
}
.profile-list {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    padding: 13px;

  .photo_card{
    display: flex;
    width: 50%;
  }
  .profile-card {
    margin: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    min-width: 330px;
    width: 350px;
    min-height: 350px;
    padding: 22px;
    gap: 5px;
    flex-wrap: nowrap;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
