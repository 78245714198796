<template>
  <div>
    <ForeignObject :is_extendable="false"
                   :is_filterable="true"
                   :is_multi_select="false"
                   :label_text="this.rawField.view"
                   :object_type="this.rawField.linked_object_type"
                   :selected_items="this.getDataValue"
                   :input_field_status="this.rawField.readonly ? 'disabled' : undefined"
                   :is_favoritable="true"
                   @input_change="this.updateValue"
    />
  </div>
</template>

<script type="text/javascript">
import ForeignObject from '@/units/forms/ForeignObject';

export default ({
  components: {
    ForeignObject,
  },
  emits: [
    'input_change',
  ],
  data: () => ({
    dataValue: [],
  }),
  created() {
    this.dataValue = this.rawField['value'] == null ? [] : [this.rawField['value']];
  },
  computed: {
    getDataValue() {
      return this.dataValue;
    },
  },
  methods: {
    updateValue(newData) {
      this.dataValue = newData;
      this.$emit('input_change', newData[0]);
    },
  },
  staticMethods: {
    getPackedInputData(forPackData, rawField) {
      // if (rawField.type == 'link') {

      // }

      return forPackData ? forPackData.uid : null;

      // return !forPackData ? [] : forPackData.map((item) => item.uid);
    },
  },
  watch: {
    rawField(newData) {
      if (newData) this.dataValue = newData['value'] == null ? [] : [newData['value']];
    },
  },
  props: {
    rawField: {
      required: true,
      type: Object,
    },
  },
});
</script>

<style lang="less">

</style>
