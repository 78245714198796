import {ROOT_URL, store} from '@/store';

const REQUEST_LINK = '/data/change_object/';

function getObjectInfo(objectTypeName, objectUID, handler) {
  store.commit('set_full_screen_loading', true);
  fetch(ROOT_URL + REQUEST_LINK + objectTypeName + '/' + String(objectUID), {
    method: 'GET',
    credentials: 'include',
    // body: JSON.stringify({params: {}}),
  }).then(async function(response) {
    const objectTypeData = await response.json();
    store.commit('set_full_screen_loading', false);
    handler(objectTypeData);
  }).catch((errData) => {
    store.commit('set_full_screen_loading', false);
  });
}

export default getObjectInfo;
