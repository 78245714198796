<template>
  <div class='mtom-wrapper'>
    <SeparatorComponent />
    <LabelComponent :is_monospace="true"
                    :label_text="this.rawField.view"
                    style="margin: 7px; margin-left: -14px;"
    />
    <TableComponent
      :table-data="this.getTableData"
      :table-data-formatted="this.getTableData"
      :table-columns="this.getTableColumns"
      :is-xlsx-exporting="true"
      :is-selectable="false"
      :no-data-text="null"
      :withTotal="false"
    >
      <template v-slot="{row_id, column_id}">
        <ButtonComponent v-if="this.getTableColumns[column_id].system_name == '__remove_col__'"
                      :iconUrl="'/icons/close.svg'"
                      :elementType="'default'"
                      class="remove-sub-obj-button"
                      @click_to_button="this.removeElement(row_id)"
        />

        <component v-else
                   :is="this.getFieldComponent(this.getTableData[row_id][column_id])"
                   :parentField="this.rawField"
                   :rawField="this.getTableData[row_id][column_id]"
                   @input_change="(newData)=> this.inputChanged(this.getTableData[row_id][column_id], newData)"
                   />

      </template>
    </TableComponent>
    <ButtonComponent
                      :label="'Добавить элемент'"
                      :elementType="'default'"
                      class="add-new-sub-obj-button"
                      @click_to_button="this.addNewElement()"
    />
    <SeparatorComponent />
  </div>
</template>

<script type="text/javascript">
import TableComponent from '@/units/Table';
import LabelComponent from '@/units/Label';
import ButtonComponent from '@/units/forms/Button';
import SeparatorComponent from '@/units/forms/Separator';


import m2mLinkField from './mtomFieldForm/m2mLinkField';


const fieldComponents = {
  // 'boolean': AdminFormBooleanField,
  // 'text': AdminFormTextField,
  'link': m2mLinkField,
  // 'table': AdminFormMtoMField,
};

function getFieldComponent(item) {
  const ans = fieldComponents[item.type];

  if (ans == null) {
    console.warn('M2M Не удалось отрисовать поле ввода для типа:', item.type, ' Поле: ', item);
  }

  return ans;
}

function packInputData(inputData, inputField) {
  const customMethods = getFieldComponent(inputField)['staticMethods'];

  let packedData = inputData;

  if (customMethods != null && customMethods['getPackedInputData']) {
    packedData = customMethods['getPackedInputData'](inputData, inputField);
  }

  return packedData;
}

export default ({
  components: {
    TableComponent,
    LabelComponent,
    ButtonComponent,
    SeparatorComponent,
  },
  emits: [
    'input_change',
  ],
  data: () => ({
    drawObjectsValues: [],
  }),
  mounted() {
    this.drawObjectsValues = this.rawField['value'] == null || this.rawField['value'].length < 1 ? [] : this.rawField['value'];
  },
  staticMethods: {
    getPackedInputData(forPackData, rawField) {
      const ans = forPackData.map(
          function(drawObj) {
            const locAns = Object.fromEntries(
                Object.keys(drawObj[1]).map(
                    (fieldName) => [fieldName, packInputData(drawObj[1][fieldName].value, drawObj[1][fieldName])],
                ),
            );

            locAns.__uid__ = drawObj[0];
            return locAns;
          },
      );

      return ans;
    },
  },
  methods: {
    getFieldComponent(item) {
      return getFieldComponent(item);
    },
    inputChanged(rawField, newData) {
      rawField.value = newData;
      this.callFormsChanged();
    },
    callFormsChanged() {
      this.$emit(
          'input_change',
          this.drawObjectsValues.map(
              (drawObj) => [drawObj.uid, Object.fromEntries(
                  drawObj['fields'].filter(
                      (field) => field.system_name != '__remove_col__',
                  ).map(
                      (field) => [field.system_name, field],
                  ),
              )],
          ),
      );
    },
    addNewElement() {
      const rawFields = this.rawField.linked_object_form.fields;

      // console.log('rawFields', rawFields);

      // const newObj = {};
      const newObj = JSON.parse(JSON.stringify(rawFields));
      // rawFields.forEach((elem) => newObj[elem.system_name] = elem.value);

      this.drawObjectsValues.push(
          {
            uid: null,
            fields: newObj,
          },
      );

      this.callFormsChanged();
    },
    removeElement(removeIndex) {
      this.drawObjectsValues.splice(removeIndex, 1);

      this.callFormsChanged();
    },
  },
  computed: {
    getTableColumns() {
      const drawFields = this.rawField.linked_object_form.fields;

      drawFields.push({
        'view': 'Удалить',
        'system_name': '__remove_col__',
      });

      if (drawFields.length===0) return [];

      // const packedColumns = Object.keys(drawFields).map((field) => {
      //   // eslint-disable-next-line vue/no-mutating-props
      //   this.draw_info.columns[item].__key__ = item;
      //   return this.draw_info.columns[item];
      // });

      const ans = drawFields.filter(
          (field) => field.system_name != this.rawField.linked_field_name && field.system_name != '__uid__',
      ).map((field, index) =>{
        return {'id': index,
          'title': field.view,
          'system_name': field.system_name,
          // 'content_align': this.checkColumnIsNumber(item.__key__) ? 'right' : 'left',
          'width': '300px',
          // 'is_delete_if_empty': item.is_delete_if_empty,
          // 'is_with_sum': item.is_with_sum === true,
          // 'is_files': item.is_files === true,
          // 'resource': item.resource,
          'self_clickable': false,
          // 'number_round': item.number_round,
          // '__key__': item.__key__,
          // 'data': (item) => item['value'],
        };
      });

      return ans;
    },
    getTableData() {
      const drawFields = this.rawField.linked_object_form.fields;

      if (drawFields.length===0) return [];

      const allColumns = this.getTableColumns;

      const curAns = this.drawObjectsValues.map((drawObj, index) => {
        const ans = Object.assign(
            {
              id: index,
              // text_color: item.color,
              // icon: item.icon,
              // background_color: item.color_background,
              is_total: false,
              // is_remove_column: column.system_name == '__remove_col__',
              mtomUid: drawObj['uid'],
            },
            allColumns.map((column) => drawObj['fields'].filter((item) => item.system_name == column.system_name)[0]),
        );

        return ans;
      });

      return curAns;
    },
  },
  watch: {
    rawField(newData) {
      if (newData) this.drawObjectsValues = newData['value'] == null || newData['value'].length < 1 ? [] : newData['value'];
    },
  },
  props: {
    rawField: {
      required: true,
      type: Object,
    },
  },
});
</script>

<style lang="less">

.mtom-wrapper {
  // padding: 14px;

  .add-new-sub-obj-button {
    display: flex;
    margin: 9px;
  }
}
</style>
