import {ROOT_URL, store} from '@/store';

const REQUEST_LINK = '/data/build_report/';

function buildReport(reportName, filters, handler) {
  store.commit('set_full_screen_loading', true);

  const formData = new FormData();
  Object.keys(filters).forEach((key) => formData.append(key, filters[key]));

  fetch(ROOT_URL + REQUEST_LINK + reportName, {
    method: 'POST',
    credentials: 'include',
    body: formData,
  }).then(async function(response) {
    const builtReport = await response.json();
    store.commit('set_full_screen_loading', false);
    handler(builtReport);
  }).catch((errData) => {
    store.commit('set_full_screen_loading', false);
  });
}

export default buildReport;
