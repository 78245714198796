export default {
  data: () => ({
    isMobileWall: 0,
    currentWidth: 0,
  }),
  beforeMount() {
    const appElem = document.getElementById('app');
    const appStyles = window.getComputedStyle(appElem, null);
    const mobileTransitionThreshold = appStyles.getPropertyValue('--transition-threshold-1').slice(0, -2);

    this.isMobileWall = parseInt(mobileTransitionThreshold);
    this.currentWidth = window.innerWidth;

    window.addEventListener('resize', () => {
      this.currentWidth = window.innerWidth;
    });
  },
  computed: {
    get_is_mobile_info() {
      return this.currentWidth <= this.isMobileWall;
    },
  },
};
