<template>
  <div class="check-box-block" @click="this.checking_item">
    <div :class="this.get_input_block_field_classes">
      <div class="check-box-block__text" >
        <LabelComponent v-if="this.label_text_left"
                        label_component_type="label"
                        label_type="body/large/regular 14"
                        :label_text="this.label_text_left"
                        class="check-box__label"/>
        <LabelComponent v-if="this.label_text_left && this.is_required"
                        class="check-box__label__required-symbol"
                        label_component_type="label"
                        label_type="body/large/regular 14"
                        label_text=" *"/>

        <label v-if="this.is_toggle" class="check-box-block__view-toggle">
          <div class="move-button" :class="{'move-button__active': state_box}"/>
        </label>

        <label v-else class="check-box-block__view-input">
          <IconComponent v-show="this.state_box" url="/icons/check.svg" sizeHeight="12" sizeWidth="12"/>
        </label>

        <LabelComponent v-if="this.label_text_right"
                        label_component_type="label"
                        label_type="body/large/regular 14"
                        :label_text="this.label_text_right"
                        class="check-box__label"/>
        <LabelComponent v-if="this.label_text_right && this.is_required"
                        class="check-box__label__required-symbol"
                        label_component_type="label"
                        label_type="body/large/regular 14"
                        label_text=" *"/>
      </div>
    </div>

    <LabelComponent class="input-block__error-text"
                    label_type="caption/small/regular 10"
                    v-if="this.check_box_field_status==='error'"
                    :label_text="this.error_text" />

  </div>
</template>

<script>
// import setIdMixin from '@/assets/v1/js/mixins/setIdMixin';
// import builder from '@/assets/v1/js/builder';

import LabelComponent from '@/units/Label';
import IconComponent from '@/units/Icon';

export default /* builder */({
  name: 'CheckBox',
  components: {
    LabelComponent,
    IconComponent,
  },
  // mixins: [setIdMixin],
  emits: {input_check: null},
  mounted() {
    this.state_box=!this.state_box;
    this.checking_item(false);
  },
  methods: {
    checking_item(checkDisable=true) {
      if (checkDisable && this.check_box_field_status == 'disabled') {
        return;
      }
      this.state_box=!this.state_box;
      this.$emit('input_check', this.state_box);
    },
  },
  watch: {
    init_value() {
      this.state_box = this.init_value;
    },
  },
  data: function() {
    return {
      state_box: this.init_value,
    };
  },
  props: {
    is_toggle: {
      type: Boolean,
      default: false,
    }, // тип переключателя
    check_box_field_status: {
      validator: (prop) => ['error', 'disabled'].includes(prop),
      required: false,
    },
    label_text_right: {
      type: String,
      default: '',
    },
    label_text_left: {
      type: String,
      default: '',
    },
    init_value: {
      type: Boolean,
      default: false,
    },
    error_text: {
      type: String,
      default: 'Неизвестная ошибка, обратитесь к администратору',
    },
    is_required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    get_input_block_field_classes() {
      const classes = {
        'check-box-block__field': true,
        'check-box-block__filled': this.state_box,
      };

      if (this.check_box_field_status) {
        classes[`input-block__${this.check_box_field_status}`] = true;
      }

      return classes;
    },
  },
});
</script>


<style lang="less">
@input__border__color: @stroke-gray;
@input__icon__color: @light-gray;
@input__text__color: #303030;

@input__filled__border__color: @gray;
@input__focused__border__color: @gray;
@input__error__border__color: @error;
@input__error__text__color: @error;

@input__disabled__background-color: @super-light;
@input__disabled__border__color: @super-light;
@input__disabled__text__color: @light-gray;

@checker__unchecked__border__color: @light-gray;
@toggle__background-color: @gray-opacity-10;

@toggle__button__inactive__color: #303030;
@toggle__button__active__color: #fa3098;

.check-box-block{
  .flex(column, flex-start, flex-start);
  // width: 30%;
  //min-width: 250px;
  //transition: all 1000ms; непонятно зачем он нужен

  &__view-toggle{
    cursor: pointer;
    // .flex(row, flex-start, center);
    width: 55px;
    height: 24px;
    vertical-align: middle;
    border-radius: 16px;
    background-color: @toggle__background-color;
    padding: 3px;
    // padding-left: auto;

    .move-button{
      width: 18px;
      height: 18px;
      border-radius: 9px;
      background-color: @toggle__button__inactive__color;
      margin-left: 0;
      transition: all 1000ms;

      &__active{
        margin-left: auto;
        background-color: @toggle__button__active__color;
      }
      // margin-right: auto;
    }
  }

  &__view-input {
    cursor: pointer;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    border-radius: 2px;
    border: 1px solid @checker__unchecked__border__color;
    flex-shrink: 0; // чтобы элемент не сжимался из за флекса
    .set_icon_color(@gray);


    &__selected {
      border: 1px solid @light-green-opacity-10;
    }
  }

  &__text {
    .flex(row, flex-start, center);
    // flex-grow: 1;
    // display: inline-block;
    // user-select: none;
    color: @input__text__color;
    .check-box__label {
      cursor: pointer;

      &__required-symbol {
        color: @error;
      }
    }

    & > * {
      margin-right: 5px;
    }

    & > *:last-child {
      margin-right: 0;
    }
  }
  &__input{
    width: 100%;
    background-color: transparent;
    border: 0 solid transparent;
    height: 0;
    position: absolute;
  }
  /*&__icon{
    margin-left: 10px;
  }*/

  &__filled {
    .check-box-block__view-input {
      border: 1px solid @dark-blue;
    }

    .input-block__input{
      height: 100%;
      position: relative;
      overflow: visible;
    }
  }
  /*&__focused {
    border: 1px solid @input__focused__border__color;
  }*/
  &__error {
    // border: 1px solid @input__error__border__color;

    &-text{
      color: @input__error__text__color;
      margin: 6px 0;
    }
  }

  &__disabled{
    //pointer-events: none;
    background-color: @input__disabled__background-color;
    // border-color: @input__disabled__border__color;
    color: @input__disabled__text__color;
  }
}

.input-block__text{
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    background: transparent;
    color: transparent;
    width: 24px;
    height: 24px;
    right: -37px;
    top: -16px;
    //display: none;
    -webkit-appearance: none;
    z-index: 10;
  }
}
</style>
