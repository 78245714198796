<template>
    <!-- <div> -->
      <Icon
            :size="this.size"
            :sizeUnit="this.sizeUnit"
            :sizeHeight="this.sizeHeight"
            :sizeWidth="this.sizeWidth"
            :color="this.color"
            :url="this.url"
            :name="this.name"
            :type="this.type"
            @icon_click="this.iconClick()"
      />
      <ModalView v-if="this.zoomed"
              :open_level = "1"
              type_modal = "internal"
              position = "fixed"
              caption = ""
              @click_close="this.zoomed = false">
        <div class="zoomed-icon-wraper">
          <div class="zicon" v-if="type==='svg'"
            :style="get_zoomed_styles"
            :class="this.url||this.name?'zicon-style-color':'zdefault-icon'"/>
          <img v-else :style="get_zoomed_styles"
                      :src="get_zoomed_styles.src"
          >
        </div>
      </ModalView>
    <!-- </div> -->
</template>

<script type="text/javascript">
import Icon from './Icon';
import ModalView from '@/layoutTools/ModalView';

export default {
  components: {
    ModalView,
    Icon,
  },
  data: () => ({
    zoomed: false,
  }),
  props: {
    size: {
      type: String,
      required: false,
    },
    sizeUnit: {
      type: String,
      default: 'px',
      required: false,
    },
    sizeHeight: {
      type: String,
      default: '24',
    },
    sizeWidth: {
      type: String,
      default: '24',
    },
    color: {
      type: String,
      default: undefined,
    },
    url: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    type: {
      default: 'svg',
      type: String,
      required: false,
    },
  },
  emits: {'icon_click': null},
  computed: {
    get_zoomed_styles() {
      const localUrl = this.url ? this.url : `/icons/${this.name}.${this.type}`;
      const url = /* this.$store.state.urls.root + */ localUrl;
      const ans = {
        'src': this.type ==='png'? url: null,
        'mask': (this.url || (this.type && this.name)) ? `url(${url}) no-repeat center / contain` : null,
        '-webkit-mask': (this.url || (this.type && this.name)) ? `url(${url}) no-repeat center / contain` : null,
        // 'min-width': '100%',
        // 'max-width': '100%',
        'min-height': '100%',
        'max-height': '100%',
      };

      if (this.color) {
        if (!(this.url||this.name)) {
          ans['border-color'] = this.color;
        } else {
          ans['fill'] = this.color;
          ans['stroke'] = this.color;
          ans['background'] = this.color;
        }
      }

      return ans;
    },
  },
  methods: {
    iconClick() {
      this.zoomed = true;

      this.$emit('icon_click');
    },
  },
};
</script>

<style lang="less">
  // Стили для отдельных элементов

  @icon__border-color: @light-green;

  .zoomed-icon-wraper {
    height: 500px;

    .zicon {
      transition: color 0.2s;
    }

    .zdefault-icon {
      border: 1px solid @icon__border-color;
      border-radius: 30%;
    }
  }
   @media screen and (max-width: @transition-threshold-1)  {
      .zoomed-icon-wraper{
        height: 300px;
      }
    }
</style>
