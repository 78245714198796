<template>
  <BasePage name="My Dates">
    <div v-if="cards && cards.length > 0">
    <ViewedProfileList :cards="this.cards" @card_click="this.cardClick">
      <template v-slot="{card_data}">
        <template v-if="['simple', 'final'].includes(this.agreementMode(card_data))">
          <InputField       v-if="!this.isSelfAgreement(card_data)"
                            label_text="Date and Time"
                            :set_values="this.agreementMode(card_data) == 'simple' ? card_data.date_date : card_data.final_date_date"
                            @click.stop="''"
                            :input_field_status="(card_data.date_change_mode || card_data.base_set_final) ? undefined : 'disabled'"
                            @input_change="(data) => this.agreementMode(card_data) == 'simple' ? card_data.date_date = data : card_data.final_date_date = data"
                            :min="new Date().shiftDateFromCurrent(0, 0, 2)"
                            :max="(new Date()).shiftDateFromCurrent(0, 0, 9)"
                            type="datetime"/>
          <div class="waiting_text" v-if="card_data.self_is_main && card_data.base_set_final">
          <LabelComponent label_text="Please define the date and time of meeting with your chosen one." />
          </div>
          <br/>
          <template v-if="!this.isSelfAgreement(card_data)">
            <template v-if="!card_data.date_change_mode && !card_data.base_set_final">
              <div class="cancel_button">
              <ButtonComponent label="CONFIRM THE MEETING" elementType="default-bg" @click.stop="this.acceptDate(card_data, false)"/>
              <br/>
              <ButtonComponent label="CHANGE THE DATE" elementType="hover-bg" @click.stop="card_data.date_change_mode = true"/>
              <br/>
            </div>
            </template>
            <template v-else>
              <div class="cancel_button">
              <ButtonComponent label="CONFIRM THE MEETING" elementType="default-bg" @click.stop="this.acceptDate(card_data, true)"/>
              <br/>
              <ButtonComponent label="CANCEL DATE CHANGES" elementType="hover-bg" @click.stop="this.updateCards()" v-if="!card_data.base_set_final" />
              <br/>
            </div>
            </template>
          </template>
          <template  v-else>
            <div class="waiting_text">
            <LabelComponent label_text="We will show you the calendar to prove the date of meeting." />
          </div>
            <br/>
          </template>
        </template>

        <!-- <template v-if="this.agreementMode(card_data) == 'pay_await'">
          <template v-if="!card_data.self_payed">
            <ButtonComponent label="Pay meeting" @click.stop="this.payDate(card_data)" elementType="default-bg"/>
            <br/>
          </template>
           <template v-else>
            <LabelComponent label_text="Ждите пока претендент оплатит свидание!" />
            <br/>
          </template>
        </template> -->

        <template v-if="this.agreementMode(card_data).includes('meet_await')">
          <div class="user_id_card">
          <LabelComponent label_text="Дата свидания" class="italic-label" label_type="body/large/regular 16" />
          </div>
          <InputField       label_text="Date and Time"
                            :set_values="card_data.final_date_date"
                            @click.stop="''"
                            input_field_status="disabled"
                            type="datetime"/>
          <br/>
          <div class="cancel_button" v-if="!this.agreementMode(card_data).includes('feedback_await')">
            <ButtonComponent v-if="(new Date(card_data.final_date_date) - new Date()) < 300000"  label="ZOOM MEETING" @click.stop="this.redirectToZoom(card_data)" :href="card_data.zoom_link" elementType="default-bg"/>
          <br/>
        </div>
        </template>

        <!-- <template class="waiting_text" v-if="this.agreementMode(card_data).includes('feedback_await') &&!card_data.self_active && !card_data.other_active">
          <div class="cloud_backward">
          <LabelComponent label_text="In accordance with our technical department, you were out of the Personal Account at the time of the meeting with your chosen one. Your chosen one was very upset; we will remove the data of your chosen one from your Personal Account. Respectively, your data will be removed from the Personal Account of your chosen one. Your payment in amount 195 Swiss Francs for organization of online date is not a subject to refund." />
        </div>
          <br/>
        </template>

        <template class="waiting_text" v-if="this.agreementMode(card_data).includes('feedback_await') && card_data.self_active && !card_data.other_active">
          <div class="cloud_backward">
            <div v-if="!card_data.other_active">
          <LabelComponent  label_text="Unfortunately, your chosen one did not inform us about inability to attend the meeting. If you are still ready to continue communicating with your chosen one, we will organize another date and time. Otherwise, we will remove the data of your chosen one from your Personal Account. Respectively, your data will be removed from the Personal Account of your chosen one. Your payment in amount 195 Swiss Francs for organization of online date will be used for another meeting. As a compensation, we will provide you another Candidate in your CANDIDATES Section." />
            </div>
            <div v-if="card_data.self_active">
          <LabelComponent  label_text="In accordance with our technical department, you were out of the Personal Account at the time of the meeting with your chosen one. Your chosen one was very upset; we will remove the data of your chosen one from your Personal Account. Respectively, your data will be removed from the Personal Account of your chosen one. Your payment in amount 195 Swiss Francs for organization of online date is not a subject to refund." />
          </div>
        </div>
          <br/>
        </template> -->

        <template v-if="this.agreementMode(card_data).includes('feedback_await')">
          <div class="cloud_backward" v-if="!card_data.self_active && !card_data.other_active">
           <LabelComponent label_text="no_show_meeting_notif" />
          </div>

          <div class="cloud_backward" v-if="card_data.self_active && !card_data.other_active">
            <LabelComponent v-if="card_data.self_is_main" label_text="meeting_is_active" />
            <LabelComponent v-else label_text="no_show_meeting_notif_innocent" />
          </div>

          <div class="cloud_backward" v-if="!card_data.self_active && card_data.other_active">
            <LabelComponent label_text="no_show_meeting_notif" />
          </div>

          <div class="cloud_backward" v-if="card_data.self_active && card_data.other_active">
            <LabelComponent label_text="If during the date you agreed to continue communicating directly, you are not obligated to tell us anything. We wish you happiness! If you haven’t yet decided anything, and you need to think it over carefully, we will return the Presentations of both of you to the MUTUAL SYMPATHIES Section." />
          </div>

          <div class="meeting_feedback">
          <!-- <LabelComponent label_text="Оставьте отзыв о свидании!" />
          <InputField       label_text="Feedback"
                            :set_values="card_data.self_feedback"
                            @click.stop="''"
                            :error_text="card_data.self_feedback_error"
                            :input_field_status="card_data.has_feedback ? 'disabled' : undefined"
                            @input_change="(data) => card_data.self_feedback = data"
                            />
          <LabelComponent v-if="card_data.has_feedback" label_text="Ожидаем отзыва от претендента" />
          <ButtonComponent v-else @click.stop="this.setSelfFeedback(card_data)" label="Send" elementType="default-bg"/> -->
          </div>
          <br/>
        </template>
        <div class="cancel_button" v-if="!this.agreementMode(card_data).includes('feedback_await')">
        <ButtonComponent  label="CANCEL THE MEETING" elementType="hover-bg" @click.stop="this.cancelMeeting(card_data)" />
        <ModalView v-if="this.showModal" :open_level="1" type_modal="internal" position="fixed" caption=" " @click_close="this.hideModal">
        <LabelComponent label_text="Please pay attention, if cancellation is within 24 hours before the meeting, no refund of your payment is available." label_type="button/large/medium 18" class="liked_text" />
        <br />
        <ButtonComponent label="CANCEL THE MEETING" elementType="hover-bg" @click.stop="this.executeCancelMeeting(card_data)" />
        </ModalView>
        </div>

        <div class="local_time">
            <LabelComponent
                          label_type="h4"
                          label_text="Please pay attention: the date and time of the meeting is set up in accordance with your local time" />
          </div>
      </template>
    </ViewedProfileList>
  </div>
  <div v-else class="text_if_no_card">
      <LabelComponent label_text="Больше активности: вперед, навстречу своей судьбе"/>
    </div>
    <br/>
    <div class="get-more-button-wraper">
      <ButtonComponent label="ORDER ANOTHER PACK" elementType="default-bg" @click="this.buyNewPackage"/>
    </div>
    <ModalView v-if="this.global_message_text != null" :open_level="1" type_modal="internal" position="fixed" caption=" " @click_close="this.global_message_text = null">
      <LabelComponent :label_text="this.global_message_text" label_type="button/large/medium 18" class="liked_text" />
        <br />
    </ModalView>
  </BasePage>
</template>

<script>
import BasePage from '@/baseLayout/BasePage';
import ButtonComponent from '@/units/forms/Button';
import InputField from '@/units/forms/InputField';
import LabelComponent from '@/units/Label';
import ModalView from '@/layoutTools/ModalView';
import ViewedProfileList from './ViewedProfileList';

import getFormData from '@/assets/dataSources/getFormData';
import sendFormData from '@/assets/dataSources/sendFormData';


export default ({
  components: {
    BasePage,
    ModalView,
    ButtonComponent,
    LabelComponent,
    ViewedProfileList,
    InputField,
  },
  data: ()=> ({
    global_message_text: null,
    showModal: false,
    user_message_text: null,
    cards: [
      {
        id: '1',
        image_url: '/icons/law.svg',
        date_date: null,
        user_id: '0000000',

        // 'id': other_looks[qr.owner_id].viewed_profile_id,
        // 'meet_id': other_looks[qr.owner_id].id,
        // 'image_url': request.build_absolute_uri(list(qr.media_set.all())[0].files.url),
        // 'user_id': qr.owner.unique_code,
        // 'self_agreement': other_looks[qr.owner_id].get_self_agreement(cur_person.id),
        // 'other_agreement': other_looks[qr.owner_id].get_other_agreement(cur_person.id),
        // 'self_final_agreement': other_looks[qr.owner_id].get_self_final_agreement(cur_person.id),
        // 'other_final_agreement': other_looks[qr.owner_id].get_other_final_agreement(cur_person.id),
        // 'self_payed': other_looks[qr.owner_id].get_self_payment(cur_person.id),
        // 'other_payed': other_looks[qr.owner_id].get_other_payment(cur_person.id),
        // 'date_date': other_looks[qr.owner_id].date,
        // 'final_date_date': other_looks[qr.owner_id].final_date,
        // 'self_is_main': other_looks[qr.owner_id].viewed_profile.owner1_id == cur_person.id,
        // 'zoom_link'
      },
      {
        id: '2',
        date_date: null,
        image_url: '/icons/law.svg',
        user_id: '0000001',
      },
    ],
  }),
  props: {

  },
  mounted() {
    this.updateCards();
  },
  computed: {

  },
  methods: {
    confirmCancelMeeting(card) {
      if ((new Date(card.final_date_date) - new Date()) < 86400000) {
        // Показать модальное окно только если осталось меньше 24 часов
        this.showModal = true;
      } else {
        // Иначе выполнить отмену встречи
        this.executeCancelMeeting(card);
      }
    },
    isSelfAgreement(card) {
      return (this.agreementMode(card) == 'simple' && card.self_agreement) || (this.agreementMode(card) == 'final' && (card.self_final_agreement || (!card.self_is_main && !card.other_final_agreement)));
    },
    agreementMode(card) {
      if ((!card.self_agreement || !card.other_agreement) && !card.self_payed && !card.other_payed) {
        return 'simple';
      }

      if (card.self_agreement && card.other_agreement && (!card.self_payed || !card.other_payed)) {
        return 'pay_await';
      }

      if (!card.self_final_agreement || !card.other_final_agreement) {
        return 'final';
      }

      return 'meet_await' + (((new Date(card.final_date_date) - new Date()) < -900000) ? '&feedback_await' : '');
    },
    cancelMeeting(card) {
      const meetingTime = new Date(card.final_date_date);
      const currentTime = new Date();
      const timeDifference = meetingTime - currentTime;

      // Показать модальное окно только если осталось меньше 24 часов
      if (timeDifference < 86400000) {
        this.showModal = true;
      } else {
        // Если времени больше, чем 24 часа, выполнить отмену встречи
        this.executeCancelMeeting(card);
      }
    },

    hideModal() {
      this.showModal = false;
      this.user_message_text = null; // если нужно сбросить текст сообщения пользователя
    },
    executeCancelMeeting(card) {
      sendFormData((result) => {
        this.user_message_text = 'You canceled the meeting.';
        this.updateCards();

        if (result.status == 200) {
          return;
        }

        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: {
            name: 'Error',
            message: 'Bad server response! Status: ' + result.status,
          },
        }]);
      }, 'cancel_meeting/' + card.meet_id);
    },
    acceptDate(card, changeDate) {
      this.user_message_text= 'Thank you for confirmation, we will inform your chosen one accordingly.';

      if ((this.agreementMode(card) == 'simple' && !card.date_date) || (this.agreementMode(card) != 'simple' && !card.final_date_date)) {
        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: {
            name: 'Error',
            message: 'You must select date and time!',
          },
        }]);

        return;
      }

      const formData = new FormData();

      const selectedDate = (this.agreementMode(card) == 'simple' ? card.date_date : card.final_date_date);

      formData.append('date_date', selectedDate.toISOString());

      if (!changeDate) {
        formData.append('confirm_date', 'on');
      }

      const reqAddress = (this.agreementMode(card) == 'simple' ? 'confirm_meeting' : 'confirm_final_meeting') + '/' + card.meet_id;

      sendFormData((result) => {
        this.updateCards();

        if (result.status == 200) {
          return;
        }

        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: {
            name: 'Error',
            message: 'Bad server response! Status: ' + result.status,
          },
        }]);
      }, reqAddress, formData);
    },
    payDate(card) {
      sendFormData(async (result) => {
        this.updateCards();

        if (result.status == 200) {
          return;
        }

        const responseText = await result.text();

        if (responseText == 'not_enough_money' && result.status == 400) {
          window.globalEvents.callEvent('new_message', [{
            messageType: 'error',
            messageData: {
              name: 'Error',
              message: 'Недостаточно средств в кошелке!\nПополните кошелек!',
            },
          }]);
          return;
        }

        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: {
            name: 'Error',
            message: 'Bad server response! Status: ' + result.status,
          },
        }]);
      }, 'pay_meeting/' + card.meet_id);
    },
    updateCards() {
      getFormData((servData) => {
        servData.forEach((card) => {
          card.base_set_final = card.final_date_date == null;
          card.has_feedback = card.self_feedback != null && card.self_feedback != '';
          card.self_feedback_error = null;
        });
        this.cards = servData;
      }, 'meetings');
    },
    cardClick(card) {
      window.location.href = '/presentation/' + card.id + '/';
    },
    redirectToZoom(card) {
      sendFormData((result) => {
        if (result.status == 200) {
          // window.open(card.zoom_link, '_blank').focus();
        }
      }, 'set_meeting_active/' + card.meet_id);
    },
    setSelfFeedback(card) {
      console.log('New feedback!', card);

      if (!card.self_feedback || card.self_feedback == '') {
        card.self_feedback_error = 'Please, fill feedback!';
        console.log('bad!');
        return;
      }

      const sendForm = new FormData();
      sendForm.append('message', card.self_feedback);

      sendFormData((result) => {
        if (result.status == 200) {
          window.location.reload();
        }
      }, 'set_meeting_feedback/' + card.meet_id, sendForm);
    },
    buyNewPackage() {
      sendFormData((result) => {
        if (result.status == 200) {
          this.global_message_text = 'The new package has been successfully purchased. Please expect Presentations of new Candidates to appear.';
        } else if (result.status == 400) {
          this.global_message_text = 'There are not enough funds to purchase the package, please top up your Wallet.';
        } else if (result.status == 409) {
          this.global_message_text = 'A new Pack can be ordered after all the Presentations of already ordered Candidates have arrived. Thank you for your desire to receive more Candidates.';
        };
      }, 'buy_candidates_package');
    },
  },
});
</script>

<style lang="less">
.local_time{
  display: flex;
    width: 80%;
    text-align: center;
}
.meeting_feedback{
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;
    align-items: flex-start;
}
.get-more-button-wraper {
  .flex(row, center, center);
}
</style>
