<template>
  <ButtonComponent :label="this.label"
                   :elementStatusModifier="this.isPositive ? undefined : 'danger'"
                   @click_to_button="this.buttonClick"/>
</template>

<script>
import ButtonComponent from '@/units/forms/Button';

export default ({
  name: 'AdminButton',
  components: {
    ButtonComponent,
  },
  emits: [
    'buttonClick',
  ],
  computed: {

  },
  methods: {
    buttonClick() {
      this.$emit('buttonClick', this.fieldInfo);
    },
  },
  props: {
    label: {
      required: true,
      type: String,
      default: '',
    },
    isPositive: {
      required: true,
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style>

</style>
