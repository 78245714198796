<template>
  <div class="message-item">
    <IconComponent
        :class="`message-item__icon-${push_message.messageType}`"
        url="/icons/more-information.svg"
        size="50"/>
    <div class="message-item__main-field">
      <div class="message-item__caption">
        <LabelComponent
            :label_text="'Сообщение:'"
        />
            </div>
      <div class="message-item__message">
        <LabelComponent
            class="message-item__message-items"
            :label_text="get_label1"/>
        <LabelComponent
            v-if="push_message.messageType==='error'"
            class="message-item__message-items"
            :label_text="push_message.messageData.message"
        />
      </div>
    </div>
    <ButtonComponent
        class="message-item__close-button"
        elementType="hover"
        @click="close_window"
        iconUrl="/icons/close.svg"
        iconType="svg"
        iconSize="22"/>
  </div>
</template>

<script>
import ButtonComponent from '@/units/forms/Button';
// import builder from '@/assets/v1/js/builder';
import IconComponent from '@/units/Icon';
import LabelComponent from '@/units/Label';
export default /* builder */({
  name: 'MessageWindowItem',
  components: {ButtonComponent, IconComponent, LabelComponent},
  emits: {'close_error_item': null},
  methods: {
    // событие - закрой меня
    close_window() {
      this.$emit('close_error_item');
    },
  },
  mounted() {
    // элемент с ошибкой удаляется автоматически
    if (this.push_message.messageType==='success') {
      setTimeout(()=>{
        this.close_window();
      }, 7000);
    }
  },
  computed: {
    get_label1() {
      return this.push_message.messageType==='error'?this.push_message.messageData.name:this.push_message.messageData;
    },
  },
  props: {
    push_message: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style scoped lang="less">
.message-item{
  margin: 10px auto;
  border-radius: 10px;
  box-shadow: 0 4px 14px rgba(46, 50, 45, 0.03);
  padding: 21px 24px;
  //max-width: 50px;
  //height: 50px;
  //width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  //border: 1px solid @error;
  background-color: @white;
  & > * {
    margin-right: 5px;
  }
  &__header{
    display: flex;
    justify-content: space-between;

   }
  &__icon{
    &-error {
      transform: rotate(180deg);
      background-color: @error;
    }
    &-success{

    }
    &-warning{
      background-color: @warning;
    }
  }

  &__caption{
    //color: @error;
  }
  &__message{
    word-break: break-all;
    max-width: 250px;
    &-items{
      margin: 5px auto;
      white-space: pre-wrap;
    }
  }
  &__close-button{
    align-self: flex-start;
  }
}
</style>
